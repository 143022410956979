// @flow strict
import React from 'react';

import { Input as RInput } from 'reactstrap';

import type { AbstractComponent, Node } from 'react';

type Props = {|
  id?: string,
  name?: string,
  placeholder?: string,
  className?: string,
  type?: string,
  onChange?: (string | number) => void,
  onBlur?: () => void,
  placeholder?: string,
  checked?: boolean,
  value?: ?(string | number),
|};

const Input: AbstractComponent<Props, HTMLInputElement> = React.forwardRef<
  Props,
  HTMLInputElement,
>((props, ref): Node => {
  return <RInput innerRef={ref} {...props} />;
});

Input.displayName = 'Input';

export default Input;
