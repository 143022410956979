// @flow strict
import { FormattedMessage } from 'react-intl';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

import type { Node } from 'react';

import Select from '../molecules/form/Select';
import SearchInput from '../cells/SearchInput';

import { useStrainSearchParams } from '../hooks';
import type { Option } from '../molecules/form/Select';

type Props = {|
  cities: Array<{| municipality: string, lat: number, lng: number |}>,
|};

function DispensaryForm({ cities }: Props): Node {
  const [searchParams, setSearchParams] = useStrainSearchParams();

  const cityOptions = cities.map(({ municipality }) => ({
    label: municipality,
    value: municipality,
  }));

  cityOptions.unshift({
    // $FlowFixMe
    label: <FormattedMessage id="dispensary.filter-opt" />,
    value: 'all',
  });

  const selectedCityOption = cityOptions.find(
    (o) => o.value === searchParams.get('city'),
  );

  const handleSelectCity = (city: ?Option | Option[]) => {
    if (city instanceof Array) {
      return;
    }

    // $FlowFixMe
    setSearchParams({ city: city?.value });
  };

  const handleChangeSearch = (search: ?string) => {
    // $FlowFixMe
    setSearchParams({ search });
  };

  return (
    <Row>
      <div className="listing-search-form mb-120">
        <Row>
          <Col className="col-lg-3 col-md-4 col-sm-12 col-12">
            <FormGroup>
              <Label for="municipality">
                <FormattedMessage id="dispensary.filter.label.city" />
              </Label>
              <FormattedMessage id="dispensary.filter-opt">
                {(msg) => (
                  <Select
                    className="dropdown-primary"
                    size="xxlg"
                    placeholder={msg}
                    // $FlowFixMe
                    options={cityOptions}
                    value={selectedCityOption}
                    onChange={handleSelectCity}
                  />
                )}
              </FormattedMessage>
            </FormGroup>
          </Col>
          <Col className="col-lg-6 col-md-4 col-sm-12 col-12">
            <Label for="search">
              <FormattedMessage id="dispensary.filter.label.search" />
            </Label>
            <FormGroup>
              <FormattedMessage id="dispensary.filter-search-key">
                {(msg) => (
                  <SearchInput
                    hideIcon
                    className="form_control"
                    placeholder={msg}
                    onChange={handleChangeSearch}
                    defaultValue={searchParams.get('search')}
                  />
                )}
              </FormattedMessage>
            </FormGroup>
          </Col>
          <Col className="col-lg-3 col-md-4 col-sm-12 col-12">
            <Label for="search">&nbsp;</Label>
            <FormGroup>
              <Button className="main-btn icon-btn">
                <FormattedMessage id="dispensary.filter.search-now" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export default DispensaryForm;
