// @flow strict
import type { Node } from 'react';

import HomeArea from '../organisms/HomeArea';
import HomeMetaTags from '../molecules/seo/HomeMetaTags';
import FeaturedBrand from '../organisms/FeaturedBrand';
import FeaturedCategory from '../organisms/FeaturedCategory';
import FeaturedDispensary from '../organisms/FeaturedDispensary';
import FeaturedOffers from '../organisms/FeaturedOffers';
import RecommendedProducts from '../organisms/RecommendedProducts';
import RibbonBanner from '../cells/RibbonBanner';
import SoldProduct from '../organisms/SoldProduct';
import TestimonialArea from '../organisms/TestimonialArea';
import InstagramArea from '../organisms/InstagramArea';
import HowItWorks from '../organisms/HowItWorks';
import MedicalBanner from '../cells/MedicalBanner';
import BudtenderBanner from '../cells/BudtenderBanner';
import Footer from '../organisms/Footer';

function Home(): Node {
  return (
    <>
      <HomeMetaTags />
      <HomeArea />
      <FeaturedCategory />
      <FeaturedDispensary />
      <FeaturedBrand />
      {false && <FeaturedOffers />}
      {false && <RecommendedProducts />}
      {false && <RibbonBanner />}
      <SoldProduct />
      {false && <TestimonialArea />}
      {false && <InstagramArea />}
      {false && <HowItWorks />}
      {false && <MedicalBanner />}
      {false && <BudtenderBanner />}
      <Footer />
    </>
  );
}

export default Home;
