// @flow strict
import MUTATION from './mutations';
import PRODUCT from './product';
import BRAND from './brand';
import PRODUCT_CATEGORY from './product-category';
import DISPENSARY from './dispensary';
import PATIENT from './patient';
import CART from './cart';

export default {
  BRAND,
  PRODUCT,
  PRODUCT_CATEGORY,
  DISPENSARY,
  PATIENT,
  CART,
  MUTATION,
};
