// @flow strict

import type { Node } from 'react';
import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';

type Props = {|
  min: number,
  max: number,
  defaultValue: number,
  noRound: boolean,
  size: string,
  onChange?: (data: number) => void,
  onDecrease?: () => void,
  onIncrease?: () => void,
  className?: string,
|};

function QuantitySelector({
  min,
  max,
  defaultValue,
  noRound,
  size,
  onChange,
  onDecrease,
  onIncrease,
  className,
}: Props): Node {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value > max) {
      setValue(max);
    }
  }, [value, max]);

  const decreaseValue = () => {
    if (min !== undefined || value <= min) {
      if (value <= 1) {
        return;
      }
      onDecrease?.();
      onChange?.(value - 1);
      setValue(value - 1);
    }
  };

  const increaseValue = () => {
    if (value >= max) {
      return;
    }
    onIncrease?.();
    onChange?.(value + 1);
    setValue(value + 1);
  };

  const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const quantity = +e.target.value;
    if (e.target.value === '') {
      setValue(1);
      onChange?.(1);
    }
    if (Number.isNaN(quantity) === false) {
      if (quantity >= 1 && quantity <= max) {
        setValue(quantity);
        onChange?.(quantity);
      }
    }
  };

  return (
    <div
      className={`${className || ''} quantity-selector ${
        noRound ? '-no-round' : ''
      } -${size}`}
    >
      <Button
        className="quantity-selector-controller d-none d-lg-inline"
        type="link"
        disabled={value <= min || value <= 1}
        onClick={decreaseValue}
      >
        -
      </Button>
      <div className="quantity-selector-number">
        <Input onChange={handleChange} value={value} />
      </div>
      <Button
        className="quantity-selector-controller d-none d-lg-inline"
        type="link"
        disabled={value >= max}
        onClick={increaseValue}
      >
        +
      </Button>
    </div>
  );
}

export default QuantitySelector;
