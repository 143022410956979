// @flow strict

import type { ApolloError } from '@apollo/client';
import type { StrainValidationErrors } from './types';

export const getValidationErrors = (
  error: ?ApolloError,
): ?StrainValidationErrors => {
  const graphQLErrors = error?.graphQLErrors;

  const validationError = graphQLErrors?.find(
    (x) =>
      // $FlowFixMe
      x.extensions.code === 'BAD_USER_INPUT' && x.extensions.status === 400,
  );

  if (validationError != null) {
    return {
      message: validationError.message,
      // $FlowFixMe
      errors: validationError.extensions.validationErrors,
    };
  }

  return null;
};

export default getValidationErrors;
