// @flow strict
import S from 'react-select';
import type { Node } from 'react';
import React from 'react';
import cx from 'classnames';

export type Option = {|
  label: string | Node,
  value: ?(string | number),
|};

type Props = {|
  isMulti?: boolean,
  isSearchable?: boolean,
  isLoading?: boolean,
  autoselect?: boolean,
  disabled?: boolean,
  options?: Option[],
  onChange?: (option: ?(Option | Option[])) => void,
  onBlur?: () => void,
  placeholder?: string,
  value?: ?(Option | Option[]),
  size?: string, // sm, md (default), lg, xlg, xxlg
  className?: ?string,
|};

const Select = function ({
  options,
  onChange,
  onBlur,
  placeholder,
  value,
  isSearchable,
  isLoading,
  autoselect,
  disabled = true,
  isMulti = false,
  size = 'md',
  className,
}: Props): Node {
  return (
    <S
      className={cx('react-select', `react-select-${size}`, className)}
      classNamePrefix="react-select"
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isLoading={isLoading}
      autoselect={autoselect}
      disabled={disabled}
    />
  );
};

export default Select;
