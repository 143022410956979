// @flow strict
import gql from 'graphql-tag';

const DETAIL: typeof gql = gql`
  query Store($storeId: Int) {
    store(id: $storeId) {
      id
      name
      address
      image
      email
      phoneNumber
      locationId
      showTaxes
      point {
        lng
        lat
      }
      openHours {
        close
        day
        open
      }
      productsAndServices
      website
      business {
        id
        about
        logo
      }
    }
  }
`;

const LIST: typeof gql = gql`
  query Stores(
    $pagination: PaginationInput
    $sorting: StoreListSortingInput
    $filter: StoreListFilterInput
  ) {
    stores(pagination: $pagination, sorting: $sorting, filter: $filter) {
      total
      objects {
        id
        name
        phoneNumber
        address
        image
        isFeatured
        point {
          lat
          lng
        }
        openHours {
          close
          open
          day
        }
        business {
          id
          logo
        }
      }
    }
  }
`;

export default { DETAIL, LIST };
