// @flow strict

import { FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  NavLink,
  Spinner,
} from 'reactstrap';

import type { Node } from 'react';

import Input from '../molecules/form/Input';
import usePatientIdExists from '../hooks/patient';

import { addPatientId } from '../helpers';

type Props = {|
  isOpen: boolean,
  businessId: string,
  handleAddToCart: (patientId: string) => void,
  onContinueAsGuest?: () => void,
  toggle?: () => void,
|};

function LightBox({
  isOpen,
  businessId,
  handleAddToCart,
  onContinueAsGuest,
  toggle,
}: Props): Node {
  const [patientId, setPatientId] = useState('');

  const [getPatientIdExists, { data: patientIDExists, loading }] =
    usePatientIdExists();

  const handlePatientIdExists = (valPatientId: string) => {
    // $FlowFixMe
    getPatientIdExists({
      variables: {
        businessId: +businessId,
        patientId: valPatientId,
      },
    });
  };

  const submitForm = (e: SyntheticInputEvent<HTMLFormElement>) => {
    // $FlowFixMe
    setPatientId(e.target[0].value);
    // $FlowFixMe
    handlePatientIdExists(e.target[0].value);
    e.preventDefault();
  };

  useEffect(() => {
    // // $FlowFixMe
    // $FlowFixMe
    if (patientIDExists && isOpen) {
      addPatientId(patientId, businessId);
      handleAddToCart(patientId);
    }
  }, [patientIDExists, businessId, patientId, isOpen, handleAddToCart]);

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggle} className="lightbox">
        <ModalBody className="text-center justify-content-center">
          <Col className="col-12 col-sm-10 m-auto">
            <img src="/assets/images/id-cannabis.png" alt="id" />
            <Form className="form" onSubmit={(e) => submitForm(e)}>
              <FormGroup>
                <Label for="exampleEmail">
                  <p>
                    <FormattedMessage id="home.lightbox.title" />
                  </p>
                  <p>
                    <FormattedMessage id="home.lightbox.title2" />
                  </p>
                </Label>
                <FormattedMessage id="home.lightbox.input-patient">
                  {(msg) => (
                    <Input
                      id="patientId"
                      name="patientId"
                      className="form_control"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </FormGroup>
              <Button block className="main-btn" disabled={loading}>
                {loading && <Spinner size="sm" />}
                <span className="mx-3">
                  <FormattedMessage id="home.lightbox.continue" />
                </span>
              </Button>
            </Form>
            {!loading && patientId !== '' && patientIDExists === false && (
              <Alert color="danger" className="mt-3">
                <FormattedMessage id="home.lightbox.no-patient-id-in-records" />
              </Alert>
            )}
            <NavLink onClick={onContinueAsGuest}>
              <p className="guest">
                <FormattedMessage id="home.lightbox.continue-guest" />
              </p>
            </NavLink>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default LightBox;
