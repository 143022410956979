// @flow strict
import gql from 'graphql-tag';

const LIST: $FlowFixMe = gql`
  query Brands($limit: Int = 100) {
    business {
      id
      brands(pagination: { limit: $limit }) {
        total
        objects {
          id
          name
        }
      }
    }
  }
`;

export default {
  LIST,
};
