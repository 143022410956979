// @flow strict
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import type { Node } from 'react';

import LightBox from '../organisms/LightBox';
import POSPatientRegisterModal from '../organisms/PatientRegisterModal';
import Image from '../atoms/Image';
import RequestButton from './RequestButton';
import ProductContent from './ProductContent';

import { getPatientId, addPatientId } from '../helpers';
import { useAddToCart } from '../hooks/cart';
import {
  updateCart,
  updateCartCount,
  addProductInformationVar,
  currentCartVar,
} from '../graphql/reactive-variables';

import type { Product } from '../types';

type Props = {|
  product: Product,
  showTaxes?: boolean,
  locationId: number,
  businessId: number,
  logo?: string,
  storeName?: string,
  storeId?: number,
|};

function SoldProductCard({
  product,
  showTaxes,
  locationId,
  businessId,
  logo,
  storeName,
  storeId,
}: Props): Node {
  const [modal, setModal] = useState(false);
  const [showPatientRegister, setShowPatientRegister] = useState(false);
  const { name, image, id, itemNumber, variants } = product;
  // $FlowFixMe
  const [requestAddToCart, { data: cartResponse, loading }] = useAddToCart({
    context: {
      headers: { 'Strain-Business-Id': +businessId },
    },
  });

  const handleAddToCart = useCallback(
    (patientId: string) => {
      // $FlowFixMe
      requestAddToCart({
        variables: {
          cartItem: {
            productId: id,
            quantity: 1,
            productVariantId: variants[0].id,
          },
          filter: {
            patientId,
            locationId: +locationId,
            cartType: 'MED',
          },
        },
        // $FlowFixMe
        onCompleted: ({ addItemToCart }) => {
          if (addItemToCart != null) {
            currentCartVar({
              businessId: `${businessId}`,
              locationId: `${locationId}`,
            });
            addProductInformationVar({
              isOpen: true,
              continueUrl: `/dispensaries/${businessId}/stores/${locationId}`,
              cartUrl: `/order-online/${businessId}/stores/${locationId}`,
            });
          }
        },
      });
    },
    [businessId, id, locationId, requestAddToCart, variants],
  );

  const handleSetCartCount = useCallback((cont: number) => {
    updateCartCount({
      quantity: cont,
    });
  }, []);

  const togglePatientRegisterModal = useCallback(() => {
    setShowPatientRegister((value) => !value);
  }, []);

  const handleOnSuccessRegistration = useCallback(
    (patientId: string) => {
      setShowPatientRegister(false);
      addPatientId(patientId, businessId.toString());
      handleAddToCart(patientId);
    },
    [handleAddToCart, businessId],
  );

  const onAddProductToCart = () => {
    const patientId = getPatientId(businessId.toString())?.patientId;
    if (patientId !== undefined) {
      handleAddToCart(patientId);
    } else {
      setModal(true);
    }
  };

  const handleOnContinueAsGuest = useCallback(() => {
    setModal(false);
    setShowPatientRegister(true);
  }, []);

  useEffect(() => {
    if (cartResponse?.items) {
      handleSetCartCount(cartResponse.items.length);
      updateCart(cartResponse);
    }
  }, [handleSetCartCount, cartResponse]);

  const { discount } = product;
  const dispensaryURL =
    storeId !== undefined
      ? `/dispensaries/${businessId}/stores/${storeId}`
      : '/';

  return (
    <div className="listing-item listing-grid-one">
      <LightBox
        isOpen={modal}
        toggle={() => setModal((value) => !value)}
        businessId={businessId.toString()}
        handleAddToCart={handleAddToCart}
        onContinueAsGuest={handleOnContinueAsGuest}
      />
      <POSPatientRegisterModal
        locationId={locationId}
        businessId={businessId}
        isOpen={showPatientRegister}
        toggle={togglePatientRegisterModal}
        onSuccessRegistration={handleOnSuccessRegistration}
      />
      <div className="listing-thumbnail">
        <Link
          to={
            product.variants
              ? `/dispensaries/${businessId}/stores/${locationId}/product/${itemNumber}`
              : ''
          }
        >
          <Image src={image} alt={name} />
        </Link>
        {discount != null && (
          <span className="discount">
            <i className="ti-icon ti-tag me-1" /> {discount.label}
          </span>
        )}
        {storeName != null && (
          <div className="thumbnail-meta d-flex justify-content-between align-items-center">
            <div className="meta-icon-title d-flex align-items-center">
              {logo != null && (
                <img
                  className="icon listing-icon"
                  src={logo}
                  alt="dispensary"
                />
              )}
              <Link to={dispensaryURL} className="store-name">
                {storeName}
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="listing-content">
        <ProductContent
          product={product}
          showTaxes={showTaxes}
          locationId={locationId}
          businessId={businessId}
        />
      </div>

      <RequestButton
        color="success"
        loading={loading}
        className="main-btn"
        onClick={onAddProductToCart}
        iconClass="ti-shopping-cart"
        textId="common.add-cart"
        disabled={product.variants === null}
      />
    </div>
  );
}

export default SoldProductCard;
