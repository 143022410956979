// @flow strict

import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import type { Node } from 'react';

import ProductDetailContent from '../organisms/ProductDetailContent';
import Footer from '../organisms/Footer';
import QueryResult from '../organisms/QueryResult';
import NotFound from '../cells/NotFound';
import ZoomImage from '../cells/ZoomImage';
import ProductListLoading from '../cells/Shimmers/ProductDetailLoading';
import RelatedProducts from '../cells/RelatedProducts';

import { useProduct } from '../hooks/product';
import { useDispensary } from '../hooks/dispensary';

function ProductDetail(): Node {
  const { businessId, locationId, productId } = useParams();

  // $FlowFixMe
  const { data: store } = useDispensary({
    variables: {
      storeId: +locationId,
    },
    context: {
      headers: {
        'Strain-Business-Id': +businessId,
      },
    },
  });

  const {
    data: product,
    loading: queryLoading,
    error: queryError,
    // $FlowFixMe
  } = useProduct({
    variables: {
      itemNumber: productId,
      locationId: +locationId,
    },
    context: {
      headers: {
        'Strain-Business-Id': +businessId,
      },
    },
  });

  return (
    <>
      <div className="product-detail-one">
        <div className="product-detail-one-top">
          <Container fluid className="content">
            <QueryResult
              loading={queryLoading}
              isEmpty={product == null}
              error={queryError}
              placeholder={<ProductListLoading />}
              notFound={<NotFound element="product" />}
            >
              <Row>
                <Col xl={4} lg={6} md={12} sm={12} className="mb-3">
                  <div className="d-none d-lg-block product-detail-image-one-big">
                    <ZoomImage
                      disableOnMobile
                      src={product?.image ?? ''}
                      alt="Product image"
                    />
                  </div>
                </Col>
                <Col xl={8} lg={6} md={12} sm={12} className="mb-3">
                  <ProductDetailContent
                    store={store}
                    product={product}
                    quantityControllerNoRound
                  />
                </Col>
              </Row>
            </QueryResult>
          </Container>
        </div>
        <div className="product-detail-one-bottom">
          <Container fluid className="content">
            <RelatedProducts />
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductDetail;
