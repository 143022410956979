// @flow strict
import gql from 'graphql-tag';

const ID_EXISTS: typeof gql = gql`
  query Query($businessId: Int!, $patientId: String!) {
    patientIDExists(businessId: $businessId, patientId: $patientId)
  }
`;

export default { ID_EXISTS };
