// @flow strict
import moment from 'moment';
import Slider from 'react-slick';

import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import type { Node } from 'react';

import OfferCard from '../cells/OfferCard';
import SlickArrow from '../molecules/SlickArrow';

const data = [
  {
    title: 'Sabia Colective',
    date: moment(Date.now()).subtract(30, 'days').toISOString(),
    image: '/assets/images/offers/offer-1.jpeg',
    key: 1,
  },
  {
    title: 'Sabia Colective',
    date: moment(Date.now()).subtract(14, 'days').toISOString(),
    image: '/assets/images/offers/offer-2.jpeg',
    key: 2,
  },
  {
    title: 'Sabia Colective',
    date: moment(Date.now()).subtract(2, 'hours').toISOString(),
    image: '/assets/images/offers/offer-3.png',
    key: 3,
  },
  {
    title: 'Sabia Colective',
    date: moment(Date.now()).subtract(2, 'hours').toISOString(),
    image: '/assets/images/offers/offer-2.jpeg',
    key: 4,
  },
];
function FeaturedOffers(): Node {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    centerPadding: '60px',
    prevArrow: <SlickArrow arrow="left" />,
    nextArrow: <SlickArrow arrow="right" />,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="place-area">
      <Container fluid className="content mt-5">
        <Row className="justify-content-center">
          <div className="section-title section-title-two">
            <h5>
              <FormattedMessage id="home.offer.sub-title" />
            </h5>
            <h1>
              <span className="line">
                <FormattedMessage id="home.offer.title2" />
              </span>
              <FormattedMessage id="home.offer.title" />
            </h1>
            <p>
              <FormattedMessage id="home.show-all-2" />
            </p>
          </div>
        </Row>
        <Row>
          <Slider {...settings}>
            {data.map((offer) => (
              <Col
                className="col-lg-4 col-md-6 col-sm-12 col-12 px-3"
                key={offer.key}
              >
                <OfferCard
                  title={offer.title}
                  date={offer.date}
                  image={offer.image}
                />
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
}

export default FeaturedOffers;
