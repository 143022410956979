/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
// @flow strict
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';

import type { Node } from 'react';
import { useState } from 'react';

import Image from '../atoms/Image';

import { useAppContextProvider } from '../app/context';

import type { Dispensary } from '../types';

type Props = {|
  data: Dispensary,
|};

function DispensaryDetailsArea({ data }: Props): Node {
  const [showMore, setShowMore] = useState(false);
  const [lang] = useAppContextProvider();
  const {
    address,
    business,
    email,
    image,
    name,
    openHours,
    phoneNumber,
    point,
    website,
  } = data;
  const { lng, lat } = point;
  const { logo } = business;
  const map = `https://maps.google.com/maps?q=${lat},${lng}&hl=${lang}&z=14&amp;output=embed`;
  const iframe = `<iframe src=${map} allowFullScreen="" loading="lazy" title="This is a unique title" id="map"/>`;

  const addressLowerCase = address.toLowerCase();

  const camelize = (text: string) => {
    return text.replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const containButton = showMore ? 'Hide Info ' : 'More Info ';
  const arrowInfo = showMore ? (
    <i className="ti-angle-up" />
  ) : (
    <i className="ti-angle-down" />
  );
  return (
    <section className="page-breadcrumbs page-breadcrumbs-one py-0">
      <div className="dispensary-detail-map">
        <div className="dispensary-info">
          <div className="info-name">
            <div className="thumb">
              <Image src={image} alt="dispensary" />
              <Link to={website}>
                <img
                  className="cat-btn listing-icon"
                  src={logo || '/assets/images/default-placeholder.png'}
                  alt="dispensary-icon"
                />
              </Link>
            </div>
            <div className="content">
              <div className="d-flex justify-content-between align-items-center">
                <span className="cat-btn">
                  <FormattedMessage id="dispensary.title" />
                </span>
                <Button
                  className="d-lg-none button-more-less"
                  color="link"
                  onClick={() => setShowMore(!showMore)}
                >
                  {containButton}
                  {arrowInfo}
                </Button>
              </div>
              <h3 className="dispensary-title">{name}</h3>

              <div className={`d-lg-none ${showMore ? '' : 'd-none'}`}>
                {address && (
                  <div className="icon-box icon-box-two mt-3">
                    <div className="icon h3">
                      <i className="ti-location-pin" />
                    </div>
                    <div className="info">
                      <h6>{camelize(addressLowerCase)}</h6>
                    </div>
                  </div>
                )}
                {website && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <a
                        href={website}
                        target="_blank"
                        aria-label="Website"
                        rel="noreferrer"
                      >
                        <i className="ti-world" />
                      </a>
                    </div>
                    <div className="info">
                      <h6>
                        <a
                          href={website}
                          target="_blank"
                          className="link-dark"
                          rel="noreferrer"
                        >
                          {website}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {email && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <i className="ti-email" />
                    </div>
                    <div className="info">
                      <h6>
                        <a
                          href={`mailto:${email}`}
                          target="_blank"
                          className="link-dark"
                          rel="noreferrer"
                        >
                          {email}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {phoneNumber && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <a href={`tel:${phoneNumber}`} aria-label="Phone Number">
                        <i className="ti-mobile" />
                      </a>
                    </div>
                    <div className="info">
                      <h6>
                        <a href={`tel:${phoneNumber}`} className="link-dark">
                          {`${phoneNumber.substring(
                            2,
                            5,
                          )}-${phoneNumber.substring(
                            5,
                            8,
                          )}-${phoneNumber.substring(8)}`}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {Array.isArray(openHours) && (
                  <div className="icon-box icon-box-two align-items-start">
                    <div className="icon h3">
                      <i className="ti-time" />
                    </div>
                    <div className="info">
                      {openHours.map((item) => (
                        <h6 key={`open-hour-${item.day}`}>
                          <span style={{ width: '2.4rem' }}>
                            <FormattedMessage id={`day.abbr.${item.day}`} />
                          </span>{' '}
                          {moment(item.open, 'HH:mm').format('hh:mma')}
                          {' - '}
                          {moment(item.close, 'HH:mm').format('hh:mma')}
                        </h6>
                      ))}
                    </div>
                  </div>
                )}
                <div className="button d-none">
                  <Button className="icon-btn">
                    <i className="ti-heart" />
                  </Button>
                  <Button to="/" className="icon-btn">
                    <i className="ti-share" />
                  </Button>
                </div>

                <div className="dispensary-map">
                  <div
                    className="ratio ratio-16x9"
                    dangerouslySetInnerHTML={{ __html: iframe }}
                  />
                </div>
              </div>
              <div className="d-none d-sm-none  d-md-none d-lg-block">
                {address && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <i className="ti-location-pin" />
                    </div>
                    <div className="info">
                      <h6>{camelize(addressLowerCase)}</h6>
                    </div>
                  </div>
                )}
                {website && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <a
                        href={website}
                        target="_blank"
                        aria-label="Website"
                        rel="noreferrer"
                      >
                        <i className="ti-world" />
                      </a>
                    </div>
                    <div className="info">
                      <h6>
                        <a
                          href={website}
                          target="_blank"
                          className="link-dark"
                          rel="noreferrer"
                        >
                          {website}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {email && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <i className="ti-email" />
                    </div>
                    <div className="info">
                      <h6>
                        <a
                          href={`mailto:${email}`}
                          target="_blank"
                          className="link-dark"
                          rel="noreferrer"
                        >
                          {email}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {phoneNumber && (
                  <div className="icon-box icon-box-two">
                    <div className="icon h3">
                      <a href={`tel:${phoneNumber}`} aria-label="Phone Number">
                        <i className="ti-mobile" />
                      </a>
                    </div>
                    <div className="info">
                      <h6>
                        <a href={`tel:${phoneNumber}`} className="link-dark">
                          {`${phoneNumber.substring(
                            2,
                            5,
                          )}-${phoneNumber.substring(
                            5,
                            8,
                          )}-${phoneNumber.substring(8)}`}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
                {Array.isArray(openHours) && (
                  <div className="icon-box icon-box-two align-items-start">
                    <div className="icon h3">
                      <i className="ti-time" />
                    </div>
                    <div className="info">
                      {openHours.map((item) => (
                        <h6 key={`open-hour-${item.day}`}>
                          <span style={{ width: '2.4rem' }}>
                            <FormattedMessage id={`day.abbr.${item.day}`} />
                          </span>{' '}
                          {moment(item.open, 'HH:mm').format('hh:mma')}
                          {' - '}
                          {moment(item.close, 'HH:mm').format('hh:mma')}
                        </h6>
                      ))}
                    </div>
                  </div>
                )}
                <div className="button d-none">
                  <Button className="icon-btn">
                    <i className="ti-heart" />
                  </Button>
                  <Button to="/" className="icon-btn">
                    <i className="ti-share" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dispensary-map d-none d-sm-none  d-md-none d-lg-block">
          <div
            className="ratio ratio-16x9"
            dangerouslySetInnerHTML={{ __html: iframe }}
          />
        </div>
      </div>
    </section>
  );
}

export default DispensaryDetailsArea;
