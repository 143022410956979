/* eslint-disable jsx-a11y/control-has-associated-label */
// @flow strict
import moment from 'moment';
import 'moment/locale/es';

import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Table,
} from 'reactstrap';

import type { Node } from 'react';

import RequestButton from '../cells/RequestButton';
import CartForm from '../cells/CartForm';
import CartItem from '../cells/CartItem';
import FormattedCurrency from '../atoms/FormattedCurrency';

import { useAppContextProvider } from '../app/context';

import type { Cart, Dispensary, StrainValidationErrors } from '../types';

type Props = {|
  data: Cart,
  store: ?Dispensary,
  removeItemCart: (index: number) => void,
  editQty: (index: number, qty: number) => void,
  onRedeemCoupon?: ?(code: string) => void,
  onRemoveCoupon?: ?() => void,
  errorRedeemCoupon?: ?StrainValidationErrors,
  onSubmit: ({
    orderType: string,
    note?: string,
    deliveryAddress?: string,
    pickup?: string,
  }) => void,
|};

function CartDetail({
  data,
  store,
  removeItemCart,
  editQty,
  onRedeemCoupon,
  onRemoveCoupon,
  errorRedeemCoupon,
  onSubmit,
}: Props): Node {
  const { messages } = useIntl();
  const { businessId, locationId } = useParams();
  const [lang] = useAppContextProvider();
  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    if (lang === 'en') {
      moment.locale('en-gb');
    }
    if (lang === 'es') {
      moment.locale('es');
    }
  }, [lang]);

  const { coupon, couponDiscount, items, createdAt, subTotal, total, tax } =
    data;
  const date = moment(createdAt);

  // $FlowFixMe
  const ref = React.useRef<{ handleSubmitExt: () => void }>(() => {});

  const onButtonClick = () => {
    if (ref.current) {
      ref.current.handleSubmitExt();
    }
  };

  // $FlowFixMe
  const handleOnChangeCouponCode = useCallback((event) => {
    setCouponCode(event.target.value);
  }, []);

  const handleOnRedeemCoupon = useCallback(() => {
    onRedeemCoupon?.(couponCode);
  }, [onRedeemCoupon, couponCode]);

  const handleOnRemoveCoupon = useCallback(() => {
    onRemoveCoupon?.();
  }, [onRemoveCoupon]);

  // $FlowFixMe
  const continueUrl = `/dispensaries/${businessId}/stores/${locationId}`;

  const products = (items || []).map((item, index) => (
    <CartItem
      key={`${item.productId}${item.weightID}`}
      item={item}
      editQty={editQty}
      removeItemCart={removeItemCart}
      index={index}
    />
  ));

  return (
    <Row className="cart-details">
      <Col lg="8">
        <div className="cart-view-container p-4">
          <p className="cart-view-label">
            <span>
              <FormattedMessage id="order-online.cart-detail.store" />
            </span>{' '}
            {store?.name}
          </p>
          <p className="cart-view-label">
            <span>
              <FormattedMessage id="order-online.cart-detail.date" />
            </span>
            {` ${date.format('LL')}`}
          </p>
          <p className="cart-view-label">
            <span>
              <FormattedMessage id="order-online.cart-detail.products" />
            </span>
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th className="pl-0" aria-label="Product Name">
                  <FormattedMessage id="order-online.cart-detail.products.name" />
                </th>
                <th className="pl-0" aria-label="Quantity">
                  <FormattedMessage id="order-online.cart-detail.products.qty" />
                </th>
                <th className="pl-0" aria-label="Product Price">
                  <FormattedMessage id="order-online.cart-detail.products.price" />
                </th>
                <th aria-label="Actions" />
              </tr>
            </thead>
            <tbody>{products}</tbody>
          </Table>
          <CartForm
            data={data}
            onSubmit={onSubmit}
            // $FlowFixMe
            ref={ref}
          />
        </div>
      </Col>
      <Col lg="4" className="cart-summary">
        <div className="cart-view-container p-4">
          <h5>
            <FormattedMessage id="order-online.cart-detail.summary.title" />
          </h5>
          <div className="data my-4">
            <p className="cart-view-label d-space-between">
              <span>
                <FormattedMessage id="order-online.cart-detail.summary.sub-total" />
              </span>
              <span>
                <FormattedCurrency value={subTotal} />
              </span>
            </p>
            <p className="cart-view-label d-space-between">
              <span>
                <FormattedMessage id="order-online.cart-detail.summary.tax" />
              </span>
              <span>
                <FormattedCurrency value={tax} />
              </span>
            </p>
            {couponDiscount > 0 && (
              <p className="cart-view-label d-space-between">
                <span>
                  <FormattedMessage id="order-online.cart-detail.summary.coupon.discount" />
                </span>
                <span className="text-danger">
                  <FormattedCurrency value={couponDiscount * -1} />
                </span>
              </p>
            )}
            <p className="cart-view-label d-space-between">
              <span>Total:</span>
              <span>
                <FormattedCurrency value={total} />
              </span>
            </p>
          </div>
          <div>
            <span className="bold mb-3">
              <FormattedMessage id="order-online.cart-detail.summary.discount" />
            </span>
            {false && (
              <FormGroup>
                <ButtonGroup className="d-flex">
                  <Button className="coupon">
                    <FormattedMessage id="order-online.cart-detail.summary.coupon" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
            )}
            <Collapse isOpen>
              <FormGroup>
                <InputGroup>
                  <Input
                    placeholder={
                      messages[
                        'order-online.cart-detail.summary.placeholder.coupon-code'
                      ]
                    }
                    type="text"
                    className="form-control redeem-coupon"
                    value={couponCode}
                    onChange={handleOnChangeCouponCode}
                    readOnly={coupon != null}
                  />
                  {coupon == null ? (
                    <Button color="success" onClick={handleOnRedeemCoupon}>
                      <i className="ti-check" />
                    </Button>
                  ) : (
                    <Button color="danger" onClick={handleOnRemoveCoupon}>
                      <i className="ti-trash" />
                    </Button>
                  )}
                </InputGroup>
                <FormFeedback
                  valid={errorRedeemCoupon == null}
                  className={errorRedeemCoupon == null ? '' : 'd-block'}
                >
                  {errorRedeemCoupon?.errors[0].description}
                </FormFeedback>
              </FormGroup>
            </Collapse>
          </div>
          <Row className="mb-3 buttons">
            <Col>
              <RequestButton
                textId="order-online.cart-detail.form.complete-order"
                className="w-100"
                onClick={onButtonClick}
              />
            </Col>
          </Row>
          <Row className="buttons text-center">
            <Col>
              <Link to={continueUrl} className="w-100 continue-shopping">
                <FormattedMessage id="order-online.cart-detail.form.continue-shopping" />
              </Link>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default CartDetail;
