// @flow strict
import type { Node } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop(): Node {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!pathname.includes('related-products') && !pathname.includes('info')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;
