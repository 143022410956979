// @flow strict
import type { ElementConfig, Node } from 'react';

import { NavLink as NavLinkR } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

type Props = {|
  children: Node,
  toggle: (e: string) => void,
  currentActiveTab: string,
  index: string,
  props: ElementConfig<typeof NavItem>,
|};

function TabNav({
  children,
  toggle,
  currentActiveTab,
  index,
  props,
}: Props): Node {
  return (
    <NavItem>
      {!props && (
        <NavLink
          className={`${currentActiveTab === index ? 'active' : ''}`}
          onClick={() => toggle(index)}
        >
          {children}
        </NavLink>
      )}
      {props && (
        <NavLinkR
          {...props}
          active={(currentActiveTab === index).toString()}
          onClick={() => toggle(index)}
        >
          {children}
        </NavLinkR>
      )}
    </NavItem>
  );
}

export default TabNav;
