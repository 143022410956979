// @flow strict
import { Container } from 'reactstrap';

import { useParams } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';

import type { Node } from 'react';

import CartDetail from '../organisms/CartDetails';
import QueryResult from '../organisms/QueryResult';
import ProductListLoading from '../cells/Shimmers/ProductDetailLoading';
import NotFound from '../cells/NotFound';
import SuccessSubmitModal from '../cells/SuccessSubmitModal';
import { getValidationErrors } from '../utils';

import { getPatientId } from '../helpers';
import {
  useCart,
  useSubmitCart,
  useEditQtyItemCart,
  useRemoveItemCart,
  useRedeemCoupon,
  useRemoveCoupon,
} from '../hooks/cart';

function OrderOnline(): Node {
  const { businessId, locationId } = useParams();
  const [isOpen, setisOpen] = useState(false);
  // $FlowFixMe
  const patientId = getPatientId(businessId)?.patientId;
  const [
    getCart,
    {
      loading,
      // $FlowFixMe
      data: { business, cart, store },
      error,
    },
  ] = useCart();

  useEffect(() => {
    if (patientId !== undefined) {
      // $FlowFixMe
      getCart({
        variables: {
          locationId: +locationId,
          businessId: +businessId,
          patientId,
        },
        context: {
          headers: {
            'Strain-Business-Id': +businessId,
          },
        },
      });
    }
  }, [getCart, patientId, businessId, locationId]);

  const [requestSubmitCart] = useSubmitCart();

  const [requestEditQtyCart] = useEditQtyItemCart();
  const handleEditQty = (index: number, quantity: number) => {
    // $FlowFixMe
    requestEditQtyCart({
      variables: {
        index,
        quantity,
        filter: {
          patientId,
          locationId: +locationId,
        },
      },
      context: {
        headers: {
          'Strain-Business-Id': +businessId,
        },
      },
    });
  };

  const [requestRemoveItemCart] = useRemoveItemCart();
  const handleRemoveItemCart = (index: number) => {
    // $FlowFixMe
    requestRemoveItemCart({
      variables: {
        index,
        filter: {
          patientId,
          locationId: +locationId,
        },
      },
      context: {
        headers: {
          'Strain-Business-Id': +businessId,
        },
      },
    });
  };

  const [requestRedeemCoupon, { error: errorRedeemCoupon }] = useRedeemCoupon();
  const handleRedeemCoupon = (code: string) => {
    // $FlowFixMe
    requestRedeemCoupon({
      variables: {
        code: code.trim(),
        filter: {
          patientId,
          locationId: +locationId,
        },
      },
      context: {
        headers: {
          'Strain-Business-Id': +businessId,
        },
      },
    });
  };

  const [requestRemoveCoupon] = useRemoveCoupon();
  const handleRemoveCoupon = () => {
    // $FlowFixMe
    requestRemoveCoupon({
      variables: {
        locationId: +locationId,
        patientId,
      },
      context: {
        headers: {
          'Strain-Business-Id': +businessId,
        },
      },
    });
  };

  const handleSubmit = useCallback(
    ({
      orderType,
      deliveryAddress,
      pickup,
      note,
    }: {
      deliveryAddress?: string,
      note?: string,
      orderType: string,
      pickup?: string,
      ...
    }) => {
      let deliveryStartAt;
      let deliveryEndAt;

      if (pickup != null && pickup !== '') {
        [deliveryStartAt, deliveryEndAt] = pickup.split(',');
      }

      // $FlowFixMe
      requestSubmitCart({
        variables: {
          filter: {
            locationId: +locationId,
            patientId,
          },
          cart: {
            channel: 'MARKETPLACE',
            orderType,
            deliveryAddress,
            customerNotes: note,
            deliveryStartAt,
            deliveryEndAt,
          },
        },
        context: {
          headers: {
            'Strain-Business-Id': +businessId,
          },
        },
        // $FlowFixMe
        onCompleted: ({ submitCart }) => {
          if (submitCart) {
            setisOpen(true);
          }
        },
      });
    },
    [requestSubmitCart, patientId, locationId, businessId],
  );

  return (
    <div className="product-detail-one">
      <div className="product-detail-one-top">
        <Container fluid className="content">
          {isOpen && (
            <SuccessSubmitModal
              message={business.inAppMessages.orderSubmitted}
            />
          )}
          <div className="cart-view container">
            <QueryResult
              loading={loading}
              isEmpty={cart == null}
              error={error}
              placeholder={<ProductListLoading />}
              notFound={<NotFound element="cart" />}
            >
              <CartDetail
                data={cart}
                store={store}
                removeItemCart={handleRemoveItemCart}
                editQty={handleEditQty}
                onSubmit={handleSubmit}
                onRedeemCoupon={handleRedeemCoupon}
                onRemoveCoupon={handleRemoveCoupon}
                errorRedeemCoupon={getValidationErrors(errorRedeemCoupon)}
              />
            </QueryResult>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default OrderOnline;
