// @flow strict

import { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { List, ListInlineItem, NavLink } from 'reactstrap';
import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';

import type { Node } from 'react';

import { coordinates } from '../graphql/reactive-variables';
import Rating from '../molecules/Rating';
import Image from '../atoms/Image';
import isOpen from '../helpers';
import { getDistance } from '../helpers/geolib';

import type { Dispensary } from '../types';

type Props = {|
  data: Dispensary,
  className?: ?string,
  showDistance?: boolean,
|};

function DispensaryCard({
  data,
  className,
  showDistance = false,
}: Props): Node {
  const {
    id,
    name,
    address,
    phoneNumber,
    openHours,
    image,
    point,
    isFeatured,
    business,
  } = data;
  const { logo } = business;
  const { lat, lng } = point;
  const currentPosition = useReactiveVar(coordinates);

  const status = isOpen(openHours) ? 'open' : 'close';
  const dispensaryURL = `/dispensaries/${business.id}/stores/${id}`;

  const distance = useMemo(() => {
    return showDistance ? getDistance(currentPosition, point) : 0;
  }, [currentPosition, point, showDistance]);

  return (
    <div className={cx('listing-item listing-grid-item-two', className)}>
      <div className="listing-thumbnail">
        <div>
          <Link to={dispensaryURL}>
            <Image src={image} alt="dispensary" />
          </Link>
        </div>
        <Link to={dispensaryURL} className="cat-btn">
          <img
            className="listing-icon"
            src={logo || '/assets/images/default-placeholder.png'}
            alt="dispensary"
          />
        </Link>
        {isFeatured && (
          <span className="featured-btn">
            <FormattedMessage id="home.dispensary.card.featured" />
          </span>
        )}
        <Rating rating="three" className="d-none" />
      </div>
      <div className="listing-content">
        <div className="info">
          <div>
            <Link to={dispensaryURL}>
              <h5 className="title mb-3">{name}</h5>
            </Link>
            <p>
              {address.length > 40
                ? `${address.substring(0, 40)} ....`
                : address}
            </p>
            <span className="phone-meta">
              {phoneNumber && <i className="ti-mobile" />}
              <a href={`tel:${phoneNumber}`}>{`${phoneNumber.substring(
                2,
                5,
              )}-${phoneNumber.substring(5, 8)}-${phoneNumber.substring(
                8,
              )}`}</a>
              <span className={`status st-${status}`}>
                <FormattedMessage
                  id={`home.dispensary.card.status-${status}`}
                />
              </span>
            </span>
          </div>
          <div className="actions">
            <List>
              <ListInlineItem>
                <span>
                  <i className="ti-location-pin" color="red" />
                  <NavLink
                    href={`http://www.google.com/maps/place/${lat},${lng}`}
                  >
                    <FormattedMessage id="home.dispensary.card.map" />
                  </NavLink>
                </span>
              </ListInlineItem>
              {distance > 0 && (
                <ListInlineItem>
                  <span className="text-muted text-lowercase">
                    <i className="ti-map-alt" />
                    <FormattedNumber
                      value={distance}
                      maximumFractionDigits={0}
                    />{' '}
                    <FormattedMessage id="home.dispensary.card.miles-away" />
                  </span>
                </ListInlineItem>
              )}
            </List>
          </div>
        </div>
        <div className="listing-meta">
          <Link to={dispensaryURL} className="main-btn btn">
            <FormattedMessage id="home.dispensary.card.view-more" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DispensaryCard;
