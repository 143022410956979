// @flow strict
import type { Node } from 'react';
import cx from 'classnames';
import { Badge as BaseBadge } from 'reactstrap';

type Props = {|
  className?: ?string,
  children?: ?Node,
  color?: ?string,
  outline?: ?boolean,
  pill?: ?boolean,
  square?: ?boolean,
|};

function Badge({
  className = null,
  children = null,
  color = null,
  outline = false,
  pill = false,
  square = false,
}: Props): Node {
  return (
    <BaseBadge
      className={cx(className, {
        [`badge-outline-${color ?? ''}`]: outline,
        'squared-pill': square,
      })}
      color={color}
      pill={pill}
    >
      {children}
    </BaseBadge>
  );
}

export default Badge;
