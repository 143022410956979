// @flow strict
import * as Sentry from '@sentry/react';

import { ApolloProvider } from '@apollo/client';

import type { Node } from 'react';

import AgeGate from '../organisms/AgeGate';
import AppContextProvider from './context';
import AppRouter from './router';
import client from './graphql-client';

function App(): Node {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <AppRouter />
        <AgeGate />
      </AppContextProvider>
    </ApolloProvider>
  );
}

export default (Sentry.withProfiler(App): Node);
