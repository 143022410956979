// @flow strict
import { useLazyQuery, useMutation } from '@apollo/client';

import type { Cart, Business, Dispensary, ErrorMessage } from 'types';
import type { MutationTuple, QueryTuple } from '@apollo/client';

import GRAPHQL from '../graphql';

export const useAddToCart = (options?: ?{}): MutationTuple<?Cart, {}> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.ADD_CART, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data?.addItemToCart,
    },
  ];
};

export const useCart = (
  options?: ?{},
): QueryTuple<
  { cart: Cart, business: Business, store: Dispensary },
  {|
    businessId: number,
    patientId: string,
    locationId: number,
  |},
> => {
  // $FlowFixMe
  const [getCart, { data, ...rest }] = useLazyQuery(GRAPHQL.CART.CART_DETAIL, {
    ...options,
    // fetchPolicy: 'network-only',
  });
  // $FlowFixMe
  return [
    // $FlowFixMe
    getCart,
    // $FlowFixMe
    {
      ...rest,
      // $FlowFixMe
      data: {
        // $FlowFixMe
        cart: data?.cart ?? {},
        // $FlowFixMe
        store: data?.store ?? {},
        // $FlowFixMe
        business: data?.store?.business ?? {},
      },
    },
  ];
};

export const useSubmitCart = (options?: ?{}): MutationTuple<?Cart, {}> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.SUBMIT, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data,
    },
  ];
};

export const useRemoveItemCart = (options?: ?{}): MutationTuple<?Cart, {}> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.REMOVE_ITEM, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data,
    },
  ];
};

export const useEditQtyItemCart = (options?: ?{}): MutationTuple<?Cart, {}> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.EDIT_QTY_ITEM, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data,
    },
  ];
};

export const useRedeemCoupon = (
  options?: ?{},
): MutationTuple<
  ?{
    object: Cart,
    errorMessages: ErrorMessage[],
  },
  {},
> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.REDEEM_COUPON, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data?.redeemCoupon,
    },
  ];
};

export const useRemoveCoupon = (
  options?: ?{},
): MutationTuple<
  ?{
    object: Cart,
    errorMessages: ErrorMessage[],
  },
  {},
> => {
  const [mutator, response] = useMutation(GRAPHQL.MUTATION.CART.REMOVE_COUPON, {
    ...options,
    errorPolicy: 'all',
  });

  return [
    // $FlowFixMe
    mutator,
    // $FlowFixMe
    {
      ...response,
      // $FlowFixMe
      data: response?.data?.redeemCoupon,
    },
  ];
};
