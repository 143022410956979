// @flow strict

import Slider from 'react-slick';

import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import type { Node } from 'react';

import SoldProductCard from './SoldProductCard';
import ProductListLoading from './Shimmers/ProductListLoading';
import QueryResult from '../organisms/QueryResult';

import { useRelatedProducts } from '../hooks/product';

function RelatedProducts(): Node {
  const { businessId, locationId, productId } = useParams();

  const {
    data: products,
    loading,
    error,
    // $FlowFixMe
  } = useRelatedProducts({
    variables: {
      itemNumber: productId,
      locationId: parseInt(locationId, 10),
    },
    context: {
      headers: {
        'Strain-Business-Id': parseInt(businessId, 10),
      },
    },
  });

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    centerPadding: '60px',
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <QueryResult
      error={error}
      isEmpty={products.length <= 0}
      loading={loading}
      placeholder={<ProductListLoading />}
      notFound={null}
    >
      <h4 className="mb-4">
        <FormattedMessage id="product-detail.tab.related-product" />
      </h4>
      <div className="mb-4">
        <Slider {...settings}>
          {products.map((item) => (
            <div className="slider-item px-2" key={item.id}>
              <SoldProductCard
                product={item}
                showTaxes={item.store.showTaxes}
                storeName={item.store.name}
                logo={item.store.image}
                // $FlowFixMe
                locationId={locationId}
                // $FlowFixMe
                businessId={businessId}
              />
            </div>
          ))}
        </Slider>
      </div>
    </QueryResult>
  );
}

export default RelatedProducts;
