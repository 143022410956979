// @flow strict
import type { Node } from 'react';
import { FormattedNumber, IntlProvider } from 'react-intl';

import { CURRENCY } from '../constants';

type Props = {|
  value: mixed,
|};

function FormattedCurrency({ value }: Props): Node {
  return (
    <IntlProvider locale="en">
      <FormattedNumber
        currency={CURRENCY}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        value={value}
      />
    </IntlProvider>
  );
}

export default FormattedCurrency;
