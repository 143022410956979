// @flow strict
import esMessages from '../locales/es_ES';

/* eslint-disable global-require */
if (Intl.PluralRules == null) {
  // $FlowFixMe
  require('@formatjs/intl-pluralrules/polyfill');
  // $FlowFixMe
  require('@formatjs/intl-pluralrules/locale-data/es');
}

// $FlowFixMe
if (Intl.RelativeTimeFormat == null) {
  // $FlowFixMe
  require('@formatjs/intl-relativetimeformat/polyfill');
  // $FlowFixMe
  require('@formatjs/intl-relativetimeformat/locale-data/es');
}
/* eslint-enable global-require */

export default esMessages;
