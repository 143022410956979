// @flow strict
import type { Node } from 'react';
import Helmet from 'react-helmet';

function ProductsMetaTags(): Node {
  return (
    <Helmet>
      <title>
        Encuentra los mejores productos de cannabis cerca de ti en Puerto Rico
      </title>
      <meta
        name="title"
        content="Encuentra los mejores productos de cannabis cerca de ti en Puerto Rico"
      />
      <meta
        name="description"
        content="STRAIN Marketplace: Encuentra los productos de cannabis más populares de los dispensarios cerca de ti en Puerto Rico."
      />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}

export default ProductsMetaTags;
