// @flow strict
import type { Node } from 'react';
import Badge from './Badge';

type Props = {|
  className?: ?string,
  pill?: ?boolean,
  type: string,
|};

const COLOR_STRAIN_TYPES = {
  INDICA: 'ocean-blue',
  HYBRID: 'green-cyan',
  SATIVA: 'orange',
};

function StrainTypeBadge({
  className = null,
  pill = false,
  type = '',
}: Props): Node {
  const color = COLOR_STRAIN_TYPES[type] ?? 'success';
  return (
    <Badge color={color} pill={pill} className={className}>
      {type}
    </Badge>
  );
}

export default StrainTypeBadge;
