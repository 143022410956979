// @flow strict

export default {
  'common.add-cart': 'Añadir al Carrito',
  'common.continue': 'Continuar',
  'common.updated-5-minutes-ago': 'Actualizado hace 5 minutos',
  'common.no.products.found': 'No se han encontrado productos',
  'common.no.dispensaries.found': 'No se han encontrado dispensarios',
  'common.requesting-geopoint': 'Obteniendo punto geográfico',
  'common.close': 'Cerrar',

  'nav-menu.dispensaries': 'Dispensarios',
  'nav-menu.products': 'Productos',
  'nav-menu.blog': 'Blog',

  'header.top-bar.follow': 'Síguenos',
  'header.top-bar.cart': 'Carrito',
  'header.top-bar.content': 'Solo hoy 50% descuento en',
  'header.top-bar.offer': 'Ver oferta',
  'header.nav-bar.logo': 'Marketplace',
  'header.nav-bar.certificate': 'Certificate Hoy',
  'header.nav-bar.dispensary': 'Ver Dispensarios',
  'header.nav-bar.language': 'Idioma',
  'home.lightbox.title': 'Para una experiencia más rápida,',
  'home.lightbox.title2': 'ingresa tu ID de paciente de Cannabis.',
  'home.lightbox.input-patient': 'Ej. PAxxxxxxx',
  'home.lightbox.continue': 'Continuar',
  'home.lightbox.continue-guest': 'Continuar como visitante',
  'home.lightbox.no-patient-id-in-records':
    'No hemos encontrado tu licencia de paciente. Favor continuar como visitante.',
  'home.area.title': 'La Red Más Grande de Dispensarios en Puerto Rico',
  'home.area.description': 'Encuentra tus productos favoritos al mejor precio',
  'home.area.dispensaries': 'Dispensarios',
  'home.area.brands': 'Marcas',
  'home.area.doctors': 'Doctores',
  'home.area.popular': 'Populares',
  'home.area.popular-data': 'UMA , Comestibles, Madlove, Flores, Aceites, 1919',
  'home.area.search-filter': 'Buscar Dispensario',
  'home.area.search-btn': 'Buscar',
  'home.area.filter-opt': 'Cercanos a mi',
  'home.category.title': 'Destacadas',
  'home.category.title2': 'Categorías',
  'home.category.accessories': 'Accesorios',
  'home.category.drinks': 'Bedidas',
  'home.category.flowers': 'Flores',
  'home.category.cartridges': 'Cartuchos',
  'home.category.edibles': 'Comestibles',
  'home.category.concentrates': 'Concentrados',
  'home.category.topicals': 'Tópicos',
  'home.category.tinctures': 'Tinturas',
  'home.dispensary.title': 'Destacados',
  'home.dispensary.title2': 'Dispensarios',
  'home.dispensary.sub-title': 'Los Favoritos!',
  'home.dispensary.card.miles-away': 'Millas de ti',
  'home.dispensary.card.favorite': 'Favorito',
  'home.dispensary.card.map': 'Ver Mapa',
  'home.dispensary.card.status-open': 'Abierto',
  'home.dispensary.card.status-close': 'Cerrado',
  'home.dispensary.card.view-more': 'Ver Dispensario',
  'home.dispensary.card.featured': 'Destacado',
  'home.brand.title': 'Marcas Favoritas',
  'home.brand.sub-title': 'Las mas buscadas',
  'home.brand.show-brands': 'Ver Más Marcas',
  'home.offer.title': 'Ofertas',
  'home.offer.title2': 'Mejores',
  'home.offer.sub-title': 'Mas por tu dinero!',
  'home.offer.expiration': 'Expira ',
  'home.recommended.title': 'Recomendados',
  'home.recommended.title2': 'Productos',
  'home.recommended.sub-title': 'Los Favoritos!',
  'home.recommended.filter.All': 'Todos',
  'home.recommended.filter.Anxiety': 'Ansiedad',
  'home.recommended.filter.Cancer': 'Cancer',
  'home.recommended.filter.Depression': 'Depresión',
  'home.recommended.filter.Pain': 'Dolor',
  'home.recommended.filter.Insomnia': 'Insomnio',
  'home.show-all': 'Ver Todos >',
  'home.show-all-2': 'Ver Todas >',
  'home.ribbon-banner.title': 'Ahorra con las mejores ofertas cerca de ti!',
  'home.ribbon-banner.offer': 'Ver Ofertas',
  'home.sold-product.title': 'cercanos',
  'home.sold-product.title2': 'Productos más',
  'home.sold-product.sub-title': 'Los que no duran!',
  'home.sold-product.filter.Flowers': 'Flores',
  'home.sold-product.filter.Cartridges': 'Cartuchos',
  'home.sold-product.filter.Edibles': 'Comestibles',
  'home.sold-product.filter.Pre-rolls': 'Pre-rollos',
  'home.sold-product.filter.Tinctures': 'Tinturas',
  'home.sold-product.filter.Topicals': 'Tópicos',
  'home.sold-product.filter.Capsules': 'Cápsulas',
  'home.sold-product.filter.Syringes': 'Jeringas',
  'home.testimonial.title': 'Por que nos',
  'home.testimonial.title2': 'Aman?',
  'home.instagram.title': 'Taggeanos #chillconSTRAIN',
  'home.instagram.sub-title': 'Nuestra Comunidad',
  'home.how-work.sub-title': 'No sabes por donde empezar?',
  'home.how-work.title': 'Es tan simple como',
  'home.medical-banner.title':
    'Con Cannabis tendras una vida feliz y saludable!',
  'home.medical-banner.description':
    'Obtén o renueva tu licencia de Cannabis Medicinal en 24 horas o menos con nuestros medicos certificados.',
  'home.medical-banner.button': 'Certificate Aqui',
  'home.budtender-banner.title':
    'La importancia de usar la cepa correcta a la hora correcta',
  'home.budtender-banner.description':
    'Luego de ver este video aprenderas cual tipo de flor es la mas adecuada para tu estilo de vida. Ya sea para enfocarte en tu trabajo, aumentar la creatividad o simplemente relajarte luego del dia con este video podrás conocer mas. ',
  'home.budtender-banner.sub-title': 'Tu Budtender [Indio Hernandez]',
  'home.budtender-banner.button': 'Ver Video',
  'list-dispensary.filter-default': 'Más Cercano',
  'list-dispensary.filter': 'Alfabético',
  'list-dispensary.filter.all': 'Todos',
  'list-dispensary.filter.featured': 'Destacados',
  'list-dispensary.filter.open': 'Abiertos',
  'list-dispensary.show-result': 'Mostrar Resultados',
  'dispensary.filter-opt': 'Todos',
  'dispensary.filter-search-key': 'Buscar por nombre',
  'dispensary.filter.label.search': 'Nombre de Dispensario',
  'dispensary.filter.label.category': 'Seleccionar Categoria',
  'dispensary.filter.label.city': 'Seleccionar Pueblo',
  'dispensary.filter.search-now': 'Buscar Ahora',
  'dispensary.title': 'Dispensario',
  'dispensary.about': 'Acerca del Dispensario',
  'dispensary.features.title': 'Productos y Servicios',
  'dispensary.products.title': 'Top Productos',
  'dispensary.listing-tag.title': 'Tags Populares',
  'dispensary.listing-faq.title': 'Preguntas Solicitadas',
  'dispensary.products.load-more': 'Mostrar más',
  'product-detail.content.quantity': 'Cantidad Disponible',
  'product-detail.content.size': 'Seleccione el peso del producto: ',
  'product-detail.content.tax': 'Impuesto incluido',
  'product-detail.tab.add-information': 'Informacion Adicional',
  'product-detail.tab.related-product': 'Productos Relacionados',
  'product.filter.label.city': 'Seleccionar Pueblo',
  'product.filter.label.category': 'Seleccionar Categoría',
  'product.filter.label.brand': 'Seleccionar Marca',
  'product.filter.label.sortby': 'Ordenar Por',
  'product.filter.label.strain-type': 'Seleccionar Cepa',
  'product.filter.placeholder.search-products': 'Buscar productos',
  'product.filter.option.all-categories': 'Todas',
  'product.filter.option.all-brands': 'Todas',
  'product.filter.option.all-strain-types': 'Todas',
  'product.filter.sort.option.featured': 'Destacado',
  'product.filter.sort.option.name': 'Nombre',
  'product.filter.sort.option.newest': 'Más reciente',
  'product.filter.sort.option.most-sold': 'Más vendido',
  'product.filter.strain-type.option.hybrid': 'Híbrido',
  'product.filter.strain-type.option.indica': 'Indica',
  'product.filter.strain-type.option.sativa': 'Sativa',
  'product.weight-gr': 'g.',
  'product.weight-each': 'Unidad',
  'product.weight-plural': 'Unidades',
  'order-online.change-alert-message':
    'Navegar fuera del dispensario donde se encuentra reiniciará su carrito',
  'order-online.continue-shopping-dispensary': 'Continuar Comprando',
  'order-online.cart-detail.store': 'Tienda: ',
  'order-online.cart-detail.date': 'Fecha: ',
  'order-online.cart-detail.products': 'Productos: ',
  'order-online.cart-detail.products.qty': 'Cantidad',
  'order-online.cart-detail.products.name': 'Nombre de Producto',
  'order-online.cart-detail.products.price': 'Precio',
  'order-online.cart-detail.form.type': 'Seleccionar tipo de orden: ',
  'order-online.cart-detail.form.pick-up': 'Recoger',
  'order-online.cart-detail.form.delivery': 'Entrega (Pronto)',
  'order-online.cart-detail.form.address': 'Direccion de compra: *',
  'order-online.cart-detail.form.pickup-time': 'Hora para recoger',
  'order-online.cart-detail.form.notes': 'Notas de la orden:',
  'order-online.cart-detail.form.notes-placeholder':
    'Añade aquí cualquier requerimiento o detalle adicional sobre tu orden.',
  'order-online.cart-detail.form.pickup-placeholder': 'Seleccionar hora',
  'order-online.cart-detail.form.continue-shopping': 'Continuar comprando',
  'order-online.cart-detail.form.complete-order': 'Completar orden',
  'order-online.cart-detail.summary.title': 'Resumen de la orden',
  'order-online.cart-detail.summary.sub-total': 'Subtotal: ',
  'order-online.cart-detail.summary.tax': 'Impuesto: ',
  'order-online.cart-detail.summary.discount': 'Aplicar descuento',
  'order-online.cart-detail.summary.coupon.discount': 'Descuento de Cupón',
  'order-online.cart-detail.summary.coupon': 'Cupón',
  'order-online.cart-detail.summary.placeholder.coupon-code': 'Código de Cupón',
  'order-online.continue-shopping': 'Continuar comprando',
  'order-online.view-cart': 'Ver carrito',
  'order-online.cancel-order': 'Cancelar Orden',
  'order-online.or': 'O',
  'order-online.added-success-message':
    'El producto se agregó con éxito al pedido.',
  'order-online.cart-detail.order-terms':
    'RECOGIDA EXPRESS: Los pedidos se retendrán durante las 24 horas posteriores a la hora de reserva seleccionada. TODOS LOS DESCUENTOS SE APLICARÁN AL MOMENTO DEL PAGO.',
  'order-online.guest-checkout': 'Continuar como Visitante',

  'day.abbr.MONDAY': 'Lun.',
  'day.abbr.TUESDAY': 'Mar.',
  'day.abbr.WEDNESDAY': 'Mie.',
  'day.abbr.THURSDAY': 'Jue.',
  'day.abbr.FRIDAY': 'Vie.',
  'day.abbr.SATURDAY': 'Sab.',
  'day.abbr.SUNDAY': 'Dom.',

  'user.name': 'Nombre',
  'user.license': 'ID de Paciente',
  'user.placeholder.license': 'Ej. PAxxxxxxx',
  'user.placeholder.phoneNumber': 'Ej. 787-777-7777',
  'user.phoneNumber': 'Teléfono',

  'validation.required-fields': 'Campos requeridos',
  'validation.required-field': 'Campo requerido',

  /* Categories */
  'category.all': 'Todos',
  'category.accessories': 'Accesorios',
  'category.capsules': 'Cápsulas',
  'category.cartridges': 'Cartuchos',
  'category.cbd': 'CBD',
  'category.clones': 'Clones',
  'category.concentrates': 'Concentrados',
  'category.drinks': 'Bebidas',
  'category.edibles': 'Comestibles',
  'category.extracts': 'Extractos',
  'category.disposable-pens': 'Plumas Desechables',
  'category.disposable-vapes': 'Cartuchos Desechables',
  'category.flower': 'Flores',
  'category.inhalers': 'Inhaladores',
  'category.kief': 'Kief',
  'category.merch': 'Ropa',
  'category.moonrocks': 'Moonrocks',
  'category.oils': 'Aceites',
  'category.other': 'Otros',
  'category.pets': 'Mascotas',
  'category.prerolls': 'Pre-enrolados',
  'category.seeds': 'Semillas',
  'category.shake': 'Shake',
  'category.specials': 'Especiales',
  'category.specialty': 'Especialidad',
  'category.suppositories': 'Supositorios',
  'category.syringes': 'Jeringas',
  'category.tablets': 'Tabletas',
  'category.tinctures': 'Tinturas',
  'category.topicals': 'Tópicos',
  'category.trim': 'Trim',
  'category.vaporizers': 'Vaporizadores',
  'category.wax': 'Wax',

  'age-gate.title': 'Debes tener más de 21 años para entrar',
  'age-gate.verify-age': 'Favor de verificar tu edad',
  'age-gate.under-21': 'Soy menor de 21 años',
  'age-gate.above-21': 'Soy mayor de 21 años',
  'age-gate.body':
    'Al ingresar a esta página estás aceptando nuestros <tc>Términos y Condiciones</tc> así como nuestro <pt>Aviso de Privacidad</pt>',
};
