// @flow strict
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup } from 'reactstrap';
import slugify from 'slugify';

import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import type { Node } from 'react';

import DispensaryAvatar from '../molecules/DispensaryAvatar';
import QuantitySelector from '../cells/QuantitySelector';
import ZoomImage from '../cells/ZoomImage';
import LightBox from './LightBox';
import POSPatientRegisterModal from './PatientRegisterModal';
import Badge from '../atoms/Badge';
import FormattedCurrency from '../atoms/FormattedCurrency';
import StrainTypeBadge from '../atoms/StrainTypeBadge';
import RequestButton from '../cells/RequestButton';

import { useAddToCart } from '../hooks/cart';
import { getPatientId, addPatientId } from '../helpers';
import {
  updateCartCount,
  addProductInformationVar,
  currentCartVar,
} from '../graphql/reactive-variables';

import { WEIGHT_TYPE } from '../constants';
import type { Product, Dispensary } from '../types';

type Props = {|
  product: ?Product,
  store: ?Dispensary,
  quantityControllerNoRound: boolean,
|};

function ProductDetailContent({
  product,
  store,
  quantityControllerNoRound,
}: Props): Node {
  const {
    type,
    variants,
    percentageTHC,
    thcUnitMeasurement,
    percentageCBD,
    cbdUnitMeasurement,
  } = product ?? { variants: [] };
  const { businessId, locationId, productId } = useParams();
  const [modal, setModal] = useState(false);
  const [showPatientRegister, setShowPatientRegister] = useState(false);
  const [variant, setVariant] = useState(variants[0]);
  const [quantity, setQuantity] = useState(1);
  const avaiableQuantity = 2;

  // $FlowFixMe
  const [requestAddToCart, { data: cartResponse, loading }] = useAddToCart({
    context: {
      headers: { 'Strain-Business-Id': +businessId },
    },
  });

  const handleAddToCart = useCallback(
    (patientId: string) => {
      // $FlowFixMe
      requestAddToCart({
        variables: {
          cartItem: {
            productId,
            quantity,
            productVariantId: variant.id,
          },
          filter: {
            patientId,
            locationId: +locationId,
            cartType: 'MED',
          },
        },
        // $FlowFixMe
        onCompleted: ({ addItemToCart }) => {
          if (addItemToCart != null) {
            currentCartVar({
              businessId,
              locationId,
            });
            addProductInformationVar({
              isOpen: true,
              // $FlowFixMe
              continueUrl: `/dispensaries/${businessId}/stores/${locationId}`,
              // $FlowFixMe
              cartUrl: `/order-online/${businessId}/stores/${locationId}`,
            });
          }
        },
      });
    },
    [businessId, locationId, productId, quantity, requestAddToCart, variant.id],
  );

  const handleSetCartCount = useCallback((cont: number) => {
    updateCartCount({
      quantity: cont,
    });
  }, []);

  const togglePatientRegisterModal = useCallback(() => {
    setShowPatientRegister((value) => !value);
  }, []);

  const handleOnSuccessRegistration = useCallback(
    (patientId: string) => {
      setShowPatientRegister(false);
      // $FlowFixMe
      addPatientId(patientId, businessId.toString());
      handleAddToCart(patientId);
    },
    [handleAddToCart, businessId],
  );

  const onAddProductToCart = () => {
    if (avaiableQuantity === 0) {
      return;
    }
    // $FlowFixMe
    const patientId = getPatientId(businessId)?.patientId;
    if (patientId !== undefined) {
      handleAddToCart(patientId);
    } else {
      setModal(true);
    }
  };

  const handleOnContinueAsGuest = useCallback(() => {
    setModal(false);
    setShowPatientRegister(true);
  }, []);

  useEffect(() => {
    if (cartResponse?.items) {
      handleSetCartCount(cartResponse.items.length);
    }
  }, [handleSetCartCount, cartResponse]);

  const onSetVariant = (index: number) => {
    setVariant(variants[index]);
  };

  const variantsData = variants.map((value, index) => (
    <Button
      key={value.id}
      outline
      onClick={() => onSetVariant(index)}
      active={variant.id === value.id}
    >
      <h6>{value.name}</h6>${value.price.price}
    </Button>
  ));

  const hasDiscount = variant.price.discount > 0;

  let price = variant.price.price - variant.price.discount;
  let basePrice = variant.price.price;

  if (store?.showTaxes === true) {
    price += variant.price.taxes;
    basePrice += variant.price.taxesExclDiscount;
  }

  return (
    <div className="product-detail-content-one">
      <LightBox
        isOpen={modal}
        toggle={() => setModal((value) => !value)}
        // $FlowFixMe
        businessId={businessId}
        handleAddToCart={handleAddToCart}
        onContinueAsGuest={handleOnContinueAsGuest}
      />
      <POSPatientRegisterModal
        locationId={+locationId}
        businessId={+businessId}
        isOpen={showPatientRegister}
        toggle={togglePatientRegisterModal}
        onSuccessRegistration={handleOnSuccessRegistration}
      />
      <FormGroup>
        <DispensaryAvatar
          logo={store?.business.logo ?? ''}
          name={store?.name ?? ''}
          // $FlowFixMe
          businessId={businessId}
          // $FlowFixMe
          locationId={locationId}
        />
      </FormGroup>
      <h3>{product?.name}</h3>
      <div className="product-detail-content-one-rate">
        <span className="category product-detail-content-one-review-count">
          <FormattedMessage
            id={`category.${slugify(product?.category.name ?? '', {
              lower: true,
            })}`}
          />
        </span>
        {product?.brand && product.category.name && (
          <span className="category divider">{` - `}</span>
        )}
        {product?.brand && <span className="brand">{product.brand.name}</span>}
      </div>

      <div className="d-lg-none">
        <ZoomImage
          disableOnMobile
          src={product?.image ?? ''}
          alt="Product image"
        />
      </div>
      <span className="product-detail-content-one-data">
        {type != null && <StrainTypeBadge type={type} />}
        {percentageTHC !== null && (
          <Badge color="success" outline>
            THC: {percentageTHC}
            {thcUnitMeasurement === 'PERCENTAGE' ? '%' : thcUnitMeasurement}
          </Badge>
        )}
        {percentageCBD !== null && (
          <Badge color="warning" outline>
            CBD: {percentageCBD}
            {cbdUnitMeasurement === 'PERCENTAGE' ? '%' : cbdUnitMeasurement}
          </Badge>
        )}
      </span>
      {(product?.variants.length ?? 0) > 1 && (
        <div className="product-detail-content-one-weight mb-4">
          <h6 className="mb-3">
            <FormattedMessage id="product-detail.content.size" />
          </h6>
          {variantsData}
        </div>
      )}
      <div className="product-detail-content-one-rate">
        <span className="product-detail-content-one-review-count fw-bold">
          <FormattedMessage id="product-detail.content.quantity" />:
        </span>
        <span className="ms-1">
          {Math.floor(variant.quantity / variant.weight)}
        </span>
      </div>
      <div className="product-detail-content-one-actions mb-4">
        <QuantitySelector
          noRound={quantityControllerNoRound}
          defaultValue={quantity}
          onChange={(value) => setQuantity(value)}
          size="big"
          max={Math.floor(variant.quantity / variant.weight)}
          min={1}
        />
        <div>
          {store?.showTaxes !== null && (
            <div className="product-detail-content-one-taxes">
              <span>
                <FormattedMessage id="product-detail.content.tax" />
              </span>
            </div>
          )}
          <div className="product-detail-content-one-price">
            <h4>
              <FormattedCurrency value={quantity * price} />
              {product?.variants && (
                <span className="detail-weight">
                  {`/ ${product.variants[0].weight}`}
                  <span>
                    <FormattedMessage
                      id={
                        product.variants[0].weight > 1
                          ? 'product.weight-plural'
                          : WEIGHT_TYPE[product.variants[0].uom]
                      }
                    />
                  </span>
                </span>
              )}
            </h4>

            {hasDiscount ? (
              <span className="text-muted ml-1">
                <small>
                  <del>
                    <FormattedCurrency value={quantity * basePrice} />
                  </del>
                </small>
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="product-detail-content-one-actions mb-4">
        <RequestButton
          loading={loading}
          className={`main-btn btn-success product-detail-content-one-atc ${avaiableQuantity}`}
          onClick={onAddProductToCart}
          iconClass="ti-shopping-cart"
          textId="common.add-cart"
          disabled={avaiableQuantity === 0}
          type="link"
        />
      </div>
      <div
        className="product-detail-content-one-description"
        dangerouslySetInnerHTML={{ __html: product?.description }}
      />
    </div>
  );
}

export default ProductDetailContent;
