// @flow strict

import type { Node } from 'react';
import { NavLink } from 'reactstrap';

type Props = {| image: string, name: Node, id: number |};

function CategoryItem({ image, name, id }: Props): Node {
  return (
    <div className="category-item category-item-one">
      <NavLink href={`/products?category=${id}`}>
        <div className="info text-center">
          <div className="icon">
            <img src={image} alt={name} />
          </div>
          <h6>{name}</h6>
        </div>
        <div className="category-btn">
          <i className="ti-arrow-right" />
        </div>
      </NavLink>
    </div>
  );
}

export default CategoryItem;
