// @flow strict
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import type { Node } from 'react';

import CategoryItem from '../cells/CategoryItem';

const data = [
  {
    name: <FormattedMessage id="home.category.flowers" />,
    image: '/assets/images/categories/flores.png',
    key: 9,
  },
  {
    name: <FormattedMessage id="home.category.cartridges" />,
    image: '/assets/images/categories/aceites.png',
    key: 19,
  },
  {
    name: <FormattedMessage id="home.category.edibles" />,
    image: '/assets/images/categories/comestibles.png',
    key: 3,
  },
  {
    name: <FormattedMessage id="home.category.drinks" />,
    image: '/assets/images/categories/drinks.png',
    key: 4,
  },
  {
    name: <FormattedMessage id="home.category.concentrates" />,
    image: '/assets/images/categories/concentrados.png',
    key: 7,
  },
  {
    name: <FormattedMessage id="home.category.topicals" />,
    image: '/assets/images/categories/topicos.png',
    key: 14,
  },
  {
    name: <FormattedMessage id="home.category.tinctures" />,
    image: '/assets/images/categories/tinturas.png',
    key: 11,
  },
  {
    name: <FormattedMessage id="home.category.accessories" />,
    image: '/assets/images/categories/accesories.svg',
    key: 2,
  },
];

function FeaturedCategory(): Node {
  // const state useAppContextProvider
  const items = data.map((item) => (
    <Col className="col-lg col-md-3 col-6 category-column" key={item.key}>
      <CategoryItem image={item.image} name={item.name} id={item.key} />
    </Col>
  ));

  return (
    <section className="category-area">
      <Container fluid className="content mt-5">
        <Row className="justify-content-center">
          <Col className="col-lg-6">
            <div className="section-title section-title-two text-md-center">
              <h1>
                <span className="line">
                  <FormattedMessage id="home.category.title2" />{' '}
                  <FormattedMessage id="home.category.title" />
                </span>
              </h1>
            </div>
          </Col>
        </Row>
        <div className="category-wrapper-one wow fadeInDown">
          <Row className="no-gutters categories">{items}</Row>
        </div>
      </Container>
    </section>
  );
}

export default FeaturedCategory;
