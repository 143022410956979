/* eslint-disable jsx-a11y/control-has-associated-label */
// @flow strict
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, List, ListInlineItem, Row } from 'reactstrap';
import { useState, useEffect } from 'react';

import type { Node } from 'react';

import { useReactiveVar } from '@apollo/client';
import { getPatientId } from '../helpers';
import { useCart } from '../hooks/cart';
import { useAppContextProvider } from '../app/context';
import {
  cartCount,
  updateCartCount,
  currentCartVar,
} from '../graphql/reactive-variables';

function HeaderTop(): Node {
  const { businessId, locationId } = useParams();
  const [, setLang] = useAppContextProvider();
  const [cart, setCart] = useState(0);

  const carts = useReactiveVar(cartCount).quantity;
  const currentCart = useReactiveVar(currentCartVar);

  const [
    getCart,
    {
      loading,
      // $FlowFixMe
      data: { cart: cartData },
    },
  ] = useCart();

  useEffect(() => {
    const patientId = getPatientId('1')?.patientId;
    if (carts === 0 && patientId !== undefined && !loading) {
      // $FlowFixMe
      getCart({
        variables: {
          locationId: +locationId,
          businessId: +businessId,
          patientId,
        },
        context: {
          headers: {
            'Strain-Business-Id': businessId,
          },
        },
      });
    } else {
      setCart(carts);
    }
  }, [carts, setCart, getCart, loading, locationId, businessId]);

  useEffect(() => {
    if (cartData.items) {
      updateCartCount({
        quantity: cartData.items.length,
      });
    }
  }, [cartData]);

  const cartIsActive =
    currentCart.businessId != null && currentCart.locationId != null;

  return (
    <div className="header-top">
      <div className="container-fluid">
        <Row>
          <Col className="col-md-3 col-sm-4 col-4">
            <div className="top-social">
              <List className="social-list ps-0">
                <ListInlineItem className="mt-0">
                  <a
                    href="https://thestrain.io/fbstrainmarket"
                    rel="noreferrer"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <i className="ti-facebook" />
                  </a>
                </ListInlineItem>
                <ListInlineItem>
                  <a
                    href="https://thestrain.io/instrainmarket"
                    rel="noreferrer"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <i className="ti-instagram" />
                  </a>
                </ListInlineItem>
              </List>
            </div>
          </Col>
          <Col className="col-md-5 col-sm-12 col-12 content">
            <div className="top-content text-center mt-1 d-none">
              <FormattedMessage id="header.top-bar.content" /> 🍫 UMA{' '}
              <Link to="/">
                <FormattedMessage id="header.top-bar.offer" />
              </Link>
            </div>
          </Col>
          <Col className="col-md-4 col-sm-8 col-8 content-flags">
            <div className="top-right">
              <List className="d-flex mb-0">
                {cartIsActive && (
                  <ListInlineItem>
                    <Link
                      to={`/order-online/${currentCart.businessId}/stores/${currentCart.locationId}`}
                      className="cart"
                    >
                      <div className="cart-count">
                        {cart > 0 && (
                          <span className="count d-center-center">{cart}</span>
                        )}
                        <i className="ti-shopping-cart" />
                      </div>
                    </Link>
                  </ListInlineItem>
                )}
                <ListInlineItem>
                  <div className="flag">
                    <Button color="link" onClick={() => setLang?.('en')}>
                      <img
                        alt="flag US"
                        src="/assets/images/flag-us.png"
                        width="28"
                      />
                    </Button>
                    <span className="mx-2">/</span>
                    <Button color="link" onClick={() => setLang?.('es')}>
                      <img
                        alt="flag PR"
                        src="/assets/images/flag-pr.png"
                        width="28"
                      />
                    </Button>
                  </div>
                </ListInlineItem>
              </List>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HeaderTop;
