// @flow strict
import moment from 'moment';
import 'moment/locale/es';

import { useMemo } from 'react';

import type { Node } from 'react';

import FormSelect from './form/FormSelect';

import { useAppContextProvider } from '../app/context';

type Props = {|
  name: string,
  placeholder: string,
  control: () => void,
|};

const PickupTime = function ({ control, placeholder, name }: Props): Node {
  const [lang] = useAppContextProvider();

  const [startHour] = [9];
  const [endHour] = [19];
  const minuteStep = 30;

  const options = useMemo(() => {
    const all = [];

    const hourRanges = Array.from(
      { length: endHour - startHour },
      (_, i) => startHour + i,
    );
    const minuteRanges = Array.from(
      { length: 59 / minuteStep + 1 },
      (_, i) => i * minuteStep,
    );

    const [nowHour, nowMinute] = [moment().hour(), moment().minute()];

    // TODAY
    const todayHourRanges = hourRanges.filter((h) => h >= nowHour);
    const nowMinuteRanges = minuteRanges.filter((m) => m >= nowMinute);

    todayHourRanges.forEach((h) => {
      const ranges = h === nowHour ? nowMinuteRanges : minuteRanges;
      ranges.forEach((m) => {
        all.push({
          startAt: moment({ hour: h, minute: m }),
          endAt: moment({ hour: h, minute: m }).add(1, 'hours'),
        });
      });
    });

    // TOMORROW
    hourRanges.forEach((h) => {
      minuteRanges.forEach((m) => {
        all.push({
          startAt: moment({ hour: h, minute: m }).add(1, 'days'),
          endAt: moment({ hour: h, minute: m }).add(1, 'days').add(1, 'hours'),
        });
      });
    });

    // AFTER TOMORROW
    hourRanges.forEach((h) => {
      minuteRanges.forEach((m) => {
        all.push({
          startAt: moment({ hour: h, minute: m }).add(2, 'days'),
          endAt: moment({ hour: h, minute: m }).add(2, 'days').add(1, 'hours'),
        });
      });
    });

    return all.map((item) => ({
      value: `${item.startAt.format()},${item.endAt.format()}`,

      label: `${
        moment().date() === item.startAt.date()
          ? `${lang === 'en' ? 'Today' : 'Hoy'}`
          : item.startAt.format('dddd')
      } : ${item.startAt.format('hh:mmA')} - ${item.endAt.format('hh:mmA')}`,
    }));
  }, [startHour, endHour, lang]);
  return (
    <FormSelect
      name={name}
      placeholder={placeholder}
      control={control}
      // $FlowFixMe
      options={options}
    />
  );
};

export default PickupTime;
