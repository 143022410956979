// @flow strict

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Slider from 'react-slick';
import cx from 'classnames';

import type { Node } from 'react';

import SlickArrow from '../molecules/SlickArrow';

type Props = {|
  data: Array<{|
    children: Node,
    key?: ?string,
    props?: { to: string },
  |}>,
  currentActiveTab?: ?string,
  setCurrentActiveTab: (e: string) => void,
|};

const settings = {
  focusOnSelect: true,
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  centerPadding: true,
  autoplay: false,
  prevArrow: <SlickArrow arrow="left" size="sm" />,
  nextArrow: <SlickArrow arrow="right" size="sm" />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesToShow: 2.2,
      },
    },
  ],
};

function CategoryCarouselFilter({
  data = [],
  currentActiveTab,
  setCurrentActiveTab,
}: Props): Node {
  const handleClick = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    setCurrentActiveTab(e.currentTarget.dataset.key);
  };

  return (
    <Slider {...settings}>
      {data.map((item) => {
        return (
          <div
            role="button"
            className={cx({ active: item.key === currentActiveTab })}
            key={item.key}
            data-key={item.key}
            onClick={handleClick}
          >
            {item.children}
          </div>
        );
      })}
    </Slider>
  );
}

export default CategoryCarouselFilter;
