// @flow strict

import type { Node } from 'react';

import { Row, Card, CardBody, Col } from 'reactstrap';
import Shimmer from './Shimmer';

const PRODUCTS_LIMIT = 4;
const elements = Array(PRODUCTS_LIMIT).fill(null);

const ProductListLoading = function (): Node {
  return (
    <Row className="cards-loading-container">
      {elements.map((element, index) => (
        <Col
          lg={3}
          md={4}
          sm={6}
          className="mb-3 card-loading-container"
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <Card>
            <div className="position-relative">
              <Shimmer>
                <div className="card-loading-image" />
              </Shimmer>
            </div>
            <CardBody className="d-flex flex-column justify-content-between">
              <div>
                <Shimmer>
                  <div className="card-loading-price" />
                </Shimmer>
              </div>

              <div>
                <Shimmer>
                  <div className="card-loading-price" />
                </Shimmer>
              </div>
            </CardBody>
            <Shimmer>
              <div className="card-loading-button" />
            </Shimmer>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ProductListLoading;
