// @flow strict

import gql from 'graphql-tag';

const REQUEST_POS_REGISTRATION: $FlowFixMe = gql`
  mutation RequestPOSRegistration(
    $businessId: Int!
    $locationId: Int!
    $fullName: String!
    $phoneNumber: String!
    $license: String
    $birthdate: Date
  ) {
    requestPOSRegistration(
      locationId: $locationId
      businessId: $businessId
      fullName: $fullName
      phoneNumber: $phoneNumber
      license: $license
      birthdate: $birthdate
    ) {
      code
      success
      message
      errorMessages {
        field
        value
        description
      }
    }
  }
`;

export default {
  REQUEST_POS_REGISTRATION,
};
