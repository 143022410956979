// @flow strict
import type { Node } from 'react';
import Helmet from 'react-helmet';

function HomeMetaTags(): Node {
  return (
    <Helmet>
      <title>
        La red más grande de dispensarios de cannabis en Puerto Rico
      </title>
      <meta
        name="title"
        content="La red más grande de dispensarios de cannabis en Puerto Rico"
      />
      <meta
        name="description"
        content="En STRAIN Marketplace encontrarás tus productos y marcas favoritas de cannabis, descuentos y ofertas en los dispensarios cerca de ti."
      />
      <meta name="robots" content="index, follow" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://thestrainmarketplace.com/" />
      <meta
        property="og:title"
        content="La red más grande de dispensarios de cannabis en Puerto Rico"
      />
      <meta
        property="og:description"
        content="En STRAIN Marketplace encontrarás tus productos y marcas favoritas de cannabis, descuentos y ofertas en los dispensarios cerca de ti."
      />
      <meta
        property="og:image"
        content="https://thestrainmarketplace.com/assets/images/strain-marketplace.png"
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://thestrainmarketplace.com/"
      />
      <meta
        property="twitter:title"
        content="La red más grande de dispensarios de cannabis en Puerto Rico"
      />
      <meta
        property="twitter:description"
        content="En STRAIN Marketplace encontrarás tus productos y marcas favoritas de cannabis, descuentos y ofertas en los dispensarios cerca de ti."
      />
      <meta property="twitter:image" content="" />
    </Helmet>
  );
}

export default HomeMetaTags;
