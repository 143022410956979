// @flow strict
import type { Node } from 'react';
import { useState } from 'react';

const imageTemplate = '/assets/images/default-placeholder.png';

type Props = {|
  src: string,
  alt: string,
  className?: string,
|};

function Image({ src, alt, className = '' }: Props): Node {
  const [imageData, setImageData] = useState({
    error: false,
    src: src ?? imageTemplate,
  });

  const onErrorImage = () => {
    if (!imageData.error) {
      setImageData({
        src: imageTemplate,
        error: true,
      });
    }
  };
  return (
    <img
      src={imageData.src}
      onError={onErrorImage}
      alt={alt}
      className={className}
    />
  );
}

export default Image;
