// @flow strict

import type { Node } from 'react';

import { Link } from 'react-router-dom';

type Props = {|
  email: string,
  children: Node,
  className?: string,
|};

function ButtonMailTo({ email, children, className }: Props): Node {
  return (
    <Link
      className={className}
      to={`mailto:${email}`}
      // $FlowFixMe
      onClick={(e) => {
        window.location.href = `mailto:${email}`;
        e.preventDefault();
      }}
    >
      {children}
    </Link>
  );
}

export default ButtonMailTo;
