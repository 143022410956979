// @flow strict

import type { Node } from 'react';

type Props = {|
  element?: string,
|};

const NotFound = function ({ element = 'page' }: Props): Node {
  return (
    <div className="text-center">
      <div className="m-5" />
      <div className="text-monospace h1 text-capitalize">
        {element} not found
      </div>
      <div className="h5 my-4">
        The {element} you are looking for does not exist.
      </div>
    </div>
  );
};

export default NotFound;
