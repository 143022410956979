// @flow strict

import type { Node } from 'react';

import { Row, Card, CardBody, Col } from 'reactstrap';
import Shimmer from './Shimmer';

const ProductListLoading = function (): Node {
  return (
    <Row className="mb-4 mt-3 justify-content-center">
      <Col md={11}>
        <Card className="card-loading-container detail-view">
          <Row>
            <Col md="6" className="text-center">
              <Shimmer>
                <div className="card-loading-image" />
              </Shimmer>
            </Col>
            <Col md={6}>
              <CardBody>
                <Shimmer>
                  <div className="card-loading-title" />
                  <div className="card-loading-price" />
                  <div className="card-loading-button" />
                </Shimmer>
              </CardBody>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ProductListLoading;
