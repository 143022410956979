// @flow strict
import type { Node } from 'react';
import type { ReactNodeList } from 'react-dom_shared-types';

type Props = {|
  className: string,
  icon?: string,
  onClick: () => void,
  children?: ReactNodeList,
|};

function ButtonIcon({ className, icon = '', onClick, children }: Props): Node {
  return (
    <button type="button" className={`btn-icon ${className}`} onClick={onClick}>
      {icon !== '' && <i className={icon} />}
      {children !== undefined && children}
    </button>
  );
}

export default ButtonIcon;
