// @flow strict

type GeoPoint = {
  lat: number,
  lng: number,
};

export function getDistance(
  start?: GeoPoint,
  end?: GeoPoint,
  unit?: string = 'M', // Default miles
): number {
  if (start == null || end == null) {
    return 0;
  }

  const radlat1 = (Math.PI * start.lat) / 180;
  const radlat2 = (Math.PI * end.lat) / 180;
  const theta = start.lng - end.lng;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === 'K') {
    dist *= 1.609344;
  } else if (unit === 'N') {
    dist *= 0.8684;
  }

  return dist;
}

export default getDistance;
