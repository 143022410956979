// @flow strict

import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {|
  elements: string,
|};

const EmptyList = function ({ elements }: Props): Node {
  return (
    <div className="text-center pt-3">
      <div className="p-15">
        <div className="m-3" />
        <div className="h3">
          <FormattedMessage id={`common.no.${elements}.found`} />
          ...
        </div>
      </div>
    </div>
  );
};

export default EmptyList;
