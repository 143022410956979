// @flow strict
import { FormattedMessage } from 'react-intl';
import { Button, Col, Container, Form, FormGroup, Row } from 'reactstrap';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSearchParams, useNavigate } from 'react-router-dom';

import type { Node } from 'react';

import TabFilter from '../cells/TabFilter';
import FormSelect from '../molecules/form/FormSelect';
import Input from '../molecules/form/Input';
import municipalities from '../data/municipalities';

// $FlowFixMe
const tabs = [
  // {
  //   children: (
  //     <Button className="main-btn">
  //       <img src="/assets/images/icons/store.png" alt="store" />
  //       <FormattedMessage id="home.area.dispensaries" />
  //     </Button>
  //   ),
  //   key: '1',
  // },
  // {
  //   children: (
  //     <Button className="main-btn">
  //       <img src="/assets/images/icons/brand.png" alt="brand" />
  //       <FormattedMessage id="home.area.brands" />
  //     </Button>
  //   ),
  //   key: '2',
  // },
  /* {
    children: (
      <Button className="main-btn">
        <img src="/assets/images/icons/doctor.png" alt="icon doctor" />
        <FormattedMessage id="home.area.doctors" />
      </Button>
    ),
    key: '3',
  }, */
];

function HomeArea(): Node {
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const { register, control, handleSubmit } = useForm();
  const navigate = useNavigate();

  const optMunicipalities = municipalities.map(({ municipality }) => ({
    label: municipality,
    value: municipality,
  }));

  const onSubmit = (variables: { search: ?string, city: ?string }) => {
    const searchParams = createSearchParams();

    if (variables.search !== '' && variables.search != null) {
      searchParams.set('search', variables.search);
    }

    if (variables.city != null) {
      searchParams.set('city', variables.city);
    }

    navigate(`/dispensaries?${searchParams.toString()}`);
  };

  return (
    <section className="home-area bg_cover">
      <div className="home-wrapper-three-color" />
      <div className="home-wrapper-three">
        <Container>
          <Row>
            <Col className="col-lg-12">
              <div className="home-content text-center">
                <h1 className="title title-home">
                  <FormattedMessage id="home.area.title" />
                </h1>
                <h3>
                  <FormattedMessage id="home.area.description" />
                </h3>
                <div className="home-search-wrapper">
                  <Row className="align-items-center">
                    <Col className="col-xxl-6 col-lg-12 col-md-12 col-12">
                      <TabFilter
                        // $FlowFixMe
                        data={tabs}
                        currentActiveTab={currentActiveTab}
                        setCurrentActiveTab={setCurrentActiveTab}
                        className="search-nav"
                      />
                    </Col>
                    {false && (
                      <Col className="col-xxl-6 col-lg-12">
                        <p className="tags">
                          <span>
                            <FormattedMessage id="home.area.popular" />:
                          </span>
                          <FormattedMessage id="home.area.popular-data" />
                        </p>
                      </Col>
                    )}
                  </Row>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="home-search-form">
                      <Row>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <FormGroup className="form-group">
                            <FormattedMessage id="home.area.search-filter">
                              {(msg) => (
                                <Input
                                  className="form_control"
                                  placeholder={msg}
                                  {...register('search')}
                                />
                              )}
                            </FormattedMessage>
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <FormGroup className="form_group text-start">
                            {/* <i className="ti-location-pin" /> */}
                            <FormattedMessage id="home.area.filter-opt">
                              {(msg) => (
                                <FormSelect
                                  className="dropdown-primary"
                                  placeholder={msg}
                                  name="city"
                                  control={control}
                                  options={[
                                    { label: 'Near me', value: 'near' },
                                    ...optMunicipalities,
                                  ]}
                                />
                              )}
                            </FormattedMessage>
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-4 col-md-12 col-12">
                          <FormGroup className="form-group">
                            <Button className="main-btn icon-btn">
                              <FormattedMessage id="home.area.search-btn" />
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default HomeArea;
