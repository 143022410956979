// @flow strict
import { Nav } from 'reactstrap';

import type { Node } from 'react';

import TabNav from '../molecules/TabNav';

type Props = {|
  data: Array<{|
    children: Node,
    key: string,
    props?: { to: string },
    value?: string,
  |}>,
  currentActiveTab: string,
  className?: string,
  setCurrentActiveTab: (e: string) => void,
|};

function TabFilter({
  data,
  currentActiveTab,
  className = '',
  setCurrentActiveTab,
}: Props): Node {
  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const items = data.map((item) => (
    <TabNav
      toggle={toggle}
      key={item.key}
      index={item.key}
      currentActiveTab={currentActiveTab}
      props={item.props}
    >
      {item.children}
    </TabNav>
  ));

  return (
    <Nav tabs className={className}>
      {items}
    </Nav>
  );
}

export default TabFilter;
