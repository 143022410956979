// @flow strict

import type { Node } from 'react';

const Forbidden = function (): Node {
  return (
    <div className="text-center">
      <div className="m-5" />
      <div className="h2 text-capitalize">Forbidden</div>
      <div className="h5 my-4">
        Your user has not being assigned any permission yet.
      </div>
    </div>
  );
};

export default Forbidden;
