// @flow strict

import { FormattedMessage, useIntl } from 'react-intl';
import {
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// $FlowFixMe
import { yupResolver } from '@hookform/resolvers/yup';
// $FlowFixMe
import * as yup from 'yup';

import type { Node } from 'react';

import Input from '../molecules/form/Input';
import RequestButton from '../cells/RequestButton';
import GRAPHQL from '../graphql';

import { useNotifyModalOpened } from '../hooks';

type Props = {|
  businessId: number,
  locationId: ?number,
  isOpen: boolean,
  toggle?: () => void,
  onSuccessRegistration?: (patientId: string) => void,
|};

const schema = yup.object({
  fullName: yup.string().required('validation.required-field'),
  license: yup.string().required('validation.required-field'),
  phoneNumber: yup.string().required('validation.required-field'),
});

const POSPatientRegisterModal = function ({
  locationId,
  businessId,
  isOpen,
  toggle,
  onSuccessRegistration,
}: Props): Node {
  const { messages } = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [requestPOSRegistration, { data, loading }] = useMutation(
    GRAPHQL.MUTATION.USER.REQUEST_POS_REGISTRATION,
    {
      context: {
        headers: { 'Strain-Business-Id': businessId },
      },
      onCompleted: ({
        requestPOSRegistration: requestPOSRegistrationResponse,
      }) => {
        if (requestPOSRegistrationResponse.success) {
          onSuccessRegistration?.(requestPOSRegistrationResponse.message);
        }
      },
    },
  );

  const onSubmit = useCallback(
    (value: $FlowFixMe) => {
      // $FlowFixMe
      requestPOSRegistration({
        variables: {
          locationId,
          businessId,
          ...value,
        },
        context: {
          headers: { 'Strain-Business-Id': businessId },
        },
      });
    },
    [locationId, businessId, requestPOSRegistration],
  );

  const notifyOpened = useNotifyModalOpened([]);

  useEffect(() => {
    data?.requestPOSRegistration?.errorMessages?.forEach((item) => {
      if (item.field && item.description) {
        setError(item.field, { type: 'custom', message: item.description });
      }
    });
  }, [data, setError]);

  return (
    <Modal
      centered
      innerRef={notifyOpened}
      isOpen={isOpen}
      backdrop
      toggle={toggle}
      className="ask-patient-id"
    >
      <ModalHeader toggle={toggle}>
        <img
          className="img-fluid"
          src="/assets/images/guest-checkout.png"
          alt="Strain"
          width="40"
          style={{ marginRight: '5px', verticalAlign: 'bottom' }}
        />
        <FormattedMessage id="order-online.guest-checkout" />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>
              <FormattedMessage id="user.name" />
              <span className="text-danger">*</span>
            </Label>
            <Input
              disabled={loading}
              name="fullName"
              type="text"
              {...register('fullName')}
            />
            {errors.fullName && (
              <FormFeedback valid={false} className="d-block">
                <FormattedMessage id={errors.fullName.message} />
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <FormattedMessage id="user.license" />
              <span className="text-danger">*</span>
            </Label>
            <Input
              disabled={loading}
              name="license"
              type="text"
              placeholder={messages['user.placeholder.license']}
              {...register('license')}
            />
            {errors.license && (
              <FormFeedback valid={false} className="d-block">
                <FormattedMessage id={errors.license.message} />
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <FormattedMessage id="user.phoneNumber" />
              <span className="text-danger">*</span>
            </Label>
            <Input
              disabled={loading}
              name="phoneNumber"
              type="text"
              placeholder={messages['user.placeholder.phoneNumber']}
              {...register('phoneNumber')}
            />
            {errors.phoneNumber && (
              <FormFeedback valid={false} className="d-block">
                <FormattedMessage id={errors.phoneNumber.message} />
              </FormFeedback>
            )}
          </FormGroup>
          <p className="text-danger">
            <em>
              * <FormattedMessage id="validation.required-field" />
            </em>
          </p>
          <RequestButton
            loading={loading}
            textId="common.continue"
            color="success"
            className="mt-3"
            block
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default POSPatientRegisterModal;
