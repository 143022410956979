// @flow strict
import { makeVar } from '@apollo/client';

import { onCartCount } from '../helpers';

import type { Cart } from '../types';

const cartCountData = {
  quantity: onCartCount(),
};

const coordinatesData = {
  lat: undefined,
  lng: undefined,
  allowed: undefined,
};

const cartData = {
  cart: null,
};

const addProductInfomation = {
  isOpen: false,
  continueUrl: null,
  cartUrl: null,
};

const currentCartData = {
  businessId: null,
  locationId: null,
};

export const coordinates: $FlowFixMe = makeVar(coordinatesData);
export const cartCount: $FlowFixMe = makeVar(cartCountData);
export const cartVar: $FlowFixMe = makeVar(cartData);
export const currentCartVar: $FlowFixMe = makeVar(currentCartData);
export const addProductInformationVar: $FlowFixMe =
  makeVar(addProductInfomation);

export const updateCart = (cart: Cart) => {
  cartVar(cart);
};

navigator.geolocation.getCurrentPosition(
  ({ coords }) => {
    coordinates({
      lat: coords.latitude,
      lng: coords.longitude,
      allowed: true,
    });
  },
  () => {
    coordinates({
      lat: undefined,
      lng: undefined,
      allowed: false,
    });
  },
);

export const resetLocation = () => {
  navigator.geolocation.getCurrentPosition(({ coords }) => {
    coordinates({
      lat: coords.latitude,
      lng: coords.longitude,
      allowed: true,
    });
  });
};

export const updateCartCount = (params: { quantity?: ?number }) => {
  const old = cartCount();
  cartCount({
    ...old,
    ...params,
  });
};

export const resetVariables = () => {};
