// @flow strict
import type { Node } from 'react';
import Helmet from 'react-helmet';

function DispensariesMetaTags(): Node {
  return (
    <Helmet>
      <title>
        Encuentra los dispensarios de cannabis más cerca de ti en Puerto Rico
      </title>
      <meta
        name="title"
        content="Encuentra los dispensarios de cannabis más cerca de ti en Puerto Rico"
      />
      <meta
        name="description"
        content="En STRAIN Marketplace encontrarás más dispensarios de cannabis cerca de ti en Puerto Rico que en weedmaps."
      />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}

export default DispensariesMetaTags;
