// @flow strict
export const GOOGLE_MAPS_API_KEY = 'AIzaSyAeKc17KKRRsveHuuvoFfFBwEP-4j8xROk';

export const DAYS_ORDERED = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const FILTER_PRODUCTS = [
  { name: 'Bongs' },
  { name: 'Apparel' },
  { name: 'Clones' },
  { name: 'Craft' },
  { name: 'Dabbing' },
  { name: 'Edible Accessories' },
  { name: 'Specialty' },
  { name: 'Infused Prerolls' },
  { name: 'Pipas' },
  { name: 'Pre-rolls' },
  { name: 'Seeds' },
  { name: 'Specials' },
  { name: 'Solventless' },
];

export const OPTIONS_CATEGORIES = [
  {
    name: 'category.all',
    icon: '/assets/images/icons/all.svg',
    key: null,
  },
  {
    name: 'category.flower',
    icon: '/assets/images/icons/flowers.png',
    key: '9',
  },
  {
    name: 'category.cartridges',
    icon: '/assets/images/icons/cartridge.png',
    key: '19',
  },
  {
    name: 'category.edibles',
    icon: '/assets/images/icons/edibles.png',
    key: '3',
  },
  {
    name: 'category.drinks',
    icon: '/assets/images/icons/drinks.svg',
    key: '4',
  },
  {
    name: 'category.concentrates',
    icon: '/assets/images/icons/concentrados.png',
    key: '7',
  },
  {
    name: 'category.tinctures',
    icon: '/assets/images/icons/tincture.png',
    key: '11',
  },
  {
    name: 'category.accessories',
    icon: '/assets/images/icons/accesorios.png',
    key: '2',
  },
  {
    name: 'category.shake',
    icon: '/assets/images/icons/shake.png',
    key: '17',
  },
  {
    name: 'category.topicals',
    icon: '/assets/images/icons/topicals.png',
    key: '14',
  },
  {
    name: 'category.capsules',
    icon: '/assets/images/icons/capsules.png',
    key: '5',
  },
  {
    name: 'category.syringes',
    icon: '/assets/images/icons/sirynge.png',
    key: '22',
  },
];

export const WEIGHT_TYPE = {
  GR: 'product.weight-gr',
  EA: 'product.weight-each',
};

export const ORDER_TYPES = {
  DELIVERY: 'DELIVERY',
  PICK_UP: 'PICK_UP',
};

export const SORT_TYPE = {
  featured: 'DESC',
  name: 'ASC',
  createdAt: 'DESC',
  purchasedcount: 'DESC',
};

export const CURRENCY = 'USD';

export const CATEGORIES = {
  ACCESSORIES: 2,
  FLOWERS: 9,
  CARTDRIGES: 19,
  EDIBLES: 3,
  DRINKS: 4,
  CONCENTRATES: 7,
  TOPICALS: 14,
  TINCTURES: 11,
};

export const BRANDS = {
  UMA: 2057,
  PURPLE_DOOR: 1592,
  BORICANA: 218,
  PR_ALCHEMY: 1550,
  JOY_CANNABIS: 1276,
  HEALTHWEED: 672,
};
