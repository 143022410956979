// @flow strict
import { useController } from 'react-hook-form';

import type { Node } from 'react';

import type { Option } from './Select';
import Select from './Select';

type Props = {|
  className?: string,
  control: () => void,
  name: string,
  placeholder: string,
  options: Option[],
  isMulti?: boolean,
  size?: string,
|};

const FormSelect = function ({
  className,
  control,
  name,
  options,
  placeholder,
  isMulti,
  size,
}: Props): Node {
  const {
    field: { onChange, onBlur, value: cValue },
  } = useController({
    name,
    control,
    defaultValue: isMulti === true ? [] : null,
  });

  const value: Option[] =
    isMulti === true
      ? options.filter((o) => cValue?.indexOf(o.value) >= 0)
      : options.filter((o) => cValue === o.value);

  const handleChange = (option: ?Option | ?(Option[])) => {
    if (option instanceof Array) {
      onChange(option.map((x) => x.value));
    } else {
      onChange(option?.value);
    }
  };

  return (
    <Select
      className={className}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      options={options}
      isMulti={isMulti}
      placeholder={placeholder}
      size={size}
    />
  );
};

export default FormSelect;
