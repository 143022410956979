// @flow strict

import { useLazyQuery, useQuery } from '@apollo/client';

import type { QueryTuple } from '@apollo/client';
import GRAPHQL from '../graphql';

import type { ListResponse, Dispensary, DispensarySortOption } from '../types';

const EMPTY_LIST_PAGINATED_RESPONSE = {
  objects: [],
  total: 0,
};

export const useDispensary = (
  options?: ?{},
  // $FlowFixMe
): QueryResult<
  Dispensary,
  {|
    posLocationId: number,
  |},
> => {
  // $FlowFixMe
  const { data, ...rest } = useQuery(GRAPHQL.DISPENSARY.DETAIL, { ...options });
  // $FlowFixMe
  return {
    ...rest,
    // $FlowFixMe
    data: data?.store ?? {},
  };
};

export const useDispensaries = (
  options?: ?{},
): QueryTuple<
  ListResponse<Dispensary>,
  {|
    sorting: ?DispensarySortOption,
    filter?: {
      isFeatured?: ?boolean,
      search?: ?string,
      isOpenAt?: ?Date,
    },
    pagination: {
      limit: ?number,
      offset: ?number,
    },
  |},
> => {
  // $FlowFixMe
  const [getDispensaries, { data, ...rest }] = useLazyQuery(
    GRAPHQL.DISPENSARY.LIST,
    {
      ...options,
      fetchPolicy: 'network-only',
    },
  );
  // $FlowFixMe
  return [
    // $FlowFixMe
    getDispensaries,
    // $FlowFixMe
    {
      ...rest,
      data:
        data?.stores != null
          ? {
              objects: data.stores.objects,
              total: data.stores.total,
            }
          : // $FlowFixMe
            EMPTY_LIST_PAGINATED_RESPONSE,
    },
  ];
};
