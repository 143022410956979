// @flow strict
import toast from 'react-hot-toast';

import { useLayoutEffect } from 'react';

import type { Node } from 'react';

import type { ApolloError } from '@apollo/client';
import Forbidden from '../cells/Forbidden';

type Props = {|
  error: ApolloError,
  mutation?: boolean,
|};

const GQLError = function ({ error, mutation = false }: Props): Node {
  const { graphQLErrors } = error;

  const forbidden = graphQLErrors.some(
    // $FlowFixMe
    (x) => x.extensions.code === 'FORBIDDEN',
  );

  const hasServerError = graphQLErrors.some(
    // $FlowFixMe
    (x) => x.extensions.code === 'INTERNAL_SERVER_ERROR',
  );

  useLayoutEffect(() => {
    if (hasServerError) {
      toast.error('Our Dev team has been notified about this error', {
        title: 'Server Error',
      });
    }

    if (forbidden && mutation) {
      toast.error('Your user has not being granted permissions this area.', {
        title: 'Forbidden',
      });
    }
  }, [hasServerError, forbidden, mutation]);

  if (forbidden && !mutation) {
    return <Forbidden />;
  }

  return null;
};

export default GQLError;
