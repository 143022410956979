// @flow strict
import gql from 'graphql-tag';

const LIST: $FlowFixMe = gql`
  query ProductCategories($limit: Int = 100) {
    business {
      id
      productCategories(limit: $limit) {
        total
        objects {
          id
          name
        }
      }
    }
  }
`;

export default {
  LIST,
};
