// @flow strict
import enMessages from '../locales/en_US';

/* eslint-disable global-require */
if (Intl.PluralRules == null) {
  // $FlowFixMe
  require('@formatjs/intl-pluralrules/polyfill');
  // $FlowFixMe
  require('@formatjs/intl-pluralrules/locale-data/en');
}

// $FlowFixMe
if (Intl.RelativeTimeFormat == null) {
  // $FlowFixMe
  require('@formatjs/intl-relativetimeformat/polyfill');
  // $FlowFixMe
  require('@formatjs/intl-relativetimeformat/locale-data/en');
}
/* eslint-enable global-require */

export default enMessages;
