// @flow strict
import gql from 'graphql-tag';

const DETAIL: typeof gql = gql`
  query ($itemNumber: String!, $locationId: Int!) {
    business {
      id
      product(
        filter: {
          locationId: $locationId
          priceType: MED
          itemNumber: $itemNumber
        }
      ) {
        id
        itemNumber
        name
        image
        strain
        type
        percentageTHC
        thcUnitMeasurement
        percentageCBD
        cbdUnitMeasurement
        brand {
          id
          name
        }
        category {
          id
          name
        }
        store {
          id
          name
          showTaxes
          business {
            id
          }
        }
        business {
          id
          name
          logo
        }
        description
        variants {
          id
          name
          quantity
          uom
          weight
          price {
            price
            taxes
            discount
          }
        }
      }
    }
  }
`;
const RELATED: typeof gql = gql`
  query ($itemNumber: String!, $locationId: Int!) {
    business {
      id
      logo
      product(
        filter: {
          locationId: $locationId
          priceType: MED
          itemNumber: $itemNumber
        }
      ) {
        id
        store {
          id
          business {
            id
          }
        }
        business {
          id
        }
        relatedProducts {
          id
          itemNumber
          name
          type
          brand {
            id
          }
          store {
            id
            name
            showTaxes
            image
            business {
              id
            }
          }
          business {
            id
            name
            logo
          }
          image
          description
          percentageTHC
          thcUnitMeasurement
          percentageCBD
          cbdUnitMeasurement
          category {
            id
            name
          }
          variants {
            id
            name
            price {
              price
              taxes
              discount
            }
          }
        }
      }
    }
  }
`;

const LIST: typeof gql = gql`
  query (
    $filter: ProductFilter
    $pagination: PaginationInput
    $sort: ProductSort
  ) {
    business {
      id
      logo
      products(filter: $filter, pagination: $pagination, sort: $sort) {
        total
        objects {
          id
          itemNumber
          name
          image
          brand {
            id
            name
          }
          category {
            id
            name
          }
          discount {
            id
            name
            label
          }
          store {
            id
            name
            showTaxes
            image
            business {
              id
            }
          }
          business {
            id
            name
            logo
          }
          sale
          type
          thcUnitMeasurement
          percentageTHC
          cbdUnitMeasurement
          percentageCBD
          variants {
            id
            name
            quantity
            uom
            weight
            price {
              price
              taxes
              discount
              taxesExclDiscount
            }
          }
        }
      }
    }
  }
`;

const GLOBAL_LIST: typeof gql = gql`
  query GlobalProductList(
    $filter: GlobalProductFilter
    $pagination: PaginationInput
    $sortBy: GlobalProductSortInput
  ) {
    products(filter: $filter, pagination: $pagination, sortBy: $sortBy) {
      total
      objects {
        id
        itemNumber
        name
        image
        brand {
          id
          name
        }
        category {
          id
          name
        }
        discount {
          id
          name
          label
        }
        store {
          id
          name
          showTaxes
          image
          business {
            id
          }
        }
        business {
          id
          name
          logo
        }
        sale
        type
        thcUnitMeasurement
        percentageTHC
        cbdUnitMeasurement
        percentageCBD
        variants {
          id
          name
          quantity
          uom
          weight
          price {
            price
            taxes
            discount
            taxesExclDiscount
          }
        }
      }
    }
  }
`;

export default { DETAIL, RELATED, LIST, GLOBAL_LIST };
