// @flow strict
import { useParams } from 'react-router-dom';

import type { Node } from 'react';

import QueryResult from '../organisms/QueryResult';
import NotFound from '../cells/NotFound';
import DispensaryDetailsArea from '../organisms/DispensaryDetailsArea';
import DispensaryDetailsSection from '../organisms/DispensaryDetailsSection';
import Footer from '../organisms/Footer';

import { useDispensary } from '../hooks/dispensary';
import { useProductCategories } from '../hooks/product-category';
import { useBrands } from '../hooks/brand';

function DispensaryDetails(): Node {
  const { businessId, storeId } = useParams();

  // $FlowFixMe
  const { data, loading, error } = useDispensary({
    variables: {
      storeId: +storeId,
    },
    context: {
      headers: {
        'Strain-Business-Id': +businessId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  // $FlowFixMe
  const { data: productCategoriesResponse } = useProductCategories({
    context: {
      headers: {
        'Strain-Business-Id': +businessId,
      },
    },
  });

  // $FlowFixMe
  const { data: brandsResponse } = useBrands({
    context: {
      headers: {
        'Strain-Business-Id': +businessId,
      },
    },
  });

  return (
    <QueryResult
      loading={loading}
      isEmpty={data == null}
      error={error}
      notFound={<NotFound element="dispensary" />}
    >
      <DispensaryDetailsArea data={data} />
      <DispensaryDetailsSection
        dispensary={data}
        productCategories={productCategoriesResponse.objects}
        brands={brandsResponse.objects}
      />
      <Footer />
    </QueryResult>
  );
}

export default DispensaryDetails;
