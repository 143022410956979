// @flow strict
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormGroup, ModalHeader, Modal, ModalBody, Button } from 'reactstrap';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import { addProductInformationVar } from '../graphql/reactive-variables';

const SuccessModal = function (): Node {
  const addProductInformation = useReactiveVar(addProductInformationVar);

  const handleToggle = () => {
    addProductInformationVar({
      ...addProductInformation,
      isOpen: false,
    });
  };

  return (
    <Modal
      centered
      isOpen={addProductInformation.isOpen}
      toggle={handleToggle}
      className="embed-success-modal"
    >
      <ModalHeader toggle={handleToggle} />
      <ModalBody>
        <div className="d-flex justify-content-center">
          <div>
            <div className="text-center">
              <img
                className="img-thumbnail image"
                src="/assets/images/added-to-cart.png"
                alt="Strain"
              />
            </div>
            <p className="text-center mt-3 mb-4">
              <FormattedMessage id="order-online.added-success-message" />
            </p>
            <FormGroup className="text-center">
              <Button
                className="text-uppercase"
                color="success"
                tag={Link}
                to={addProductInformation.continueUrl}
                onClick={handleToggle}
              >
                <FormattedMessage id="order-online.continue-shopping" />
              </Button>
            </FormGroup>
            <div className="text-center">
              <Button
                className="text-uppercase text-dark"
                color="link"
                tag={Link}
                to={addProductInformation.cartUrl}
                onClick={handleToggle}
              >
                <FormattedMessage id="order-online.view-cart" />
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
