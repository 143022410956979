// @flow strict

import * as Sentry from '@sentry/react';

import { setContext } from '@apollo/client/link/context';
// $FlowFixMe
import { InMemoryCache } from '@apollo/client/cache';
// $FlowFixMe
import { createHttpLink, ApolloClient, split } from '@apollo/client';
// $FlowFixMe
import { getMainDefinition } from '@apollo/client/utilities';

import WebSocketLink from '../websocket-link';

import { GRAPHQL_URI, GRAPHQL_URI_WS } from '../config';
import { resetVariables } from '../graphql/reactive-variables';

import type { CartItem } from '../types';

const typePolicies = {
  POSLocation: {
    keyFields: ['id', 'business', ['id']],
  },
  Product: {
    keyFields: ['id', 'store', ['id'], 'business', ['id']],
  },
  ProductVariant: {
    keyFields: false,
  },
  Cart: {
    fields: {
      items: {
        // eslint-disable-next-line default-param-last, no-unused-vars
        merge(existing: CartItem[] = [], incoming: CartItem[]) {
          return incoming;
        },
      },
    },
  },
};

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  url: GRAPHQL_URI_WS,
  lazy: true,
  // $FlowFixMe
  on: {
    // $FlowFixMe
    error: (e) => {
      Sentry.captureException(e);
    },
    // $FlowFixMe
    message: ({ payload }) => {
      const length = payload?.errors?.length ?? 0;
      if (length > 0) {
        Sentry.withScope((scope) => {
          scope.setTag('errors', JSON.stringify(payload.errors));
          scope.setLevel('warning');
          Sentry.captureException(new Error('Errors on Websocket GrapqhQL'));
        });
      }
    },
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Forwarded-Proto': 'https',
  },
}));

const clientCache = new InMemoryCache({ typePolicies });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

// $FlowFixMe
const client: ApolloClient = new ApolloClient({
  link: splitLink,
  cache: clientCache,
});

// $FlowFixMe
client.onResetStore(() => {
  resetVariables();
});

export default client;
