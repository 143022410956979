import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './app/App';
// import reportWebVitals from './reportWebVitals';

import { SENTRY_DSN, SENTRY_RELEASE, ENVIRONMENT } from './config';

import './assets/sass/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const tagManagerArgs = {
  gtmId: 'GTM-WK7X4NW',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: SENTRY_RELEASE,
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

if (window.Intl == null) {
  window.Intl = require('intl'); // eslint-disable-line global-require
}

window.onbeforeunload = () => {
  localStorage.removeItem('patient');
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
