// @flow strict
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import type { Node } from 'react';

import ProductFilters from '../molecules/ProductFilters';
import RequestButton from '../cells/RequestButton';
import SoldProductCard from '../cells/SoldProductCard';
import CategoryCarouselFilter from '../cells/CategoryCarouselFilter';
import EmptyList from './EmptyList';
import ListingFaq from './ListingFaq';
import QueryResult from './QueryResult';

import { useProducts } from '../hooks/product';
import { useStrainSearchParams } from '../hooks';
import { SORT_TYPE, OPTIONS_CATEGORIES } from '../constants';

import type { Dispensary, Brand, ProductCategory } from '../types';

const dataFaq = [
  {
    title: 'How do I Make a regular Table Booking?',
    description:
      'Musutrum orci montes hac at neque mollis taciti parturient vehicula interdum viverra cubilia ipsum ut duis amet nullam sit ut ornare mattis urna. Parturient. Aptent erat blandit dolor porta eros mollis hendrerit leo viverra pellentesque fusce.',
    key: '1',
  },
  {
    title: `How can I be certain my booking's been received?`,
    description:
      'Musutrum orci montes hac at neque mollis taciti parturient vehicula interdum viverra cubilia ipsum ut duis amet nullam sit ut ornare mattis urna. Parturient. Aptent erat blandit dolor porta eros mollis hendrerit leo viverra pellentesque fusce.',
    key: '2',
  },
  {
    title: 'How much do I have to pay for my booking?',
    description:
      'Musutrum orci montes hac at neque mollis taciti parturient vehicula interdum viverra cubilia ipsum ut duis amet nullam sit ut ornare mattis urna. Parturient. Aptent erat blandit dolor porta eros mollis hendrerit leo viverra pellentesque fusce.',
    key: '3',
  },
  {
    title: `What happens restaurant and they don't have my booking?`,
    description:
      'Musutrum orci montes hac at neque mollis taciti parturient vehicula interdum viverra cubilia ipsum ut duis amet nullam sit ut ornare mattis urna. Parturient. Aptent erat blandit dolor porta eros mollis hendrerit leo viverra pellentesque fusce.',
    key: '4',
  },
];

type Props = {|
  dispensary: Dispensary,
  productCategories: ProductCategory[],
  brands: Brand[],
|};

const PRODUCTS_LIMIT = 12;

function DispensaryDetailsSection({
  dispensary,
  productCategories,
  brands,
}: Props): Node {
  const { business } = dispensary;
  const { about } = business;
  const { businessId, storeId } = useParams();
  const [searchParams, updateSearchParams] = useStrainSearchParams();
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  // $FlowFixMe
  const [getProducts, { loading, data: products, error, fetchMore }] =
    useProducts();

  const productCategoryId = searchParams.get('category');
  const brandId = searchParams.get('brand');
  const search = searchParams.get('search');
  const sortBy = searchParams.get('sort') ?? 'featured';
  const type = searchParams.get('type');

  useEffect(() => {
    if (productCategoryId === null) {
      setCurrentActiveTab(null);
    } else {
      // $FlowFixMe
      setCurrentActiveTab(searchParams.get('category'));
    }

    // $FlowFixMe
    getProducts({
      // $FlowFixMe
      variables: {
        locationId: +storeId,
        filter: {
          search,
          type,
          locationId: +storeId,
          priceType: 'MED',
          brandId: brandId != null ? +brandId : undefined,
          categoryId:
            productCategoryId === null ? undefined : +currentActiveTab,
        },
        pagination: {
          limit: PRODUCTS_LIMIT,
        },
        sort: {
          orderBy: sortBy,
          sortType: SORT_TYPE[sortBy] ?? 'ASC',
        },
      },
      context: {
        headers: {
          'Strain-Business-Id': +businessId,
        },
      },
    });
  }, [
    getProducts,
    storeId,
    businessId,
    productCategoryId,
    brandId,
    search,
    type,
    sortBy,
    currentActiveTab,
    searchParams,
  ]);

  // $FlowFixMe
  const { objects: productsData } = products;

  // $FlowFixMe
  const handleChangeProductCategory = (id) => {
    setCurrentActiveTab(id);
    // $FlowFixMe
    updateSearchParams({ category: id });
  };

  const handleLoadMoreClick = () => {
    const pagination = {
      limit: PRODUCTS_LIMIT,
      // $FlowFixMe
      offset: products.objects.length,
    };

    // $FlowFixMe
    fetchMore({
      variables: {
        pagination,
      },
      // $FlowFixMe
      updateQuery: (prev, { fetchMoreResult }) => {
        const moreProducts = fetchMoreResult?.business?.products;
        if (moreProducts.objects.length === 0) {
          return prev;
        }

        return {
          ...prev,
          business: {
            // $FlowFixMe
            ...prev.business,
            products: {
              // $FlowFixMe
              ...prev.business.products,
              total: moreProducts.total,
              objects: [
                // $FlowFixMe
                ...prev.business.products.objects,
                ...moreProducts.objects,
              ],
            },
          },
        };
      },
    });
  };
  // $FlowFixMe
  const loadMore = products.total > products.objects.length;

  const optionsTab = OPTIONS_CATEGORIES.map((option) => {
    return {
      key: option.key,
      children: (
        <div className="carousel-filter-prods" key={option.key}>
          <img src={option.icon} alt="" />
          <FormattedMessage id={option.name} />
        </div>
      ),
    };
  });

  return (
    <section className="listing-details-section">
      <div className="listing-details-wrapper listing-details-wrapper-one">
        <Container fluid className="content">
          {false && (
            <div className="listing-content mb-5">
              <h3 className="title">
                <FormattedMessage id="dispensary.about" />
              </h3>
              <div dangerouslySetInnerHTML={{ __html: about }} />
            </div>
          )}
          <div className="carousel-filter">
            <CategoryCarouselFilter
              // $FlowFixMe
              data={optionsTab}
              currentActiveTab={currentActiveTab}
              setCurrentActiveTab={handleChangeProductCategory}
            />
          </div>
          <div className="listing-gallery-box">
            <ProductFilters
              className="mb-3"
              productCategories={productCategories}
              brands={brands}
              hideProductCategories
            />
            <QueryResult
              error={error}
              isEmpty={productsData.length <= 0}
              loading={loading}
              notFound={<EmptyList elements="products" />}
            >
              <Row>
                {productsData.map((product) => (
                  <Col
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"
                    key={product.id}
                  >
                    <SoldProductCard
                      product={product}
                      showTaxes={dispensary.showTaxes}
                      // $FlowFixMe
                      locationId={storeId}
                      // $FlowFixMe
                      businessId={businessId}
                    />
                  </Col>
                ))}
              </Row>
            </QueryResult>

            {loadMore && (
              <Row>
                <Col className="mb-3 mt-3">
                  <RequestButton
                    loading={loading}
                    textId="dispensary.products.load-more"
                    color="success"
                    className="mt-3"
                    block
                    onClick={handleLoadMoreClick}
                  />
                </Col>
              </Row>
            )}
          </div>
          <div className="listing-tag-box mt-5 d-none">
            <h4 className="title">
              <FormattedMessage id="dispensary.listing-tag.title" />
            </h4>
            <Link to="/">Delivery</Link>
            <Link to="/">Restaurant</Link>
            <Link to="/">Free Internet</Link>
            <Link to="/">Shopping</Link>
            <Link to="/">Car Parking</Link>
            <Link to="/">Food</Link>
            <Link to="/">Kitchen</Link>
            <Link to="/">Reservation</Link>
            <Link to="/">Travel</Link>
          </div>
          <div className="listing-faq-box mb-50 d-none">
            <h4 className="title">
              <FormattedMessage id="dispensary.listing-faq.title" />
            </h4>
            <Col lg={8} sm={12}>
              <ListingFaq items={dataFaq} />
            </Col>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default DispensaryDetailsSection;
