// @flow strict

import { useLazyQuery } from '@apollo/client';

import type { QueryTuple } from '@apollo/client';

import GRAPHQL from '../graphql';

export default function usePatientIdExists(options?: ?{}): QueryTuple<
  boolean,
  {|
    businessId: number,
    patientId: string,
  |},
> {
  // $FlowFixMe
  const [getPatientIdExists, { data, ...rest }] = useLazyQuery(
    GRAPHQL.PATIENT.ID_EXISTS,
    { ...options, fetchPolicy: 'network-only' },
  );
  // $FlowFixMe
  return [
    // $FlowFixMe
    getPatientIdExists,
    // $FlowFixMe
    { ...rest, data: data?.patientIDExists ? data.patientIDExists : false },
  ];
}
