// @flow strict
import moment from 'moment';
import 'moment/locale/es';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import type { Node } from 'react';

import { useAppContextProvider } from '../app/context';

type Props = {|
  title?: string,
  date?: string,
  image: string,
|};

moment.locale('en-gb');
function OfferCard({ title, date, image }: Props): Node {
  const [lang] = useAppContextProvider();

  useEffect(() => {
    if (lang === 'es') {
      moment.locale('en-gb');
    }
    if (lang === 'en') {
      moment.locale('es');
    }
  }, [lang]);

  return (
    <div className="place-item place-item-two">
      <div className="place-thumbnail">
        <img src={image} alt="place Image1" />
        <Link to="/" className="cat-btn">
          <img
            className="listing-icon"
            src="/assets/images/default-placeholder.png"
            alt="dispensary"
          />
        </Link>
        <div className="place-overlay">
          {title !== undefined && (
            <div className="place-content d-flex align-items-center">
              <div className="info">
                <span className="span">
                  <FormattedMessage id="home.offer.expiration" />
                  {moment(date).toNow()}
                </span>
                <h4>{title}</h4>
              </div>
              <span className="icon">Redimir</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OfferCard;
