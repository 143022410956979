// @flow strict
import { useSearchParams } from 'react-router-dom';

import {
  useLayoutEffect,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import type { ErrorMessage } from './types';

export const useOutsideClick = (
  callback: () => void,
): {| current: ?HTMLDivElement |} => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // $FlowFixMe
      if (ref.current != null && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);

  // $FlowFixMe
  return ref;
};

/**
 *
 */
export const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return debouncedValue;
};

/**
 *
 */
export const usePagination = (
  page?: number = 1,
): ({
  selectedPageSize: number,
  setSelectedPageSize: (pageSize: number) => void,
  currentPage: number,
  setCurrentPage: (page: number) => void,
  startIndex: number,
  endIndex: number,
}) => {
  const [selectedPageSize, setSelectedPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(page);

  return {
    selectedPageSize,
    setSelectedPageSize,
    currentPage,
    setCurrentPage,
    startIndex: (currentPage - 1) * selectedPageSize,
    endIndex: currentPage * selectedPageSize,
  };
};

/**
 *
 */
export const useNotifyHeightChanged = (observers: Array<mixed>) => {
  useLayoutEffect(() => {
    const element = document.querySelector('.embed.container');

    if (window.self === window.top || window.parent == null) {
      // We're not inside an IFrame, don't do anything...
      return;
    }

    window.parent.postMessage(
      {
        type: 'height-changed',
        height: element?.offsetHeight ?? 0,
      },
      '*',
    );
  }, observers); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 *
 */
export const useNotifyModalOpened = (
  observers: Array<mixed>,
): ((ref: HTMLDivElement) => void) =>
  useCallback((ref: HTMLDivElement) => {
    if (ref != null) {
      const element = ref.querySelector('.modal-dialog');

      if (window.self === window.top || window.parent == null) {
        // We're not inside an IFrame, don't do anything...
        return;
      }

      window.parent.postMessage(
        {
          type: 'modal-opened',
          height: element?.offsetHeight ?? 0,
        },
        '*',
      );
    }
  }, observers); // eslint-disable-line react-hooks/exhaustive-deps

export const useMergedErrors = <T>(
  serverErrors: ?(ErrorMessage[]),
  clientErrors: T,
): T => {
  return useMemo(() => {
    const errors = { ...clientErrors };
    serverErrors?.forEach((e) => {
      Reflect.set(errors, e.field, { message: e.description });
    });
    return errors;
  }, [serverErrors, clientErrors]);
};

export const useStrainSearchParams = <T>(
  defaultInit: T,
): [URLSearchParams, (updates: T) => void] => {
  // $FlowFixMe check-later
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  const searchAsObject = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const updateFunction = useCallback(
    (updates: T) => {
      setSearchParams(
        // $FlowFixMe check-later
        Object.fromEntries(
          Object.entries({
            ...searchAsObject,
            ...updates,
          }).filter(([, v]) => v != null),
        ),
      );
    },
    [setSearchParams, searchAsObject],
  );

  return [searchParams, updateFunction];
};
