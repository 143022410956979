// @flow strict

import { FormattedMessage } from 'react-intl';

import type { Node } from 'react';

const RequestingGeoPoint = function (): Node {
  return (
    <div className="text-center my-5">
      <div className="p-15">
        <div className="h3">
          <FormattedMessage id="common.requesting-geopoint" /> ...
        </div>
      </div>
    </div>
  );
};

export default RequestingGeoPoint;
