// @flow strict

import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import type { Node } from 'react';

type Props = {
  className?: string,
  disabled?: ?boolean,
  loading?: boolean,
  block?: boolean,
  textId: string,
  color?: string,
  type?: string,
  onClick?: () => void,
  children?: Node,
  iconClass?: string,
  shadow?: boolean,
  size?: string,
};

const RequestButton = function ({
  className = '',
  disabled,
  color = 'primary',
  loading = false,
  block = false,
  shadow = true,
  textId,
  type,
  onClick,
  children,
  iconClass,
  size = 'lg',
}: Props): Node {
  return (
    <Button
      onClick={onClick}
      disabled={disabled ?? loading}
      color={color}
      className={`btn-multiple-state ${className} ${
        loading ? 'show-spinner' : ''
      } ${shadow ? 'btn-shadow' : ''}`}
      size={size}
      type={type}
      block={block}
    >
      <span className="spinner d-inline-block">
        <span className="bounce1" />
        <span className="bounce2" />
        <span className="bounce3" />
      </span>
      {!loading && children}

      {iconClass != null && <i className={iconClass} />}

      <span className="label ms-2">
        <FormattedMessage id={textId} />
      </span>
    </Button>
  );
};

export default RequestButton;
