// @flow strict
import type { Node } from 'react';
import { List, ListInlineItem } from 'reactstrap';
import cx from 'classnames';

type Props = {| rating: string, className?: ?string |};
function Rating({ rating, className }: Props): Node {
  return (
    <List className={cx(`ratings ratings-${rating}`, className)}>
      <ListInlineItem className="star">
        <i className="flaticon-star-1" />
      </ListInlineItem>
      <ListInlineItem className="star">
        <i className="flaticon-star-1" />
      </ListInlineItem>
      <ListInlineItem className="star">
        <i className="flaticon-star-1" />
      </ListInlineItem>
      <ListInlineItem className="star">
        <i className="flaticon-star-1" />
      </ListInlineItem>
      <ListInlineItem className="star">
        <i className="flaticon-star-1" />
      </ListInlineItem>
    </List>
  );
}

export default Rating;
