/* eslint-disable no-unreachable */
// @flow strict

import type { ApolloError } from '@apollo/client';
import type { Node } from 'react';

import GQLError from './GQLError';

type Props = {|
  loading: boolean,
  isEmpty: boolean,
  error?: ApolloError,
  children: Node,
  notFound?: Node,
  placeholder?: Node,
|};

const QueryResult = function ({
  loading,
  isEmpty,
  error,
  children,
  notFound,
  placeholder,
}: Props): Node {
  if (loading) {
    return placeholder ?? null;
  }

  if (isEmpty && error == null) {
    return notFound ?? null;
  }

  if (error != null) {
    return <GQLError error={error} />;
  }

  return children ?? null;
};

export default QueryResult;
