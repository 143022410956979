// @flow strict
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import type { Node } from 'react';

import Header from '../organisms/Header';
import Home from '../pages/Home';
import ListDispensaries from '../pages/ListDispensaries';
import BrandList from '../pages/BrandList';
import ProductList from '../pages/ProductList';
import DispensaryDetails from '../pages/DispensaryDetails';
import ProductDetail from '../pages/ProductDetail';
import SuccessModal from '../cells/SuccessModal';
import ChangeAlertModal from '../cells/ChangeAlertModal';
import OrderOnline from '../pages/OrderOnline';
import ScrollToTop from './ScrollToTop';

function AppRouter(): Node {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/dispensaries" element={<ListDispensaries />} />
        <Route
          path="/order-online/:businessId/stores/:locationId"
          element={<OrderOnline />}
        />
        <Route
          path="/dispensaries/:businessId/stores/:storeId"
          element={<DispensaryDetails />}
        />
        <Route
          path="/dispensaries/:businessId/stores/:locationId/product/:productId"
          element={<ProductDetail />}
        />
        <Route path="/brands" element={<BrandList />} />
        <Route path="/products" element={<ProductList />} />
      </Routes>

      <ChangeAlertModal />
      <SuccessModal />
    </BrowserRouter>
  );
}

export default AppRouter;
