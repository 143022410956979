// @flow strict
import type { Node } from 'react';
import { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

type Props = {|
  items: Array<{|
    title: string,
    description: string,
    key: string,
  |}>,
|};

function ListingFaq({ items }: Props): Node {
  const [accordionActive, setAccordionActive] = useState('');

  const setAccordion = (value: string) =>
    value === accordionActive ? '' : value;

  const activeAccordion = (value: string) => value === accordionActive;

  const accordionItems = items.map((item) => (
    <div className="card" key={item.key}>
      <AccordionItem>
        <AccordionHeader
          targetId={item.key}
          onClick={() => setAccordionActive(setAccordion(item.key))}
        >
          {item.title}
        </AccordionHeader>
        <AccordionBody accordionId={item.key}>
          <div className="card-body">
            <p>{item.description}</p>
          </div>
        </AccordionBody>
      </AccordionItem>
    </div>
  ));
  return (
    <Accordion
      className="faq-accordian"
      open={accordionActive}
      toggle={activeAccordion}
    >
      {accordionItems}
    </Accordion>
  );
}

export default ListingFaq;
