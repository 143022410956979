/* eslint-disable jsx-a11y/control-has-associated-label */
// @flow strict

import { useState, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from 'reactstrap';

import type { Node } from 'react';
import type { CartItem as CartItemType } from 'types';

import QuantitySelector from './QuantitySelector';
import FormattedCurrency from '../atoms/FormattedCurrency';

type Props = {|
  item: CartItemType,
  editQty: (index: number, qty: number) => void,
  removeItemCart: (index: number) => void,
  index: number,
|};

function CartItem({ item, editQty, removeItemCart, index }: Props): Node {
  const [modal, setModal] = useState(false);
  const { productId, productName, quantity, subtotal, discount } = item;

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal, setModal]);

  const handleOk = () => {
    removeItemCart(index);
    toggle();
  };

  const handleCancel = () => {
    toggle();
  };

  const hasDiscount = discount > 0;
  const realPrice = hasDiscount ? subtotal - discount : subtotal;

  return (
    <>
      <tr id="content-cart-item" key={productId}>
        <td className="pl-0">{productName}</td>
        <td className="pl-0" aria-label="Action">
          <QuantitySelector
            noRound
            defaultValue={quantity}
            onChange={(qty) => editQty(index, qty)}
            size="small"
            max={10}
            min={1}
          />
        </td>
        <td className="pl-0">
          <FormattedCurrency value={realPrice} />{' '}
          {hasDiscount ? (
            <span className="text-muted ml-2">
              <del>
                <FormattedCurrency value={subtotal} />
              </del>
            </span>
          ) : null}
        </td>
        <td className="p-0">
          <NavLink onClick={() => toggle()}>
            <img src="/assets/images/icons/trash-bin.png" alt="brand" />
          </NavLink>
        </td>
      </tr>
      <Modal centered isOpen={modal} toggle={toggle} className="CartRemoveItem">
        <ModalHeader toggle={toggle}>Confirm this action</ModalHeader>
        <ModalBody>
          <p>Are your sure to remove product from cart ?</p>
        </ModalBody>
        <ModalFooter>
          <Button className="main-btn invert" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="main-btn invert" onClick={handleOk}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CartItem;
