/* eslint-disable ft-flow/generic-spacing */
/* eslint-disable ft-flow/space-after-type-colon */
// @flow strict

import {
  // $FlowFixMe
  ApolloLink,
  // $FlowFixMe
  Observable,
} from '@apollo/client/core';
// $FlowFixMe
import { print } from 'graphql';
import { createClient } from 'graphql-ws';

import type { FetchResult, Operation } from '@apollo/client';

// NOTE. types from graphql-ws library

// eslint-disable-next-line no-var
var MessageType: {|
  +ConnectionInit: 'connection_init', // "connection_init"
  +ConnectionAck: 'connection_ack', // "connection_ack"
  +Ping: 'ping', // "ping"
  +Pong: 'pong', // "pong"
  +Subscribe: 'subscribe', // "subscribe"
  +Next: 'next', // "next"
  +Error: 'error', // "error"
  +Complete: 'complete', // "complete"
|};

/**
 * ID is a string type alias representing
 * the globally unique ID used for identifying
 * subscriptions established by the client.
 * @category Common
 */
type ID = string;

/**
 * Function that allows customization of the produced JSON string
 * for the elements of an outgoing `Message` object.
 *
 * Read more about using it:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#the_replacer_parameter
 * @category Common
 */
type JSONMessageReplacer = (key: string, value: mixed) => mixed;

/**
 * Function for transforming values within a message during JSON parsing
 * The values are produced by parsing the incoming raw JSON.
 *
 * Read more about using it:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#using_the_reviver_parameter
 * @category Common
 */
type JSONMessageReviver = (key: string, value: mixed) => mixed;

/**
 * @category Common
 */
interface ConnectionInitMessage {
  // $FlowFixMe
  +type: typeof MessageType.ConnectionInit;
  +payload?: { [key: string]: mixed, ... };
}

/**
 * Configuration used for the GraphQL over WebSocket client.
 * @category Client
 */
interface ClientOptions {
  /**
   * URL of the GraphQL over WebSocket Protocol compliant server to connect.
   *
   * If the option is a function, it will be called on every WebSocket connection attempt.
   * Returning a promise is supported too and the connecting phase will stall until it
   * resolves with the URL.
   *
   * A good use-case for having a function is when using the URL for authentication,
   * where subsequent reconnects (due to auth) may have a refreshed identity token in
   * the URL.
   */
  url: string | (() => Promise<string> | string);

  /**
   * Optional parameters, passed through the `payload` field with the `ConnectionInit` message,
   * that the client specifies when establishing a connection with the server. You can use this
   * for securely passing arguments for authentication.
   *
   * If you decide to return a promise, keep in mind that the server might kick you off if it
   * takes too long to resolve! Check the `connectionInitWaitTimeout` on the server for more info.
   *
   * Throwing an error from within this function will close the socket with the `Error` message
   * in the close event reason.
   */
  connectionParams?:
    | $PropertyType<ConnectionInitMessage, 'payload'>
    | (() =>
        | Promise<$PropertyType<ConnectionInitMessage, 'payload'>>
        | $PropertyType<ConnectionInitMessage, 'payload'>);

  /**
   * Controls when should the connection be established.
   *
   * - `false`: Establish a connection immediately. Use `onNonLazyError` to handle errors.
   * - `true`: Establish a connection on first subscribe and close on last unsubscribe. Use
   * the subscription sink's `error` to handle errors.
   * @default true
   */
  lazy?: boolean;

  /**
   * Used ONLY when the client is in non-lazy mode (`lazy = false`). When
   * using this mode, the errors might have no sinks to report to; however,
   * to avoid swallowing errors, consider using `onNonLazyError`,  which will
   * be called when either:
   * - An unrecoverable error/close event occurs
   * - Silent retry attempts have been exceeded
   *
   * After a client has errored out, it will NOT perform any automatic actions.
   *
   * The argument can be a websocket `CloseEvent` or an `Error`. To avoid bundling
   * DOM types, you should derive and assert the correct type. When receiving:
   * - A `CloseEvent`: retry attempts have been exceeded or the specific
   * close event is labeled as fatal (read more in `retryAttempts`).
   * - An `Error`: some internal issue has occured, all internal errors are
   * fatal by nature.
   * @default console.error
   */
  onNonLazyError?: (errorOrCloseEvent: mixed) => void;

  /**
   * How long should the client wait before closing the socket after the last oparation has
   * completed. This is meant to be used in combination with `lazy`. You might want to have
   * a calmdown time before actually closing the connection. Kinda' like a lazy close "debounce".
   * @default 0 // close immediately
   */
  lazyCloseTimeout?: number;

  /**
   * The timout between dispatched keep-alive messages, naimly server pings. Internally
   * dispatches the `PingMessage` type to the server and expects a `PongMessage` in response.
   * This helps with making sure that the connection with the server is alive and working.
   *
   * Timeout countdown starts from the moment the socket was opened and subsequently
   * after every received `PongMessage`.
   *
   * Note that NOTHING will happen automatically with the client if the server never
   * responds to a `PingMessage` with a `PongMessage`. If you want the connection to close,
   * you should implement your own logic on top of the client. A simple example looks like this:
   *
   * ```js
   * import { createClient } from 'graphql-ws';
   *
   * let activeSocket, timedOut;
   * createClient({
   *   url: 'ws://i.time.out:4000/after-5/seconds',
   *   keepAlive: 10_000, // ping server every 10 seconds
   *   on: {
   *     connected: (socket) => (activeSocket = socket),
   *     ping: (received) => {
   *       if (!received) // sent
   *         timedOut = setTimeout(() => {
   *           if (activeSocket.readyState === WebSocket.OPEN)
   *             activeSocket.close(4408, 'Request Timeout');
   *         }, 5_000); // wait 5 seconds for the pong and then close the connection
   *     },
   *     pong: (received) => {
   *       if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
   *     },
   *   },
   * });
   * ```
   * @default 0
   */
  keepAlive?: number;

  /**
   * Disable sending the `PongMessage` automatically.
   *
   * Useful for when integrating your own custom client pinger that performs
   * custom actions before responding to a ping, or to pass along the optional pong
   * message payload. Please check the readme recipes for a concrete example.
   */
  disablePong?: boolean;

  /**
   * How many times should the client try to reconnect on abnormal socket closure before it errors out?
   *
   * The library classifies the following close events as fatal:
   * - `1002: Protocol Error`
   * - `1011: Internal Error`
   * - `4400: Bad Request`
   * - `4401: Unauthorized` _tried subscribing before connect ack_
   * - `4409: Subscriber for <id> already exists` _distinction is very important_
   * - `4429: Too many initialisation requests`
   *
   * These events are reported immediately and the client will not reconnect.
   * @default 5
   */
  retryAttempts?: number;

  /**
   * Control the wait time between retries. You may implement your own strategy
   * by timing the resolution of the returned promise with the retries count.
   * `retries` argument counts actual connection attempts, so it will begin with
   * 0 after the first retryable disconnect.
   * @default Randomised exponential backoff
   */
  retryWait?: (retries: number) => Promise<void>;

  /**
   * Check if the close event or connection error is fatal. If you return `true`,
   * the client will fail immediately without additional retries; however, if you
   * return `false`, the client will keep retrying until the `retryAttempts` have
   * been exceeded.
   *
   * The argument is either a WebSocket `CloseEvent` or an error thrown during
   * the connection phase.
   *
   * Beware, the library classifies a few close events as fatal regardless of
   * what is returned. They are listed in the documentation of the `retryAttempts`
   * option.
   * @default Non close events
   */
  isFatalConnectionProblem?: (errOrCloseEvent: mixed) => boolean;

  /**
   * Register listeners before initialising the client. This way
   * you can ensure to catch all client relevant emitted events.
   *
   * The listeners passed in will **always** be the first ones
   * to get the emitted event before other registered listeners.
   */
  on?: $Rest<
    /* eslint-disable no-restricted-globals */
    // $FlowFixMe
    $ObjMapi<{ [k: Event]: mixed }, <event>(event) => EventListener<event>>,
    { ... },
    /* eslint-enable no-restricted-globals */
  >;

  /**
   * A custom WebSocket implementation to use instead of the
   * one provided by the global scope. Mostly useful for when
   * using the client outside of the browser environment.
   */
  webSocketImpl?: mixed;

  /**
   * A custom ID generator for identifying subscriptions.
   *
   * The default generates a v4 UUID to be used as the ID using `Math`
   * as the random number generator. Supply your own generator
   * in case you need more uniqueness.
   *
   * Reference: https://gist.github.com/jed/982883
   */
  generateID?: () => ID;

  /**
   * An optional override for the JSON.parse function used to hydrate
   * incoming messages to this client. Useful for parsing custom datatypes
   * out of the incoming JSON.
   */
  jsonMessageReviver?: JSONMessageReviver;

  /**
   * An optional override for the JSON.stringify function used to serialize
   * outgoing messages from this client. Useful for serializing custom
   * datatypes out to the client.
   */
  jsonMessageReplacer?: JSONMessageReplacer;
}

/**
 * @category Common
 */
interface Disposable {
  /**
   * Dispose of the instance and clear up resources.
   */
  dispose: () => void | Promise<void>;
}

/**
 * @category Common
 */
interface SubscribePayload {
  +operationName?: string | null;
  +query: string;
  +variables?: { [key: string]: mixed, ... } | null;
  +extensions?: { [key: string]: mixed, ... } | null;
}

/**
 * A representation of any set of values over any amount of time.
 * @category Common
 */
interface Sink<T = mixed> {
  /**
   * Next value arriving.
   */
  next(value: T): void;

  /**
   * An error that has occured. Calling this function "closes" the sink.
   * Besides the errors being `Error` and `readonly GraphQLError[]`, it
   * can also be a `CloseEvent`, but to avoid bundling DOM typings because
   * the client can run in Node env too, you should assert the close event
   * type during implementation.
   */
  error(error: mixed): void;

  /**
   * The sink has completed. This function "closes" the sink.
   */
  complete(): void;
}

/**
 * @category Client
 */
type Client = Disposable & {
  /**
   * Listens on the client which dispatches events about the socket state.
   */
  on<E: Event>(event: E, listener: EventListener<E>): () => void,

  /**
   * Subscribes through the WebSocket following the config parameters. It
   * uses the `sink` to emit received data or errors. Returns a _cleanup_
   * function used for dropping the subscription and cleaning stuff up.
   */
  subscribe<T>(payload: SubscribePayload, sink: Sink<T>): () => void,
  ...
};

class WebSocketLink extends ApolloLink {
  client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  request(
    operation: Operation,
    // $FlowFixMe
  ): Observable<FetchResult> {
    return new Observable((sink) => {
      // $FlowFixMe
      return this.client.subscribe<FetchResult>(
        // $FlowFixMe
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          // $FlowFixMe
          error: (err) => {
            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              );
            }

            if (err instanceof Error || err.map == null) {
              return sink.error(err);
            }

            return sink.error(
              new Error(err.map(({ message }) => message).join(', ')),
            );
          },
        },
      );
    });
  }
}

export default WebSocketLink;
