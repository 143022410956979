// @flow strict
import React, { useState, useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Form, FormGroup, Label } from 'reactstrap';

import { useForm } from 'react-hook-form';

import type { Node } from 'react';

import Input from '../molecules/form/Input';
import PickupTime from '../molecules/PickupTime';
import { ORDER_TYPES } from '../constants';

import type { Cart } from '../types';

type Props = {|
  data: Cart,
  onSubmit: ({
    orderType: string,
    note?: string,
    deliveryAddress?: string,
    pickup?: string,
  }) => void,
|};

// $FlowFixMe
const CartForm: React$AbstractComponent<
  Props,
  {| handleSubmitExt: () => void |},
> = React.forwardRef(({ data, onSubmit }: Props, ref): Node => {
  const { note } = data;

  const [orderType, setOrderType] = useState(ORDER_TYPES.PICK_UP);
  const { register, control, handleSubmit, getValues } = useForm();

  function handleSubmitExt() {
    onSubmit(getValues());
  }

  useImperativeHandle(ref, () => ({ handleSubmitExt }));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label className="bold">
        <FormattedMessage id="order-online.cart-detail.form.type" />
      </Label>
      <FormGroup tag="fieldset" className="d-flex">
        <FormGroup check>
          <Input
            id="pick-up"
            type="radio"
            value={ORDER_TYPES.PICK_UP}
            onClick={() => setOrderType(ORDER_TYPES.PICK_UP)}
            checked={orderType === ORDER_TYPES.PICK_UP}
            {...register('orderType')}
          />
          <Label check for="pick-up">
            <FormattedMessage id="order-online.cart-detail.form.pick-up" />
          </Label>
        </FormGroup>
        {/*
        <FormGroup check className="px-5">
          <Input
            id="delivery"
            type="radio"
            value={ORDER_TYPES.DELIVERY}
            onClick={() => setOrderType(ORDER_TYPES.DELIVERY)}
            {...register('orderType')}
          />
          <Label check for="delivery">
            <FormattedMessage id="order-online.cart-detail.form.delivery" />
          </Label>
        </FormGroup>
        */}
      </FormGroup>
      {orderType === ORDER_TYPES.PICK_UP && (
        <Col lg={6} md={12} sm={12}>
          <FormGroup>
            <Label for="pickup-time" className="bold">
              <FormattedMessage id="order-online.cart-detail.form.pickup-time" />
            </Label>
            <FormattedMessage id="order-online.cart-detail.form.pickup-placeholder">
              {(msg) => (
                <PickupTime name="pickup" placeholder={msg} control={control} />
              )}
            </FormattedMessage>
          </FormGroup>
        </Col>
      )}
      {/*
      orderType === ORDER_TYPES.DELIVERY && (
        <Col lg={6} md={12} sm={12}>
          <FormGroup>
            <Label for="deliveryAddress" className="bold">
              <FormattedMessage id="order-online.cart-detail.form.address" />
            </Label>
            <Input
              type="text"
              placeholder={deliveryAddress}
              {...register('deliveryAddress')}
            />
          </FormGroup>
        </Col>
      )
      */}
      <FormGroup>
        <Label for="orderNotes" className="bold">
          <FormattedMessage id="order-online.cart-detail.form.notes" />
        </Label>
        <FormattedMessage id="order-online.cart-detail.form.notes-placeholder">
          {(msg) => (
            <Input
              type="textarea"
              placeholder={msg}
              defaultValue={note}
              {...register('note')}
            />
          )}
        </FormattedMessage>
      </FormGroup>
      {/*
      <FormGroup check className="mb-3">
        <Input id="orderTerms" name="terms" type="checkbox" />
        <Label for="orderTerms" check>
          <FormattedMessage id="order-online.cart-detail.order-terms" />
        </Label>
      </FormGroup>
      */}
    </Form>
  );
});

CartForm.displayName = 'CartForm';

export default CartForm;
