// @flow strict

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

import type { Node } from 'react';
import type { Product } from 'types';

import CBDBadge from '../atoms/CBDBadge';
import StrainTypeBadge from '../atoms/StrainTypeBadge';
import THCBadge from '../atoms/THCBadge';
import FormattedCurrency from '../atoms/FormattedCurrency';

import { WEIGHT_TYPE } from '../constants';

type Props = {|
  product: Product,
  showTaxes?: boolean,
  locationId: number,
  businessId: number,
  logo?: string,
  storeName?: string,
|};

function ProductContent({
  product,
  showTaxes,
  locationId,
  businessId,
  logo,
  storeName,
}: Props): Node {
  const {
    itemNumber,
    name,
    category,
    percentageTHC,
    thcUnitMeasurement,
    type,
    percentageCBD,
    cbdUnitMeasurement,
  } = product;
  const variant = product.variants ? product.variants[0] : null;

  const hasDiscount = variant ? variant.price.discount > 0 : false;

  let price = variant ? variant.price.price - variant.price.discount : 0;
  let basePrice = variant ? variant.price.price : 0;

  if (showTaxes === true && variant) {
    price += variant.price.taxes;
    basePrice += variant.price.taxesExclDiscount;
  }

  return (
    <div className="product-content">
      <div>
        <Link
          to={
            product.variants
              ? `/dispensaries/${businessId}/stores/${locationId}/product/${itemNumber}`
              : ''
          }
        >
          <h5 className="title">{name}</h5>
        </Link>
        <span className="category">
          <FormattedMessage
            // $FlowFixMe
            id={`category.${slugify(category?.name ?? '', { lower: true })}`}
          />
        </span>
        {product.brand && category.name && (
          <span className="category divider">{` - `}</span>
        )}
        {product.brand && <span className="brand">{product.brand.name}</span>}
        <span className="data d-flex gap-2">
          <StrainTypeBadge type={type} />
          <THCBadge
            percentageTHC={percentageTHC}
            thcUnitMeasurement={thcUnitMeasurement}
          />
          <CBDBadge
            percentageCBD={percentageCBD}
            cbdUnitMeasurement={cbdUnitMeasurement}
          />
        </span>
      </div>
      <div>
        {showTaxes !== undefined && (
          <span className="taxes">
            <FormattedMessage id="product-detail.content.tax" />
          </span>
        )}

        <span className="price">
          <h5>
            <FormattedCurrency value={price} />
            {product.variants && (
              <span>
                {`/ ${product.variants[0].weight}`}
                <span>
                  <FormattedMessage
                    id={
                      product.variants[0].weight > 1 &&
                      product.variants[0].uom === 'EA'
                        ? 'product.weight-plural'
                        : WEIGHT_TYPE[product.variants[0].uom]
                    }
                  />
                </span>
              </span>
            )}
          </h5>

          {hasDiscount ? (
            <span className="text-muted ml-1">
              <small>
                <del>
                  <FormattedCurrency value={basePrice} />
                </del>
              </small>
            </span>
          ) : null}
        </span>

        <span className="phone-meta">
          {logo != null && (
            <Link
              to={`/dispensaries/${businessId}/stores/${locationId}`}
              className="cat-btn"
            >
              <img className="listing-icon" src={logo} alt="dispensary" />
            </Link>
          )}
          {storeName != null && (
            <Link to={`/dispensaries/${businessId}/stores/${locationId}`}>
              {storeName}
            </Link>
          )}
        </span>
      </div>
    </div>
  );
}

export default ProductContent;
