// @flow strict

import { useIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';
import { InputGroup, InputGroupText, Input } from 'reactstrap';

import type { Node } from 'react';

import { useDebounce } from '../hooks';

type Props = {|
  className?: string,
  hideIcon?: boolean,
  defaultValue?: ?string,
  placeholder?: ?string,
  onChange: (searchTerm: ?string) => void,
|};

const SearchInput = function ({
  className,
  onChange,
  placeholder,
  defaultValue,
  hideIcon = false,
}: Props): Node {
  const { messages } = useIntl();
  const firstOnChangeSkipped = useRef(false);

  const [search, setSearch] = useState(defaultValue ?? '');
  const debouncedSearch = useDebounce(search, 300);

  const handleChange = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    setSearch(evt.target.value);
  };

  useEffect(() => {
    if (firstOnChangeSkipped.current) {
      onChange(debouncedSearch !== '' ? debouncedSearch : undefined);
    } else {
      firstOnChangeSkipped.current = true;
    }

    // NOTE: only when debouncedSearch was changed needs to be fired
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <InputGroup>
      <Input
        className={className}
        size="30"
        value={search}
        placeholder={placeholder ?? messages['menu.search']}
        onChange={handleChange}
      />
      {hideIcon === false && (
        <InputGroupText>
          <i className="ti-search" />
        </InputGroupText>
      )}
    </InputGroup>
  );
};

export default SearchInput;
