/* eslint-disable jsx-a11y/control-has-associated-label */
// @flow strict
import type { Node } from 'react';

import { Link } from 'react-router-dom';
import { Col, Container, List, Row } from 'reactstrap';

import ButtonMailTo from '../atoms/ButtonMailTo';

function Footer(): Node {
  return (
    <div className="footer">
      <div className="footer-top bg-animation">
        <Container>
          <Row>
            <Col md={3}>
              <h4>¿Quienes Somos?</h4>
              <p className="mb-4">
                En STRAIN Marketplace encontrarás tus productos y marcas
                favoritas de cannabis, descuentos y ofertas en los dispensarios
                cerca de ti.
              </p>
              <List type="unstyled">
                <li>
                  <i className="ti-email" />
                  <a
                    href="mailto:marketplace@thestrainapp.com"
                    className="ms-2 text-light"
                  >
                    marketplace@thestrainapp.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://thestrain.io/fbstrainmarket"
                    rel="noreferrer"
                    target="_blank"
                    className="social-link"
                    aria-label="Facebook"
                  >
                    <i className="ti-facebook" />
                  </a>
                  <a
                    href="https://thestrain.io/instrainmarket"
                    rel="noreferrer"
                    target="_blank"
                    className="social-link"
                    aria-label="Instagram"
                  >
                    <i className="ti-instagram" />
                  </a>
                </li>
              </List>
            </Col>
            <Col md={1} />
            <Col md={2}>
              <h4>Descubre</h4>
              <List type="unstyled">
                <li>
                  <Link to="/dispensaries">
                    <i className="ti-angle-right" /> Dispensarios
                  </Link>
                </li>
                {false && (
                  <>
                    <li>
                      <Link to="/brands">
                        <i className="ti-angle-right" /> Marcas
                      </Link>
                    </li>
                    <li>
                      <Link to="/dispensaries">
                        <i className="ti-angle-right" /> Cepas
                      </Link>
                    </li>
                    <li>
                      <Link to="/dispensaries">
                        <i className="ti-angle-right" /> Doctores
                      </Link>
                    </li>
                    <li>
                      <Link to="/dispensaries">
                        <i className="ti-angle-right" /> Educate
                      </Link>
                    </li>
                  </>
                )}
              </List>
            </Col>
            <Col md={2}>
              <h4>Legales</h4>
              <List type="unstyled">
                <li>
                  <Link to="https://thestrain.io/marketplace-tnc">
                    <i className="ti-angle-right" /> Términos y Condiciones
                  </Link>
                </li>
                <li>
                  <Link to="https://thestrain.io/marketplace-pp">
                    <i className="ti-angle-right" /> Política de Privacidad
                  </Link>
                </li>
                {false && (
                  <li>
                    <Link to="/dispensaries">
                      <i className="ti-angle-right" /> Política de Cookies
                    </Link>
                  </li>
                )}
              </List>
            </Col>
            <Col className="text-center" md={4}>
              <h4>¿Eres un dispensario o marca?</h4>
              <ButtonMailTo
                email="marketplace@thestrainapp.com"
                className="btn btn-success btn-lg px-5 py-3"
              >
                Contáctanos Aquí
                <i className="ti-email align-middle ms-3" />
              </ButtonMailTo>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-copyright">
        <Container>
          <p className="copyright text-center">
            Copyright © {new Date().getFullYear()} STRAIN, llc. All rights
            reserved.
          </p>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
