// @flow strict
import { useState } from 'react';

import type { Node } from 'react';

import Image from '../atoms/Image';

type Props = {| src: string, alt: string, disableOnMobile: boolean |};

function ZoomImage({ src, alt, disableOnMobile }: Props): Node {
  const [backgroundPos, setBackgroundPos] = useState('0% 0%');

  const handleMouseMove = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    // $FlowFixMe
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPos(`${x}% ${y}%`);
  };
  return (
    <figure
      className={`zoom-image ${disableOnMobile ? '-disable-on-mobile' : ''}`}
      onMouseMove={handleMouseMove}
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: backgroundPos,
      }}
    >
      <Image src={src} alt={alt} />
    </figure>
  );
}

export default ZoomImage;
