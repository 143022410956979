// @flow strict
import type { Node } from 'react';

import { Link } from 'react-router-dom';

type Props = {|
  logo: string,
  name: string,
  businessId: string,
  locationId: string,
|};

function DispensaryAvatar({ logo, name, businessId, locationId }: Props): Node {
  return (
    <Link
      className="avatar avatar-dispensary"
      to={`/dispensaries/${businessId}/stores/${locationId}`}
    >
      <img
        className="avatar-image"
        src={logo || '/assets/images/default-placeholder.png'}
        alt="{name}"
      />
      <span className="avatar-title">{name}</span>
    </Link>
  );
}

export default DispensaryAvatar;
