// @flow strict

import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import slugify from 'slugify';

import type { Node } from 'react';

import Select from './form/Select';
import ProductTypeSelect from '../cells/select/ProductType';
import ProductSortSelect from '../cells/select/ProductSort';
import SearchInput from '../cells/SearchInput';
import cities from '../data/municipalities';
import { FILTER_PRODUCTS } from '../constants';

import { useStrainSearchParams } from '../hooks';

import type { ProductCategory, Brand } from '../types';
import type { Option } from './form/Select';

type Props = {
  className?: ?string,
  productCategories: ProductCategory[],
  brands?: Brand[],
  hideMostSold?: boolean,
  hideProductCategories?: boolean,
  withCities?: boolean,
};

const byLabel = function (a: Option, b: Option): number {
  if (b.value == null) {
    return 1;
  }

  if (typeof a.label === 'string' && typeof b.label === 'string') {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
  }
  return 0;
};

const ProductFilters = function ({
  className,
  productCategories,
  brands,
  hideMostSold = false,
  hideProductCategories = false,
  withCities = false,
}: Props): Node {
  const { messages } = useIntl();
  const [searchParams, updateSearchParams] = useStrainSearchParams();

  const productCategoriesFilter = productCategories.filter((product) => {
    let ok = true;
    for (let i = 0; i < FILTER_PRODUCTS.length && ok; i += 1) {
      const filter = FILTER_PRODUCTS[i];
      if (filter.name === product.name) ok = false;
    }
    return ok;
  });

  const productCategoriesOptions = useMemo(() => {
    const result = productCategoriesFilter.map((pc) => {
      return {
        label:
          messages[`category.${slugify(pc.name, { lower: true })}`] ?? pc.name,
        value: pc.id,
      };
    });

    result.unshift({
      label: messages['product.filter.option.all-categories'],
      // $FlowFixMe
      value: null,
    });

    result.sort(byLabel);

    return result;
  }, [productCategoriesFilter, messages]);

  const cityOptions = cities.map(({ municipality }) => ({
    label: municipality,
    value: municipality,
  }));

  cityOptions.unshift({
    label: messages['dispensary.filter-opt'],
    // $FlowFixMe
    value: null,
  });

  const brandOptions = useMemo(() => {
    const result = brands?.map((pc) => ({
      label: pc.name,
      value: pc.id,
    }));

    result?.unshift({
      label: messages['product.filter.option.all-brands'],
      // $FlowFixMe
      value: null,
    });

    return result;
  }, [brands, messages]);

  const handleChangeProductCategory = (option: ?(Option | Array<Option>)) => {
    // $FlowFixMe
    updateSearchParams({ category: option?.value });
  };

  const handleChangeBrand = (option: ?(Option | Array<Option>)) => {
    // $FlowFixMe
    updateSearchParams({ brand: option?.value });
  };

  const handleOnProductTypeSelected = (option: ?string) => {
    // $FlowFixMe
    updateSearchParams({ type: option });
  };

  const handleOnProductSortSelected = (option: ?string) => {
    // $FlowFixMe
    updateSearchParams({ sort: option });
  };

  const handleOnChangeSearch = (option: ?string) => {
    // $FlowFixMe
    updateSearchParams({ search: option });
  };

  const handleSelectCity = (option: ?(Option | Array<Option>)) => {
    // $FlowFixMe
    updateSearchParams({ city: option?.value });
  };

  const selectedCategory = productCategoriesOptions.find(
    (pc) => pc.value === +searchParams.get('category'),
  );

  const selectedBrand = brandOptions?.find(
    (b) => b.value === +searchParams.get('brand'),
  );

  const selectedCityOption = cityOptions.find(
    (b) => b.value === searchParams.get('city'),
  );

  return (
    <Row className={cx('align-items-center', className)}>
      <Col className="d-block d-md-none">
        <FormGroup className="text-xl-end text-center">
          <small className="text-green">
            <i className="ti-reload me-2" />
            <FormattedMessage id="common.updated-5-minutes-ago" />
          </small>
        </FormGroup>
      </Col>
      <Col lg={12}>
        <Row>
          {withCities === true && (
            <Col md={4} lg={3}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="product.filter.label.city" />
                </Label>
                <Select
                  className="dropdown-primary"
                  placeholder={messages['dispensary.filter-opt']}
                  // $FlowFixMe
                  options={cityOptions}
                  value={selectedCityOption}
                  onChange={handleSelectCity}
                />
              </FormGroup>
            </Col>
          )}
          {hideProductCategories === true ? null : (
            <Col md={4} lg={3}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="product.filter.label.category" />
                </Label>
                <Select
                  className="dropdown-primary"
                  // $FlowFixMe
                  options={productCategoriesOptions}
                  placeholder={messages['product.filter.option.all-categories']}
                  onChange={handleChangeProductCategory}
                  value={selectedCategory}
                />
              </FormGroup>
            </Col>
          )}
          {brandOptions && (
            <Col md={4} lg={3}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="product.filter.label.brand" />
                </Label>
                <Select
                  className="dropdown-primary"
                  // $FlowFixMe
                  options={brandOptions}
                  placeholder={messages['product.filter.option.all-brands']}
                  onChange={handleChangeBrand}
                  value={selectedBrand}
                />
              </FormGroup>
            </Col>
          )}
          <Col md={4} lg={3}>
            <FormGroup>
              <Label>
                <FormattedMessage id="product.filter.label.sortby" />
              </Label>
              <ProductSortSelect
                className="dropdown-primary"
                onSelected={handleOnProductSortSelected}
                selected={searchParams.get('sort')}
                hideMostSold={hideMostSold}
              />
            </FormGroup>
          </Col>
          <Col md={4} lg={3}>
            <FormGroup>
              <Label>
                <FormattedMessage id="product.filter.label.strain-type" />
              </Label>
              <ProductTypeSelect
                className="dropdown-primary"
                onSelected={handleOnProductTypeSelected}
                selected={searchParams.get('type')}
              />
            </FormGroup>
          </Col>

          <Col md={12} lg={3}>
            <FormGroup>
              <Label className="d-none d-xl-block">&nbsp;</Label>
              <SearchInput
                onChange={handleOnChangeSearch}
                defaultValue={searchParams.get('search')}
                placeholder={
                  messages['product.filter.placeholder.search-products']
                }
              />
            </FormGroup>
          </Col>
          <Col className="d-none d-md-block">
            <FormGroup className="text-xl-end text-center">
              <Label className="d-none d-xl-block">&nbsp;</Label>
              <small className="text-green">
                <i className="ti-reload me-2" />
                <FormattedMessage id="common.updated-5-minutes-ago" />
              </small>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductFilters;
