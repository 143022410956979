// @flow strict
import { FormattedMessage } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
import { FormGroup, ModalHeader, Modal, ModalBody, Button } from 'reactstrap';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import { currentCartVar } from '../graphql/reactive-variables';

const ChangeAlertModal = function (): Node {
  const match = useMatch('/:basePath/:businessId/stores/:locationId/*') ?? {};

  const currentCart = useReactiveVar(currentCartVar);
  const { businessId, locationId } = match.params ?? {
    businessId: null,
    locationId: null,
  };

  const handleToggle = () => {
    currentCartVar({
      businessId: null,
      locationId: null,
    });
  };

  const isOpen =
    currentCart.locationId != null &&
    currentCart.businessId != null &&
    (currentCart.businessId !== businessId ||
      currentCart.locationId !== locationId);

  const returnUrl = `/dispensaries/${currentCart.businessId}/stores/${currentCart.locationId}`;

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={handleToggle}
      className="embed-success-modal"
    >
      <ModalHeader toggle={handleToggle} />
      <ModalBody>
        <div className="d-flex justify-content-center">
          <div>
            <div className="text-center">
              <img
                className="img-thumbnail image"
                src="/assets/images/added-to-cart.png"
                alt="Strain"
              />
            </div>
            <p className="text-center mt-3 mb-4">
              <FormattedMessage id="order-online.change-alert-message" />
            </p>
            <FormGroup className="text-center">
              <Button
                className="text-uppercase"
                color="success"
                tag={Link}
                to={returnUrl}
              >
                <FormattedMessage id="order-online.continue-shopping-dispensary" />
              </Button>
            </FormGroup>
            <div className="text-center">
              <Button
                className="text-uppercase text-danger text-decoration-none"
                color="link"
                onClick={handleToggle}
              >
                <FormattedMessage id="order-online.cancel-order" />
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangeAlertModal;
