// @flow strict

import { useQuery } from '@apollo/client';

import type { ListResponse, ProductCategory } from 'types';

import GRAPHQL from '../graphql';

const EMPTY_LIST_PAGINATED_RESPONSE = {
  total: 0,
  objects: [],
};

// eslint-disable-next-line import/prefer-default-export
export const useProductCategories = (
  options?: ?{},
  // $FlowFixMe
): QueryResult<
  ListResponse<ProductCategory>,
  {|
    limit: ?number,
  |},
> => {
  // $FlowFixMe
  const { data, ...rest } = useQuery(GRAPHQL.PRODUCT_CATEGORY.LIST, {
    ...options,
  });

  // $FlowFixMe
  return {
    ...rest,
    // $FlowFixMe
    data: data?.business?.productCategories ?? EMPTY_LIST_PAGINATED_RESPONSE,
  };
};
