// @flow strict

import { onGet, onSave } from './store';

import type { OpenHour } from '../types';
import { DAYS_ORDERED } from '../constants';

export default function isOpen(openHours: OpenHour[]): boolean {
  const date = new Date();
  if (openHours != null) {
    const openHour = openHours.find(
      ({ day }) => day === DAYS_ORDERED[date.getDay()],
    );

    if (openHour != null) {
      const close = +openHour.close.replace(':', '');
      const open = +openHour.open.replace(':', '');
      const currentTime = +date.toTimeString().slice(0, 5).replace(':', '');
      return currentTime < close && currentTime >= open;
    }
  }
  return false;
}

export function getPatientId(businessId: string): ?{ patientId?: string } {
  const patient = new Map(Object.entries(onGet('patient')));
  // $FlowFixMe
  return patient.has(businessId) ? patient.get(businessId) : {};
}

export function addPatientId(patientId: string, businessId: string): void {
  const patient = new Map(Object.entries(onGet('patient')));
  // $FlowFixMe
  patient.set(businessId, { patientId });
  // $FlowFixMe
  onSave('patient', Object.fromEntries(patient));
}

export function onAddCart(
  quantity: number,
  { id, ...data }: { id: string },
): void {
  const cart = new Map(Object.entries(onGet('cart')));
  // $FlowFixMe
  if (cart.has(id)) {
    // $FlowFixMe
    cart.delete(id);
  }
  // $FlowFixMe
  cart.set(id, { data, quantity });
  // $FlowFixMe
  onSave('cart', Object.fromEntries(cart));
}

export function onCartCount(): number {
  return Object.keys(onGet('cart')).length;
}
