// @flow strict

export default {
  'common.add-cart': 'Add to Cart',
  'common.continue': 'Continue',
  'common.updated-5-minutes-ago': 'Updated 5 minutes ago',
  'common.no.products.found': 'No products found',
  'common.no.dispensaries.found': 'No dispensaries found',
  'common.requesting-geopoint': 'Requeting your location',
  'common.close': 'Close',

  'nav-menu.dispensaries': 'Dispensaries',
  'nav-menu.products': 'Products',
  'nav-menu.blog': 'Blog',

  'header.top-bar.follow': 'Follow us',
  'header.top-bar.cart': 'Cart',
  'header.top-bar.content': 'Today only 50% discount on',
  'header.top-bar.offer': 'Show Offer',
  'header.nav-bar.logo': 'Marketplace',
  'header.nav-bar.certificate': 'Get Certified',
  'header.nav-bar.dispensary': 'Dispensaries',
  'header.nav-bar.language': 'Language',
  'home.lightbox.title': 'To enhanced your experience and a faster ',
  'home.lightbox.title2': 'checkout please enter your Cannabis patient ID.',
  'home.lightbox.input-patient': 'Enter Patient ID',
  'home.lightbox.continue': 'Continue',
  'home.lightbox.continue-guest': 'Continue as Guest',
  'home.lightbox.no-patient-id-in-records':
    'That Cannabis patient ID is not valid, please include all digits.',
  'home.area.title': 'The Largest Network of Dispensaries in Puerto Rico',
  'home.area.description': 'Find your favorite products at the best price',
  'home.area.dispensaries': 'Dispensaries',
  'home.area.brands': 'Brands',
  'home.area.doctors': 'Doctors',
  'home.area.popular': 'Popular',
  'home.area.popular-data': 'UMA , Edibles, Madlove, Flowers, Cartridges, 1919',
  'home.area.search-filter': 'Search Dispensary',
  'home.area.search-btn': 'Search Now',
  'home.area.filter-opt': 'Near me',
  'home.category.title': 'Categories',
  'home.category.title2': 'Featured',
  'home.category.accessories': 'Accessories',
  'home.category.drinks': 'Drinks',
  'home.category.flowers': 'Flowers',
  'home.category.cartridges': 'Cartridges',
  'home.category.edibles': 'Edibles',
  'home.category.concentrates': 'Concentrates',
  'home.category.topicals': 'Topicals',
  'home.category.tinctures': 'Tinctures',
  'home.dispensary.title': 'Dispensaries',
  'home.dispensary.title2': 'Featured',
  'home.dispensary.sub-title': 'The Favorites!',
  'home.dispensary.card.miles-away': 'Miles away',
  'home.dispensary.card.favorite': 'Favorite',
  'home.dispensary.card.status-open': 'Open',
  'home.dispensary.card.status-close': 'Close',
  'home.dispensary.card.map': 'Show map',
  'home.dispensary.card.view-more': 'View Dispensary',
  'home.dispensary.card.featured': 'Featured',
  'home.brand.title': 'Brands',
  'home.brand.sub-title': 'The Most Wanted',
  'home.brand.show-brands': 'Browse More Brands',
  'home.offer.title': 'Offers',
  'home.offer.title2': 'Featured',
  'home.offer.sub-title': 'More for your money!',
  'home.offer.expiration': 'Expires ',
  'home.recommended.title': 'Products',
  'home.recommended.title2': 'Recommended',
  'home.recommended.filter.All': 'All',
  'home.recommended.filter.Anxiety': 'Anxiety',
  'home.recommended.filter.Cancer': 'Cancer',
  'home.recommended.filter.Depression': 'Depression',
  'home.recommended.filter.Pain': 'Pain',
  'home.recommended.filter.Insomnia': 'Insomnia',
  'home.recommended.sub-title': 'For your Condition!',
  'home.show-all': 'View All >',
  'home.show-all-2': 'View All >',
  'home.ribbon-banner.title': 'Save with the best deals near you!',
  'home.ribbon-banner.offer': 'View Offers',
  'home.sold-product.title': 'Products',
  'home.sold-product.title2': 'Best Selling',
  'home.sold-product.sub-title': `The ones that don't last!`,
  'home.sold-product.filter.Flowers': 'Flowers',
  'home.sold-product.filter.Cartridges': 'Cartridges',
  'home.sold-product.filter.Edibles': 'Edibles',
  'home.sold-product.filter.Pre-rolls': 'Pre-rolls',
  'home.sold-product.filter.Tinctures': 'Tinctures',
  'home.sold-product.filter.Topicals': 'Topicals',
  'home.sold-product.filter.Capsules': 'Capsules',
  'home.sold-product.filter.Syringes': 'Syringes',
  'home.testimonial.title': 'Why are we',
  'home.testimonial.title2': 'Love us?',
  'home.instagram.title': 'Taggeanos #chillconSTRAIN',
  'home.instagram.sub-title': 'Our Community',
  'home.how-work.sub-title': `Don't know where to start?`,
  'home.how-work.title': `It's as simple as`,
  'home.medical-banner.title':
    'With Cannabis you will have a happy and healthy life!',
  'home.medical-banner.description':
    'Get or renew your Medical Cannabis license in 24 hours or less with our certified doctors.',
  'home.medical-banner.button': 'Certificate Here',
  'home.budtender-banner.title':
    'The importance of using the right strain at the right time.',
  'home.budtender-banner.description':
    "After watching this video you will learn which type of flower is right for your lifestyle. Whether it's to focus on your work, increase creativity or just relax after a day with this video you will learn more.",
  'home.budtender-banner.sub-title': 'Your Budtender [Indio Hernandez].',
  'home.budtender-banner.button': 'Show Video',
  'list-dispensary.filter-default': 'Nearby',
  'list-dispensary.filter': 'Alphabetical',
  'list-dispensary.filter.all': 'All',
  'list-dispensary.filter.featured': 'Featured',
  'list-dispensary.filter.open': 'Open',
  'list-dispensary.show-result': 'Show Results',
  'dispensary.filter-opt': 'All',
  'dispensary.filter-search-key': 'Search by name',
  'dispensary.filter.label.search': 'Dispensary Name',
  'dispensary.filter.label.category': 'Select Category',
  'dispensary.filter.label.city': 'Select City',
  'dispensary.filter.search-now': 'Search Now',
  'dispensary.title': 'Dispensary',
  'dispensary.about': 'About Dispensary',
  'dispensary.features.title': 'Products and Services',
  'dispensary.products.title': 'Our Products',
  'dispensary.listing-tag.title': 'Popular Tag',
  'dispensary.listing-faq.title': 'Asked Question',
  'dispensary.products.load-more': 'Load More',
  'product-detail.content.quantity': 'Quantity Available',
  'product-detail.content.size': 'Select Product Weight: ',
  'product-detail.content.tax': 'Tax Included',
  'product-detail.tab.add-information': 'Additional information',
  'product-detail.tab.related-product': 'Related Products',
  'product.filter.label.city': 'Select City',
  'product.filter.label.category': 'Select Category',
  'product.filter.label.brand': 'Select Brand',
  'product.filter.label.sortby': 'Sort By',
  'product.filter.label.strain-type': 'Select Strain Type',
  'product.filter.placeholder.search-products': 'Search products',
  'product.filter.option.all-categories': 'All',
  'product.filter.option.all-brands': 'All',
  'product.filter.option.all-strain-types': 'All',
  'product.filter.sort.option.featured': 'Featured',
  'product.filter.sort.option.name': 'Name',
  'product.filter.sort.option.newest': 'Newest',
  'product.filter.sort.option.most-sold': 'Most Sold',
  'product.filter.strain-type.option.hybrid': 'Hybrid',
  'product.filter.strain-type.option.indica': 'Indica',
  'product.filter.strain-type.option.sativa': 'Sativa',
  'product.weight-gr': 'g.',
  'product.weight-each': 'Unit',
  'order-online.change-alert-message':
    'Navigating outside of dispensary where you are will reset your cart',
  'order-online.continue-shopping-dispensary': 'Continue Shopping',
  'order-online.cart-detail.store': 'Store: ',
  'order-online.cart-detail.date': 'Date: ',
  'order-online.cart-detail.products': 'Products: ',
  'order-online.cart-detail.products.qty': 'Qty',
  'order-online.cart-detail.products.name': 'Product Name',
  'order-online.cart-detail.products.price': 'Price',
  'order-online.cart-detail.form.type': 'Select order type: ',
  'order-online.cart-detail.form.pick-up': 'Pick up',
  'order-online.cart-detail.form.delivery': 'Delivery',
  'order-online.cart-detail.form.address': 'Shipping Address: *',
  'order-online.cart-detail.form.pickup-time': 'Pickup time',
  'order-online.cart-detail.form.notes': 'Order Notes:',
  'order-online.cart-detail.form.notes-placeholder':
    'Add here any additional note about your order.',
  'order-online.cart-detail.form.pickup-placeholder': 'Select time',
  'order-online.cart-detail.form.continue-shopping': 'Continue shopping',
  'order-online.cart-detail.form.complete-order': 'Complete order',
  'order-online.cart-detail.summary.title': 'Summary',
  'order-online.cart-detail.summary.sub-total': 'Subtotal: ',
  'order-online.cart-detail.summary.tax': 'Tax: ',
  'order-online.cart-detail.summary.discount': 'Apply a discount',
  'order-online.cart-detail.summary.coupon.discount': 'Coupon Discount',
  'order-online.cart-detail.summary.coupon': 'Coupon',
  'order-online.cart-detail.summary.placeholder.coupon-code': 'Coupon Code',
  'order-online.continue-shopping': 'CONTINUE SHOPPING',
  'order-online.view-cart': 'View Cart',
  'order-online.cancel-order': 'Cancel Order',
  'order-online.or': 'OR',
  'order-online.added-success-message':
    'The product was successfully added to the order.',
  'order-online.cart-detail.order-terms':
    'EXPRESS PICKUP: Orders will be held for 24 hours after the selected reservation time. ALL DISCOUNTS WILL BE APPLIED AT THE TIME OF PAYMENT.',
  'order-online.guest-checkout': 'Continue as Guest',

  'day.abbr.MONDAY': 'Mon.',
  'day.abbr.TUESDAY': 'Tue.',
  'day.abbr.WEDNESDAY': 'Wed.',
  'day.abbr.THURSDAY': 'Thu.',
  'day.abbr.FRIDAY': 'Fri.',
  'day.abbr.SATURDAY': 'Sat.',
  'day.abbr.SUNDAY': 'Sun.',

  'user.name': 'Name',
  'user.license': 'Patient ID',
  'user.placeholder.license': 'PAxxxxxxx',
  'user.placeholder.phoneNumber': '787-777-7777',
  'user.phoneNumber': 'Phone Number',

  'validation.required-fields': 'Required fields',
  'validation.required-field': 'Required field',

  /* Categories */
  'category.all': 'All',
  'category.accessories': 'Accessories',
  'category.capsules': 'Capsules',
  'category.cartridges': 'Cartridges',
  'category.cbd': 'CBD',
  'category.clones': 'Clones',
  'category.concentrates': 'Concentrates',
  'category.drinks': 'Drinks',
  'category.edibles': 'Edibles',
  'category.extracts': 'Extracts',
  'category.flower': 'Flower',
  'category.inhalers': 'Inhalers',
  'category.kief': 'Kief',
  'category.merch': 'Merch',
  'category.moonrocks': 'Moonrocks',
  'category.oils': 'Oils',
  'category.other': 'Other',
  'category.pets': 'Pets',
  'category.prerolls': 'Prerolls',
  'category.seeds': 'Seeds',
  'category.shake': 'Shake',
  'category.specials': 'Specials',
  'category.specialty': 'Specialty',
  'category.suppositories': 'Suppositories',
  'category.syringes': 'Syringes',
  'category.tablets': 'Tablets',
  'category.tinctures': 'Tinctures',
  'category.topicals': 'Topicals',
  'category.trim': 'Trim',
  'category.vaporizers': 'Vaporizers',
  'category.wax': 'Wax',

  'age-gate.title': 'Are You Over 21 Years Old?',
  'age-gate.verify-age': "Let's check your age",
  'age-gate.under-21': 'YES, LET ME IN!',
  'age-gate.above-21': 'NOT YET...',
  'age-gate.body':
    'By accesing this website you are accepting out <tc>Terms and Conditions</tc> & <pt>Privacy Policy</pt>',
};
