// @flow strict
import type { Node } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Select from '../../molecules/form/Select';

import type { Option } from '../../molecules/form/Select';

type Props = {|
  className?: string,
  selected?: ?string,
  onSelected: (id: ?string) => void,
|};

const ProductTypeSelect = function ({
  className,
  selected,
  onSelected,
}: Props): Node {
  const { messages } = useIntl();
  // $FlowFixMe
  const handleChange = ({ value }: ?(Option | Array<Option>)) => {
    // $FlowFixMe
    onSelected(value);
  };

  const STRAIN_TYPE_OPTIONS = useMemo(() => {
    return [
      {
        label: messages['product.filter.option.all-strain-types'],
        value: null,
      },
      {
        label: messages['product.filter.strain-type.option.hybrid'],
        value: 'HYBRID',
      },
      {
        label: messages['product.filter.strain-type.option.indica'],
        value: 'INDICA',
      },
      {
        label: messages['product.filter.strain-type.option.sativa'],
        value: 'SATIVA',
      },
    ];
  }, [messages]);

  const value = STRAIN_TYPE_OPTIONS.find((option) => option.value === selected);

  return (
    <Select
      className={className}
      options={STRAIN_TYPE_OPTIONS}
      value={value}
      onChange={handleChange}
      placeholder="All Strain Types"
    />
  );
};

export default ProductTypeSelect;
