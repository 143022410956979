// @flow strict
import type { Node } from 'react';
import { Children } from 'react';

type Props = {
  children: Node,
};

const Shimmer = ({ children }: Props): Node =>
  Children.map(children, (child) => (
    // $FlowFixMe
    <child.type
      // $FlowFixMe
      {...child.props}
      // $FlowFixMe
      className={`shimmer ${child.props.className}`}
    />
  ));

export default Shimmer;
