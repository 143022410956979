// @flow strict

export default [
  {
    municipality: 'Adjuntas',
    lat: 18.1629513,
    lng: -66.72039574,
  },
  {
    municipality: 'Aguada',
    lat: 18.37966552,
    lng: -67.18856973,
  },
  {
    municipality: 'Aguadilla',
    lat: 18.42725412,
    lng: -67.1532608,
  },
  {
    municipality: 'Aguas Buenas',
    lat: 18.2571327,
    lng: -66.10483237,
  },
  {
    municipality: 'Aibonito',
    lat: 18.13996877,
    lng: -66.26471127,
  },
  {
    municipality: 'Añasco',
    lat: 18.28267368,
    lng: -67.14043414,
  },
  {
    municipality: 'Arecibo',
    lat: 18.46596679,
    lng: -66.72856981,
  },
  {
    municipality: 'Arroyo',
    lat: 18.00971274,
    lng: -66.05837394,
  },
  {
    municipality: 'Barceloneta',
    lat: 18.45048065,
    lng: -66.53937864,
  },
  {
    municipality: 'Barranquitas',
    lat: 18.18431127,
    lng: -66.30784676,
  },
  {
    municipality: 'Bayamón',
    lat: 18.37453543,
    lng: -66.1659005,
  },
  {
    municipality: 'Cabo Rojo',
    lat: 18.08782617,
    lng: -67.14598993,
  },
  {
    municipality: 'Caguas',
    lat: 18.23759809,
    lng: -66.03500457,
  },
  {
    municipality: 'Camuy',
    lat: 18.43143325,
    lng: -66.86795632,
  },
  {
    municipality: 'Canóvanas',
    lat: 18.37434924,
    lng: -65.90484677,
  },
  {
    municipality: 'Carolina',
    lat: 18.38110408,
    lng: -65.95726695,
  },
  {
    municipality: 'Cataño',
    lat: 18.43672697,
    lng: -66.14731501,
  },
  {
    municipality: 'Cayey',
    lat: 18.1142572,
    lng: -66.16624435,
  },
  {
    municipality: 'Ceiba',
    lat: 18.26481953,
    lng: -65.64820397,
  },
  {
    municipality: 'Ciales',
    lat: 18.3239006,
    lng: -66.49057155,
  },
  {
    municipality: 'Cidra',
    lat: 18.1758536,
    lng: -66.16087854,
  },
  {
    municipality: 'Coamo',
    lat: 18.07982493,
    lng: -66.35798333,
  },
  {
    municipality: 'Comerío',
    lat: 18.22087857,
    lng: -66.22569592,
  },
  {
    municipality: 'Corozal',
    lat: 18.34164624,
    lng: -66.32131581,
  },
  {
    municipality: 'Culebra',
    lat: 18.32019198,
    lng: -65.29232884,
  },
  {
    municipality: 'Dorado',
    lat: 18.4480755,
    lng: -66.29009893,
  },
  {
    municipality: 'Fajardo',
    lat: 18.3378107,
    lng: -65.65568353,
  },
  {
    municipality: 'Florida',
    lat: 18.36683384,
    lng: -66.56074987,
  },
  {
    municipality: 'Guánica',
    lat: 17.97288815,
    lng: -66.90983088,
  },
  {
    municipality: 'Guayama',
    lat: 17.97576004,
    lng: -66.11611307,
  },
  {
    municipality: 'Guayanilla',
    lat: 18.01918892,
    lng: -66.79226685,
  },
  {
    municipality: 'Guaynabo',
    lat: 18.38076253,
    lng: -66.1096456,
  },
  {
    municipality: 'Gurabo',
    lat: 18.25436572,
    lng: -65.97303388,
  },
  {
    municipality: 'Hatillo',
    lat: 18.48172611,
    lng: -66.82327482,
  },
  {
    municipality: 'Hormigueros',
    lat: 18.13401432,
    lng: -67.11120914,
  },
  {
    municipality: 'Humacao',
    lat: 18.15074347,
    lng: -65.82709202,
  },
  {
    municipality: 'Isabela',
    lat: 18.45683579,
    lng: -67.01015451,
  },
  {
    municipality: 'Jayuya',
    lat: 18.21863898,
    lng: -66.59613012,
  },
  {
    municipality: 'Juana Díaz',
    lat: 18.05237806,
    lng: -66.50656036,
  },
  {
    municipality: 'Juncos',
    lat: 18.22755611,
    lng: -65.92093982,
  },
  {
    municipality: 'Lajas',
    lat: 18.04957833,
    lng: -67.05958362,
  },
  {
    municipality: 'Lares',
    lat: 18.29896237,
    lng: -66.88316171,
  },
  {
    municipality: 'Las Marías',
    lat: 18.25224186,
    lng: -66.99070445,
  },
  {
    municipality: 'Las Piedras',
    lat: 18.18865682,
    lng: -65.87318065,
  },
  {
    municipality: 'Loíza',
    lat: 18.43328013,
    lng: -65.87796726,
  },
  {
    municipality: 'Luquillo',
    lat: 18.38112739,
    lng: -65.71691702,
  },
  {
    municipality: 'Manatí',
    lat: 18.42832441,
    lng: -66.47934159,
  },
  {
    municipality: 'Maricao',
    lat: 18.18346213,
    lng: -66.98154184,
  },
  {
    municipality: 'Maunabo',
    lat: 18.00804697,
    lng: -65.89961457,
  },
  {
    municipality: 'Mayagüez',
    lat: 18.20223247,
    lng: -67.14670711,
  },
  {
    municipality: 'Moca',
    lat: 18.39471886,
    lng: -67.11348473,
  },
  {
    municipality: 'Morovis',
    lat: 18.32605411,
    lng: -66.40693008,
  },
  {
    municipality: 'Naguabo',
    lat: 18.21164478,
    lng: -65.73520039,
  },
  {
    municipality: 'Naranjito',
    lat: 18.30259953,
    lng: -66.24666192,
  },
  {
    municipality: 'Orocovis',
    lat: 18.22745066,
    lng: -66.39178904,
  },
  {
    municipality: 'Patillas',
    lat: 18.03109338,
    lng: -66.01137117,
  },
  {
    municipality: 'Peñuelas',
    lat: 18.05810293,
    lng: -66.72275719,
  },
  {
    municipality: 'Ponce',
    lat: 18.01169394,
    lng: -66.61462679,
  },
  {
    municipality: 'Quebradillas',
    lat: 18.47430428,
    lng: -66.93802251,
  },
  {
    municipality: 'Rincón',
    lat: 18.33593299,
    lng: -67.2505411,
  },
  {
    municipality: 'Río Grande',
    lat: 18.37668461,
    lng: -65.84158409,
  },
  {
    municipality: 'Sabana Grande',
    lat: 18.08400288,
    lng: -66.96989661,
  },
  {
    municipality: 'Salinas',
    lat: 18.00974008,
    lng: -66.2560012,
  },
  {
    municipality: 'San Germán',
    lat: 18.08059441,
    lng: -67.0417779,
  },
  {
    municipality: 'San Juan',
    lat: 18.3958848,
    lng: -66.05685481,
  },
  {
    municipality: 'San Lorenzo',
    lat: 18.19130053,
    lng: -65.96723068,
  },
  {
    municipality: 'San Sebastián',
    lat: 18.33332668,
    lng: -66.99666744,
  },
  {
    municipality: 'Santa Isabel',
    lat: 17.96656649,
    lng: -66.40529756,
  },
  {
    municipality: 'Toa Alta',
    lat: 18.38859317,
    lng: -66.24858324,
  },
  {
    municipality: 'Toa Baja',
    lat: 18.44446412,
    lng: -66.21015846,
  },
  {
    municipality: 'Trujillo Alto',
    lat: 18.35428502,
    lng: -66.00887755,
  },
  {
    municipality: 'Utuado',
    lat: 18.27204332,
    lng: -66.70518336,
  },
  {
    municipality: 'Vega Alta',
    lat: 18.41507337,
    lng: -66.31858644,
  },
  {
    municipality: 'Vega Baja',
    lat: 18.43484785,
    lng: 66.40052412,
  },
  {
    municipality: 'Vieques',
    lat: 18.1328562,
    lng: -65.44075605,
  },
  {
    municipality: 'Villalba',
    lat: 18.1336168,
    lng: 66.48073166,
  },
  {
    municipality: 'Yabucoa',
    lat: 18.05049593,
    lng: 65.87957597,
  },
  {
    municipality: 'Yauco',
    lat: 18.0341515,
    lng: -66.8639244,
  },
];
