// @flow strict
import gql from 'graphql-tag';

const CART_DETAIL: $FlowFixMe = gql`
  query CartDetail(
    $id: Int
    $businessId: Int
    $patientId: String
    $locationId: Int
  ) {
    store(id: $locationId) {
      id
      name
      business {
        id
        inAppMessages {
          orderSubmitted
        }
      }
    }
    cart(
      id: $id
      businessId: $businessId
      patientId: $patientId
      locationId: $locationId
    ) {
      id
      orderType
      cartType
      status
      createdAt
      submittedAt
      couponDiscount
      redeemedPoints
      discountForRedeemedPoints
      updatedAt
      note
      deliveryAddress
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
      total
      subTotal
      tax
    }
  }
`;

export default {
  CART_DETAIL,
};
