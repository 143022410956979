// @flow strict
import gql from 'graphql-tag';

const ADD_CART: typeof gql = gql`
  mutation AddItemToCart(
    $filter: CartDetailFilterInput
    $cartItem: CartItemInput!
  ) {
    addItemToCart(filter: $filter, cartItem: $cartItem) {
      id
      createdAt
      submittedAt
      total
      subTotal
      tax
      couponDiscount
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
    }
  }
`;

const SUBMIT: $FlowFixMe = gql`
  mutation SubmitCart($filter: CartDetailFilterInput, $cart: SubmitCartInput) {
    submitCart(filter: $filter, cart: $cart) {
      id
    }
  }
`;

const REMOVE_ITEM: $FlowFixMe = gql`
  mutation RemoveItemFromCart($filter: CartDetailFilterInput, $index: Int!) {
    removeItemFromCart(filter: $filter, index: $index) {
      id
      createdAt
      submittedAt
      total
      subTotal
      tax
      couponDiscount
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
    }
  }
`;

const EDIT_QTY_ITEM: $FlowFixMe = gql`
  mutation EditQuantityItemFromCart(
    $filter: CartDetailFilterInput
    $index: Int!
    $quantity: Int!
  ) {
    editQuantityItemFromCart(
      filter: $filter
      index: $index
      quantity: $quantity
    ) {
      id
      createdAt
      submittedAt
      total
      subTotal
      tax
      couponDiscount
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
    }
  }
`;

const REDEEM_COUPON: $FlowFixMe = gql`
  mutation POSRedeemCouponTest($code: String!, $filter: CartDetailFilterInput) {
    redeemCoupon(code: $code, filter: $filter) {
      id
      createdAt
      submittedAt
      total
      subTotal
      tax
      couponDiscount
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
    }
  }
`;

const REMOVE_COUPON: $FlowFixMe = gql`
  mutation POSRemoveCoupon(
    $locationId: Int!
    $businessId: Int
    $patientId: String
  ) {
    removeCoupon(
      locationId: $locationId
      businessId: $businessId
      patientId: $patientId
    ) {
      id
      createdAt
      submittedAt
      total
      subTotal
      tax
      couponDiscount
      coupon {
        code
      }
      items {
        productId
        productVariantId
        productName
        productImage
        quantity
        variantId
        discount
        tax
        subtotal
        total
        price
        weightUOM
        weight
      }
    }
  }
`;

export default {
  ADD_CART,
  SUBMIT,
  REMOVE_ITEM,
  EDIT_QTY_ITEM,
  REDEEM_COUPON,
  REMOVE_COUPON,
};
