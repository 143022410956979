// @flow strict
import { FormattedMessage } from 'react-intl';
import { Row, Col, Container } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Slider from 'react-slick';

import type { Node } from 'react';

import ProductsMetaTags from '../molecules/seo/ProductsMetaTags';
import SlickArrow from '../molecules/SlickArrow';
import QueryResult from '../organisms/QueryResult';
import Footer from '../organisms/Footer';
import EmptyList from '../organisms/EmptyList';
import ProductFilters from '../molecules/ProductFilters';
import ProductListLoading from '../cells/Shimmers/ProductListLoading';
import SoldProductCard from '../cells/SoldProductCard';
import RequestButton from '../cells/RequestButton';
import RequestingGeoPoint from '../organisms/RequestingGeoPoint';
import CategoryCarouselFilter from '../cells/CategoryCarouselFilter';

import municipalities from '../data/municipalities';

import { useProductCategories } from '../hooks/product-category';
import { useGlobalProducts } from '../hooks/product';
import { useStrainSearchParams } from '../hooks';
import { OPTIONS_CATEGORIES, CATEGORIES } from '../constants';
import { coordinates } from '../graphql/reactive-variables';

const PRODUCTS_LIMIT = 12;

const getGeoPointValue = (
  city: ?string,
  userPoint: { lat: ?number, lng: ?number },
): ?{ geoPoint: { lat: number, lng: number } } => {
  const point =
    municipalities.find((m) => m.municipality === city) ?? userPoint;

  if (point.lat != null && point.lng != null) {
    return {
      geoPoint: {
        lat: point.lat,
        lng: point.lng,
      },
    };
  }

  return undefined;
};

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  dots: false,
  arrows: true,
  prevArrow: <SlickArrow arrow="left" />,
  nextArrow: <SlickArrow arrow="right" />,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
      },
    },
  ],
};

const sliderEdibles = (
  <Slider {...sliderSettings}>
    <a href="/brands?id=2057" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/brands/banner-uma.png"
        alt=""
      />
    </a>
  </Slider>
);
const sliderFlowers = (
  <Slider {...sliderSettings}>
    <a href="/brands?id=218" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/brands/boricana.png"
        alt=""
      />
    </a>
  </Slider>
);

const sliderAccessories = (
  <Slider {...sliderSettings}>
    <a href="/brands?id=1592" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/categories/herb-saver.png"
        alt=""
      />
    </a>
    <a href="/brands?id=1592" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/categories/honey-stick.png"
        alt=""
      />
    </a>
    <a href="/brands?id=1592" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/categories/randys.png"
        alt=""
      />
    </a>
    <a href="/brands?id=1592" aria-label="uma" rel="noreferrer">
      <img
        className="banner-img"
        src="/assets/images/banners/categories/xvape.png"
        alt=""
      />
    </a>
  </Slider>
);

function ProductList(): Node {
  const [searchParams, updateSearchParams] = useStrainSearchParams();
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  const point = useReactiveVar(coordinates);
  // $FlowFixMe
  const [getProducts, { loading, data: productsData, error, fetchMore }] =
    useGlobalProducts();

  // $FlowFixMe
  const { data: productCategoriesResponse } = useProductCategories({
    context: {
      headers: {
        'Strain-Business-Id': 6, // NOTE. use STRAIN ID
      },
    },
  });

  const productCategoryId = searchParams.get('category');
  const search = searchParams.get('search');
  const type = searchParams.get('type');
  const city = searchParams.get('city');
  const sortBy = searchParams.get('sort') ?? 'featured';
  const brandId = searchParams.get('brand');

  useEffect(() => {
    if (productCategoryId === null) {
      setCurrentActiveTab(null);
    } else {
      // $FlowFixMe
      setCurrentActiveTab(searchParams.get('category'));
    }

    if (point.allowed == null) {
      return;
    }

    // $FlowFixMe
    getProducts({
      // $FlowFixMe
      variables: {
        filter: {
          search,
          type,
          priceType: 'MED',
          categoryId:
            productCategoryId === null ? undefined : +currentActiveTab,
        },
        pagination: {
          limit: PRODUCTS_LIMIT,
        },
        sortBy: {
          ...getGeoPointValue(city, point),
          key: sortBy,
        },
      },
    });
  }, [
    getProducts,
    productCategoryId,
    search,
    type,
    city,
    point,
    sortBy,
    currentActiveTab,
    searchParams,
  ]);

  const productCategories = productCategoriesResponse.objects;
  // $FlowFixMe
  const { objects: products } = productsData;

  // $FlowFixMe
  const loadMore = productsData.total > products.length;

  // $FlowFixMe
  const handleChangeProductCategory = (id) => {
    setCurrentActiveTab(id);
    // $FlowFixMe
    updateSearchParams({ category: id });
  };

  const handleLoadMoreClick = () => {
    const pagination = {
      limit: PRODUCTS_LIMIT,
      offset: products.length,
    };

    // $FlowFixMe
    fetchMore({
      variables: {
        pagination,
      },
      // $FlowFixMe
      updateQuery: (prev, { fetchMoreResult }) => {
        const moreProducts = fetchMoreResult?.products;
        if (moreProducts.objects.length === 0) {
          return prev;
        }

        return {
          ...prev,
          products: {
            // $FlowFixMe
            ...prev.products,
            total: moreProducts.total,
            // $FlowFixMe
            objects: [...prev.products.objects, ...moreProducts.objects],
          },
        };
      },
    });
  };

  const optionsTab = OPTIONS_CATEGORIES.map((option) => ({
    key: option.key,
    children: (
      <div className="carousel-filter-prods" key={option.key}>
        <img src={option.icon} alt="" />
        <FormattedMessage id={option.name} />
      </div>
    ),
  }));

  return (
    <>
      <ProductsMetaTags />
      <section>
        <div className="breadcrumbs-wrapper mb-5">
          <Container className="container">
            {brandId === null &&
              parseInt(productCategoryId, 10) === CATEGORIES.EDIBLES &&
              sliderEdibles}
            {brandId === null &&
              parseInt(productCategoryId, 10) === CATEGORIES.ACCESSORIES &&
              sliderAccessories}
            {brandId === null &&
              parseInt(productCategoryId, 10) === CATEGORIES.FLOWERS &&
              sliderFlowers}
          </Container>
        </div>
      </section>

      <Container>
        <div className="carousel-filter pt-3">
          <CategoryCarouselFilter
            // $FlowFixMe
            data={optionsTab}
            currentActiveTab={currentActiveTab}
            setCurrentActiveTab={handleChangeProductCategory}
          />
        </div>
        <ProductFilters
          className="mb-3 mb-6"
          productCategories={productCategories}
          hideMostSold
          hideProductCategories
          withCities
        />

        {point.allowed == null ? (
          <RequestingGeoPoint />
        ) : (
          <QueryResult
            loading={loading}
            isEmpty={products == null}
            error={error}
            notFound={<EmptyList elements="products" />}
            placeholder={<ProductListLoading />}
          >
            <Row>
              {products.map((product) => {
                return product.variants &&
                  product.variants[0].price.price !== 0 ? (
                  <Col
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"
                    key={`${product.id}-${product.store.id}-${product.business.id}`}
                  >
                    <SoldProductCard
                      product={product}
                      showTaxes={product.store.showTaxes}
                      locationId={product.store.id}
                      businessId={product.business.id}
                      storeName={product.store.name}
                      storeId={product.store.id}
                      logo={product.business.logo}
                    />
                  </Col>
                ) : null;
              })}
            </Row>
          </QueryResult>
        )}

        {loadMore && (
          <Row>
            <Col className="mb-3 mt-3">
              <RequestButton
                loading={loading}
                textId="dispensary.products.load-more"
                color="success"
                className="mt-3"
                block
                onClick={handleLoadMoreClick}
              />
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default ProductList;
