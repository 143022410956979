/* eslint-disable jsx-a11y/control-has-associated-label */
// @flow strict
import { FormattedMessage } from 'react-intl';
import { Col, Container, Nav, NavItem, Row } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { useState } from 'react';

import Slider from 'react-slick';

import type { Node } from 'react';

import ButtonIcon from '../molecules/ButtonIcon';
import SlickArrow from '../molecules/SlickArrow';
import HeaderTop from './HeaderTop';

import { useOutsideClick } from '../hooks';

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  dots: false,
  fade: true,
  arrows: false,
  prevArrow: <SlickArrow arrow="left" />,
  nextArrow: <SlickArrow arrow="right" />,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
      },
    },
  ],
};

function Header(): Node {
  const [toggle, setToggle] = useState(false);

  const handleClickOutSide = () => {
    setToggle(false);
  };

  const ref = useOutsideClick(handleClickOutSide);

  return (
    <header className="header-area header-area-one d-xl-block fixed">
      <div className={`${toggle ? 'sidebar-on-mask' : ''}`} />
      <div className="cart-side-container" />
      <div className="cart-side-main">
        <HeaderTop />
        <div className="cart-side-content" />
      </div>
      <nav
        className={`navbar-container header-navigation ${
          toggle ? 'header-on-mask' : ''
        }`}
      >
        <Container fluid className="content">
          <Row>
            <Col className="content-logo" md="3" lg="2">
              <NavLink to="/" className="nav-link logo">
                <img src="/assets/images/logo.png" alt="logo" width="172" />
              </NavLink>
              <ButtonIcon
                className="burger-icon"
                onClick={() => setToggle(!toggle)}
              >
                <div className="icon-mid" />
              </ButtonIcon>
            </Col>
            <Col lg="5">
              <div className="nav-menu d-none d-lg-flex">
                <nav className="main-menu">
                  <ul>
                    <li className="menu-item">
                      <NavLink to="/dispensaries">
                        <FormattedMessage id="nav-menu.dispensaries" />
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink to="/products">
                        <FormattedMessage id="nav-menu.products" />
                      </NavLink>
                    </li>
                    {false && (
                      <li className="menu-item hidden">
                        <a
                          href="https://blog.thestrainmarketplace.com"
                          rel="noreferrer"
                          target="_blank"
                          aria-label="Blog"
                        >
                          <FormattedMessage id="nav-menu.blog" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </Col>
            <Col lg="5" className="align-self-center">
              <div
                ref={ref}
                className={`navbar-right-btn header-right-nav sidebar ${
                  toggle ? 'sidebar-on' : ''
                }`}
              >
                <a
                  href="/"
                  className="logo-menu d-lg-none d-xlg-none"
                  onClick={() => setToggle(false)}
                >
                  <img src="/assets/images/logo.png" alt="logo menu" />
                </a>
                <Container className="banner-menu d-lg-none">
                  <Slider {...sliderSettings}>
                    <NavLink
                      to="/dispensaries/302/stores/1"
                      onClick={() => setToggle(false)}
                    >
                      <img
                        className="banner-img"
                        src="/assets/images/banners/menu/kanyak.jpg"
                        alt="kanyak"
                      />
                    </NavLink>
                    <Link
                      to="https://app.canadoctors.com/thestrainmarket"
                      target="_blank"
                    >
                      <img
                        className="banner-img"
                        src="/assets/images/banners/menu/banner-menu.jpg"
                        alt="banner"
                      />
                    </Link>
                  </Slider>
                </Container>
                <ButtonIcon
                  className="burger-icon active"
                  onClick={() => setToggle(false)}
                >
                  <div className="icon-mid" />
                </ButtonIcon>
                <Nav className="d-block d-lg-none d-xlg-none mb-3" vertical>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/dispensaries"
                      onClick={() => setToggle(false)}
                    >
                      <FormattedMessage id="nav-menu.dispensaries" />
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/products"
                      onClick={() => setToggle(false)}
                    >
                      <FormattedMessage id="nav-menu.products" />
                    </Link>
                  </NavItem>
                  <NavItem>
                    <a
                      className="nav-link"
                      href="https://blog.thestrainmarketplace.com"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="Blog"
                      onClick={() => setToggle(false)}
                    >
                      <FormattedMessage id="nav-menu.blog" />
                    </a>
                  </NavItem>
                </Nav>
                <NavLink
                  target="_blank"
                  className="main-btn"
                  to="https://app.canadoctors.com/thestrainmarket"
                  onClick={() => setToggle(false)}
                >
                  <img
                    className="button-cannadoctors"
                    src="/assets/images/boton.svg"
                    alt="icon doctor"
                  />
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </nav>
    </header>
  );
}

export default Header;
