// @flow strict
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import Slider from 'react-slick';
import DispensariesMetaTags from '../molecules/seo/DispensariesMetaTags';
import SlickArrow from '../molecules/SlickArrow';
import EmptyList from '../organisms/EmptyList';
import QueryResult from '../organisms/QueryResult';
import TabFilter from '../cells/UrlTabFilter';
import RequestButton from '../cells/RequestButton';
import DispensaryCard from '../cells/DispensaryCard';
import DispensaryForm from '../organisms/DispensaryForm';
import ProductListLoading from '../cells/Shimmers/ProductListLoading';
import Footer from '../organisms/Footer';
import RequestingGeoPoint from '../organisms/RequestingGeoPoint';

import municipalities from '../data/municipalities';

import { useDispensaries } from '../hooks/dispensary';
import { useStrainSearchParams } from '../hooks';
import { coordinates } from '../graphql/reactive-variables';

import type { DispensarySortOption } from '../types';

const tabs = [
  {
    children: <FormattedMessage id="list-dispensary.filter.all" />,
    key: '1',
    value: null,
  },
  {
    children: <FormattedMessage id="list-dispensary.filter.featured" />,
    key: '2',
    value: 'isFeatured',
  },
  {
    children: <FormattedMessage id="list-dispensary.filter.open" />,
    key: '3',
    value: 'isOpenAt',
  },
];

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  prevArrow: <SlickArrow arrow="left" />,
  nextArrow: <SlickArrow arrow="right" />,
  dots: true,
  fade: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: 'button__bar',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
      },
    },
  ],
};

const DISPENSARIES_LIMIT = 12;

const getGeoPointSort = (
  city: ?string,
  userPoint: { lat: ?number, lng: ?number },
): ?DispensarySortOption => {
  const point =
    municipalities.find((m) => m.municipality === city) ?? userPoint;

  if (point.lat != null && point.lng != null) {
    return {
      key: 'GeoPoint',
      value: {
        lat: point.lat,
        lng: point.lng,
      },
      direction: 'ASC',
    };
  }

  return undefined;
};

function ListDispensaries(): Node {
  // $FlowFixMe
  const [getDispensaries, { loading, data: dispensaries, error, fetchMore }] =
    useDispensaries();
  const [searchParams] = useStrainSearchParams();
  const point = useReactiveVar(coordinates);

  const filterBy = searchParams.get('filterBy');
  const search = searchParams.get('search');
  const city = searchParams.get('city');

  useEffect(() => {
    if (point.allowed == null) {
      return;
    }

    const filter: {
      isFeatured?: ?boolean,
      isOpenAt?: ?Date,
      search?: ?string,
    } = {
      search: search != null ? search : undefined,
    };

    if (filterBy === 'isFeatured') {
      filter.isFeatured = true;
    }

    if (filterBy === 'isOpenAt') {
      filter.isOpenAt = new Date();
    }

    // $FlowFixMe
    getDispensaries({
      variables: {
        filter,
        sorting: getGeoPointSort(city, point),
        pagination: {
          limit: DISPENSARIES_LIMIT,
          offset: 0,
        },
      },
    });
  }, [getDispensaries, point, filterBy, search, city]);

  const handleLoadMoreClick = () => {
    const pagination = {
      limit: DISPENSARIES_LIMIT,
      // $FlowFixMe
      offset: dispensaries.objects.length,
    };

    // $FlowFixMe
    fetchMore({
      variables: {
        pagination,
      },
      // $FlowFixMe
      updateQuery: (prev, { fetchMoreResult }) => {
        const moreProducts = fetchMoreResult?.stores;

        if (moreProducts.objects.length === 0) {
          return prev;
        }

        return {
          ...prev,
          stores: {
            // $FlowFixMe
            ...prev.stores,
            total: moreProducts.total,
            // $FlowFixMe
            objects: [...prev.stores.objects, ...moreProducts.objects],
          },
        };
      },
    });
  };

  // $FlowFixMe
  const loadMore = dispensaries.total > dispensaries.objects.length;

  return (
    <>
      <DispensariesMetaTags />
      <section>
        <div className="breadcrumbs-wrapper">
          <Container className="container">
            {city !== 'Bayamón' && city !== 'Guaynabo' && (
              <Slider {...sliderSettings}>
                <a
                  href="/dispensaries/79/stores/1"
                  aria-label="Foresta"
                  rel="noreferrer"
                >
                  <img
                    className="banner-img"
                    src="/assets/images/banners/dispensaries/foresta-banner.png"
                    alt=""
                  />
                </a>
                <a
                  href="/dispensaries/34/stores/1"
                  aria-label="Turf"
                  rel="noreferrer"
                >
                  <img
                    className="banner-img"
                    src="/assets/images/banners/dispensaries/turf-banner.png"
                    alt=""
                  />
                </a>
                <a
                  href="dispensaries/302/stores/1"
                  aria-label="Kanyak"
                  rel="noreferrer"
                >
                  <img
                    className="banner-img"
                    src="/assets/images/banners/dispensaries/kanyak-banner.png"
                    alt=""
                  />
                </a>
              </Slider>
            )}
            {city === 'Bayamón' && (
              <Slider {...sliderSettings}>
                <a
                  href="/dispensaries/79/stores/1"
                  aria-label="Foresta"
                  rel="noreferrer"
                >
                  <img
                    className="banner-img"
                    src="/assets/images/banners/dispensaries/foresta-banner.png"
                    alt=""
                  />
                </a>
              </Slider>
            )}
            {city === 'Guaynabo' && (
              <Slider {...sliderSettings}>
                <a
                  href="/dispensaries/79/stores/1"
                  aria-label="Turf"
                  rel="noreferrer"
                >
                  <img
                    className="banner-img"
                    src="/assets/images/banners/dispensaries/turf-banner.png"
                    alt=""
                  />
                </a>
              </Slider>
            )}
          </Container>
        </div>
      </section>

      <section className="listing-grid-area pb-120">
        <Container className="content pt-2">
          <DispensaryForm cities={municipalities} />

          <Row className="justify-content-center">
            <Col className="col-lg-12">
              <div className="listing-search-filter">
                <Row className="align-items-center">
                  <Col lg={6}>
                    <div className="listing-tab-filter mb-3">
                      <TabFilter
                        className="justify-content-lg-start"
                        filters={tabs}
                        query="filterBy"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex justify-content-center justify-content-lg-end align-items-center mb-3">
                      <div className="show-text m-3">
                        <span>
                          <FormattedMessage id="list-dispensary.show-result" />
                          {` 1-${dispensaries?.objects.length || 0}`}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {point.allowed == null ? (
                <RequestingGeoPoint />
              ) : (
                <QueryResult
                  error={error}
                  // $FlowFixMe
                  isEmpty={dispensaries.objects.length <= 0}
                  loading={loading}
                  placeholder={<ProductListLoading />}
                  notFound={<EmptyList elements="dispensaries" />}
                >
                  <Row>
                    {dispensaries?.objects.map((item) => (
                      <Col
                        md={6}
                        lg={4}
                        xl={3}
                        key={`${item.id}-${item.business.id}`}
                      >
                        <DispensaryCard
                          data={item}
                          className="mb-4"
                          showDistance={city === 'all' || city == null}
                        />{' '}
                      </Col>
                    ))}
                  </Row>
                </QueryResult>
              )}

              {loadMore && (
                <Row>
                  <Col className="mb-3 mt-3">
                    <RequestButton
                      loading={loading}
                      textId="dispensary.products.load-more"
                      color="success"
                      className="mt-3"
                      block
                      onClick={handleLoadMoreClick}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ListDispensaries;
