// @flow strict
import type { Node } from 'react';
import Badge from './Badge';

type Props = {|
  percentageTHC: ?number,
  thcUnitMeasurement: ?string,
|};

function THCBadge({ percentageTHC, thcUnitMeasurement }: Props): Node {
  if (percentageTHC == null) {
    return null;
  }

  return (
    <Badge color="thc">
      THC: {percentageTHC}{' '}
      {thcUnitMeasurement === 'PERCENTAGE' ? '%' : thcUnitMeasurement}
    </Badge>
  );
}

export default THCBadge;
