// @flow strict
import type { Node } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Select from '../../molecules/form/Select';

import type { Option } from '../../molecules/form/Select';

type Props = {|
  className?: string,
  selected?: ?string,
  onSelected: (id: ?string) => void,
  hideMostSold?: boolean,
|};

const ProductSortSelect = function ({
  className,
  selected,
  onSelected,
  hideMostSold,
}: Props): Node {
  const { messages } = useIntl();
  // $FlowFixMe
  const handleChange = ({ value }: ?(Option | Array<Option>)) => {
    // $FlowFixMe
    onSelected(value);
  };

  const PRODUCT_SORT_OPTIONS = useMemo(() => {
    const options = [
      {
        label: messages['product.filter.sort.option.featured'],
        value: 'featured',
      },
      {
        label: messages['product.filter.sort.option.name'],
        value: 'name',
      },
      {
        label: messages['product.filter.sort.option.newest'],
        value: 'createdAt',
      },
      {
        label: messages['product.filter.sort.option.most-sold'],
        value: 'purchasedcount',
      },
    ];

    if (hideMostSold === true) {
      options.pop();
    }

    return options;
  }, [hideMostSold, messages]);

  const value = PRODUCT_SORT_OPTIONS.find(
    (option) => option.value === selected,
  );

  return (
    <Select
      className={className}
      options={PRODUCT_SORT_OPTIONS}
      value={value}
      onChange={handleChange}
      placeholder={messages['product.filter.sort.option.featured']}
    />
  );
};

export default ProductSortSelect;
