// @flow strict
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import type { Node } from 'react';

import BrandCard from '../cells/BrandCard';

const items = [
  {
    title: 'Borincanna',
    categories: [{ name: 'category.flower', id: 9 }],
    image: '/assets/images/brands/boricanna.png',
    key: '218',
  },
  {
    title: 'UMA',
    categories: [{ name: 'category.edibles', id: 3 }],
    image: '/assets/images/brands/uma.png',
    key: '2057',
  },
  {
    title: 'PR Alchemy',
    categories: [{ name: 'category.concentrates', id: 7 }],
    image: '/assets/images/brands/alchemy.png',
    key: '1550',
  },
  {
    title: 'Joy Cannabis',
    categories: [{ name: 'category.edibles', id: 3 }],
    image: '/assets/images/brands/joy.png',
    key: '1276',
  },
  // {
  //   title: 'Draco Rosa',
  //   description: 'Topicals | Cartridges | Flowers',
  //   image: '/assets/images/brands/nextgen-draco.jpeg',
  //   key: '3',
  // },
  {
    title: 'HealthWeed',
    categories: [{ name: 'category.shake', id: 17 }],
    image: '/assets/images/brands/healthy-weed.jpg',
    key: '672',
  },
  {
    title: 'PurpleDoor',
    categories: [{ name: 'category.accessories', id: 2 }],
    image: '/assets/images/brands/purple-doctor.png',
    key: '1592',
  },
];

function FeaturedBrand(): Node {
  const brands = items.map((item) => (
    <Col
      className="col col-xxl-4 col-lg-6 col-sm-12 col-12 mb-4"
      key={item.key}
    >
      <BrandCard
        id={item.key}
        title={item.title}
        categories={item.categories}
        image={item.image}
      />
    </Col>
  ));
  return (
    <section className="category-brand">
      <div className="category-wrapper-bg" />
      <Container fluid className="category-content">
        <Row className="justify-content-center mb-5">
          <Col className="col-lg-7">
            <div className="section-title section-title-white text-center">
              <h2>
                <FormattedMessage id="home.brand.title" />
              </h2>
            </div>
          </Col>
        </Row>
        <Row>{brands}</Row>
        {false && (
          <div className="btn-brand mt-5">
            <Link to="/" className="main-btn btn">
              <FormattedMessage id="home.brand.show-brands" />
            </Link>
          </div>
        )}
      </Container>
    </section>
  );
}

export default FeaturedBrand;
