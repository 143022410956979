// @flow strict
import { Nav } from 'reactstrap';

import type { Node } from 'react';

import TabNav from '../molecules/TabNav';
import { useStrainSearchParams } from '../hooks';

export type FilterItem = {|
  children: Node,
  value: ?string,
  key: string,
  props?: { to: string },
|};

type Props = {|
  filters: FilterItem[],
  className?: string,
  query: string,
|};

function TabFilter({ filters, className, query }: Props): Node {
  const [searchParams, updateSearchParams] = useStrainSearchParams();

  const toggle = (tab: string) => {
    // $FlowFixMe
    updateSearchParams({
      [query]: filters.find((f) => f.key === tab)?.value,
    });
  };

  const currentActiveTab = filters.find(
    (f) => f.value === searchParams.get(query),
  )?.key;

  return (
    <Nav tabs className={className}>
      {filters.map((item) => (
        <TabNav
          toggle={toggle}
          key={item.key}
          index={item.key}
          currentActiveTab={currentActiveTab ?? '0'}
          props={item.props}
        >
          {item.children}
        </TabNav>
      ))}
    </Nav>
  );
}

export default TabFilter;
