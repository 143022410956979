// @flow strict
import type { Node } from 'react';
import { NavLink } from 'reactstrap';

type Props = {|
  arrow: string,
  onClick?: () => void,
  className?: string,
  size?: string,
|};
function SlickArrow({
  arrow,
  onClick,
  className = '',
  size = '',
}: Props): Node {
  return (
    <NavLink
      className={`slider-arrow arrow-${arrow} arrow-${size} ${className}`}
      onClick={onClick}
    >
      <img src={`/assets/images/icons/${arrow}-arrow.png`} alt="arrow" />
    </NavLink>
  );
}

export default SlickArrow;
