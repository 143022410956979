// @flow strict

import { useLazyQuery, useQuery } from '@apollo/client';

import type { Product, ListResponse } from 'types';

import type { QueryTuple } from '@apollo/client';
import GRAPHQL from '../graphql';

import type { GeoPoint } from '../types';

const EMPTY_LIST_PAGINATED_RESPONSE = {
  objects: [],
  total: 0,
};

export const useProduct = (
  options?: ?{},
  // $FlowFixMe
): QueryResult<
  ?Product,
  {|
    itemNumber: string,
    locationId: number,
  |},
> => {
  // $FlowFixMe
  const { data, ...rest } = useQuery(GRAPHQL.PRODUCT.DETAIL, { ...options });
  // $FlowFixMe
  return {
    ...rest,
    data: data?.business?.product,
  };
};

export const useRelatedProducts = (
  options?: ?{},
  // $FlowFixMe
): QueryResult<
  Product[],
  {|
    itemNumber: string,
    locationId: number,
  |},
> => {
  // $FlowFixMe
  const { data, ...rest } = useQuery(GRAPHQL.PRODUCT.RELATED, {
    ...options,
  });
  // $FlowFixMe
  return {
    ...rest,
    data:
      data?.business?.product?.relatedProducts ??
      // $FlowFixMe
      EMPTY_LIST_PAGINATED_RESPONSE.objects,
  };
};

export const useProducts = (
  options?: ?{},
): QueryTuple<
  ListResponse<Product>,
  {|
    locationId?: ?number,
    filter: {
      locationId?: ?number,
      priceType: ?string,
      categoryId?: number,
      brandId?: number,
      type?: ?string,
    },
    pagination?: {
      limit?: number,
      offset?: number,
    },
    sort?: {
      key?: string,
      geoPoint?: GeoPoint,
    },
  |},
> => {
  // $FlowFixMe
  const [getProducts, { data, ...rest }] = useLazyQuery(GRAPHQL.PRODUCT.LIST, {
    ...options,
    fetchPolicy: 'network-only',
  });
  // $FlowFixMe
  return [
    // $FlowFixMe
    getProducts,
    // $FlowFixMe
    {
      ...rest,
      // $FlowFixMe
      data: data?.business?.products ?? EMPTY_LIST_PAGINATED_RESPONSE,
    },
  ];
};

export const useGlobalProducts = (
  options?: ?{},
): QueryTuple<
  ListResponse<Product>,
  {|
    filter: {
      locationId?: ?number,
      priceType: ?string,
      categoryId?: number,
      brandId?: number,
      type?: ?string,
    },
    pagination?: {
      limit?: number,
      offset?: number,
    },
    sortBy?: {
      key?: string,
      geoPoint?: GeoPoint,
    },
  |},
> => {
  // $FlowFixMe
  const [getProducts, { data, ...rest }] = useLazyQuery(
    GRAPHQL.PRODUCT.GLOBAL_LIST,
    {
      ...options,
      fetchPolicy: 'network-only',
    },
  );
  // $FlowFixMe
  return [
    // $FlowFixMe
    getProducts,
    // $FlowFixMe
    {
      ...rest,
      // $FlowFixMe
      data: data?.products ?? EMPTY_LIST_PAGINATED_RESPONSE,
    },
  ];
};
