// @flow strict
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import Slider from 'react-slick';
import SoldProductCard from '../cells/SoldProductCard';
import SlickArrow from '../molecules/SlickArrow';
import CategoryCarouselFilter from '../cells/CategoryCarouselFilter';
import ProductListLoading from '../cells/Shimmers/ProductListLoading';
import QueryResult from './QueryResult';
import EmptyList from './EmptyList';
import RequestingGeoPoint from './RequestingGeoPoint';

import { useGlobalProducts } from '../hooks/product';
import { coordinates } from '../graphql/reactive-variables';
import { OPTIONS_CATEGORIES } from '../constants';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  centerPadding: '60px',
  autoplay: false,
  autoplaySpeed: 2000,
  prevArrow: <SlickArrow arrow="left" />,
  nextArrow: <SlickArrow arrow="right" />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesToShow: 1.2,
      },
    },
  ],
};

function SoldProduct(): Node {
  const [getProducts, { loading, data: products, error }] = useGlobalProducts();
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  const point = useReactiveVar(coordinates);

  useEffect(() => {
    if (currentActiveTab === undefined) {
      setCurrentActiveTab(null);
    }
    if (point.allowed == null) {
      return;
    }

    let geoPoint;

    if (point.lat != null && point.lng != null) {
      geoPoint = {
        lat: point.lat,
        lng: point.lng,
      };
    }

    // $FlowFixMe
    getProducts({
      variables: {
        filter: {
          priceType: 'MED',
          categoryId: currentActiveTab === null ? undefined : +currentActiveTab,
        },
        pagination: {
          limit: 6,
        },
        sortBy: {
          geoPoint,
        },
      },
    });
  }, [getProducts, point, currentActiveTab]);

  // $FlowFixMe
  const { objects: productsData } = products;

  const optionsTab = OPTIONS_CATEGORIES.map((option) => ({
    key: option.key,
    children: (
      <div className="carousel-filter-prods" key={option.key}>
        <img src={option.icon} alt="" />
        <FormattedMessage id={option.name} />
      </div>
    ),
  }));

  return (
    <section className="listing-grid-area">
      <Container fluid className="content mt-5 mb-5">
        <Row className="justify-content-center">
          <Col className="col-lg-12">
            <div className="section-title section-title-two">
              <h1>
                <span className="line">
                  <FormattedMessage id="home.sold-product.title2" />{' '}
                  <FormattedMessage id="home.sold-product.title" />
                </span>
              </h1>
              <p>
                <Link to="/products">
                  <FormattedMessage id="home.show-all" />
                </Link>
              </p>
            </div>
          </Col>
        </Row>
        <div className="carousel-filter">
          <CategoryCarouselFilter
            // $FlowFixMe
            data={optionsTab}
            currentActiveTab={currentActiveTab}
            // $FlowFixMe
            setCurrentActiveTab={setCurrentActiveTab}
          />
        </div>

        {point.allowed == null ? (
          <RequestingGeoPoint />
        ) : (
          <QueryResult
            error={error}
            isEmpty={productsData.length <= 0}
            loading={loading}
            placeholder={<ProductListLoading />}
            notFound={<EmptyList elements="products" />}
          >
            <Row>
              <Slider {...settings}>
                {productsData
                  // .filter((product) => product.image !== null)
                  .map((product) => (
                    <div className="slider-item px-3" key={product.id}>
                      <SoldProductCard
                        product={product}
                        showTaxes={product.store.showTaxes}
                        locationId={product.store.id}
                        businessId={product.business.id}
                        logo={product.business.logo}
                        storeName={product.store.name}
                        storeId={product.store.id}
                      />
                    </div>
                  ))}
              </Slider>
            </Row>
          </QueryResult>
        )}
      </Container>
    </section>
  );
}

export default SoldProduct;
