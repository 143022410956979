// @flow strict

import type { Node } from 'react';

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const KEY = 'IAmAbove21';
const VALUE = 'true';

const style = {
  fontWeight: 700,
  fontFamily: 'lato bold',
  color: '#202529',
};
const iamAbove21 = localStorage.getItem(KEY) !== VALUE;

const bodyValues = {
  tc: (chunks: string) => (
    <a
      href="https://thestrain.io/marketplace-tnc"
      target="_blank"
      rel="noreferrer"
    >
      {chunks}
    </a>
  ),
  pt: (chunks: string) => (
    <a
      href="https://thestrain.io/marketplace-pp"
      target="_blank"
      rel="noreferrer"
    >
      {chunks}
    </a>
  ),
};

function AgeGate(): Node {
  const [isOpen, setIsOpen] = useState(iamAbove21);

  const handleUnder = () => {
    window.location.href = 'https://google.com';
  };

  const handleAbove = () => {
    localStorage.setItem(KEY, VALUE);
    setIsOpen(false);
  };

  return (
    <Modal centered isOpen={isOpen}>
      <ModalHeader className="header-age-gate">
        <img src="assets/images/age-gate.jpeg" alt="age-gate" />
      </ModalHeader>
      <ModalBody className="age-gate">
        <h1 className="my-3" style={style}>
          <FormattedMessage id="age-gate.title" />
        </h1>
        <div>
          <FormattedMessage id="age-gate.body" values={bodyValues} />
        </div>
        <div className="my-4 mx-4 d-flex age-buttons">
          <Button color="success" className="px-4 m-2" onClick={handleAbove}>
            <FormattedMessage id="age-gate.above-21" />
          </Button>
          <Button
            color="danger"
            outline
            className="px-4 button-rigth m-2"
            onClick={handleUnder}
          >
            <FormattedMessage id="age-gate.under-21" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AgeGate;
