// @flow strict
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import { currentCartVar } from '../graphql/reactive-variables';

type Props = {|
  message: string,
|};

const SuccessSubmitModal = function ({ message }: Props): Node {
  const currentCart = useReactiveVar(currentCartVar);

  const handleToggle = () => {
    window.location.href = `/dispensaries/${currentCart.businessId}/stores/${currentCart.locationId}`;
  };

  return (
    <Modal isOpen backdrop className="embed-success-modal">
      <ModalBody>
        <div className="text-center">
          <img
            className="img-thumbnail"
            src="/assets/images/marijuana-drive-through.png"
            alt="Strain"
          />
        </div>
        <p className="text-center mt-3 mb-4">{message}</p>
        <div className="d-flex justify-content-center">
          <Button color="success" onClick={handleToggle}>
            <FormattedMessage id="common.close" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessSubmitModal;
