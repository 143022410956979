// @flow strict
import type { Node } from 'react';
import Badge from './Badge';

type Props = {|
  percentageCBD: ?number,
  cbdUnitMeasurement: ?string,
|};

function CBDBadge({ percentageCBD, cbdUnitMeasurement }: Props): Node {
  if (percentageCBD == null) {
    return null;
  }

  return (
    <Badge color="cbd">
      CBD: {percentageCBD}{' '}
      {cbdUnitMeasurement === 'PERCENTAGE' ? '%' : cbdUnitMeasurement}
    </Badge>
  );
}

export default CBDBadge;
