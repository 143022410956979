// @flow strict
import { IntlProvider } from 'react-intl';
import { createContext, useContext, useState } from 'react';

import type { Node } from 'react';

import Lang from '../lang';

const AppContext = createContext();
const AppDispatch = createContext();

type Props = {| children: Node |};

export default function AppContextProvider({ children }: Props): Node {
  const [locale, setLocale] = useState('es');
  const lang = Lang[locale];
  return (
    <AppContext.Provider
      // $FlowFixMe[incompatible-type]
      value={locale}
    >
      <AppDispatch.Provider
        // $FlowFixMe[incompatible-type]
        value={setLocale}
      >
        <IntlProvider locale={locale} defaultLocale="es" messages={lang}>
          {children}
        </IntlProvider>
      </AppDispatch.Provider>
    </AppContext.Provider>
  );
}

export function useAppContextProvider(): [string, ?(string) => void] {
  const state = useContext(AppContext);
  const actions = useContext(AppDispatch);
  return [state ?? 'es', actions];
}
