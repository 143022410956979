// @flow strict

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import type { Node } from 'react';

import type { ProductCategory } from '../types';

type Props = {|
  title: string,
  categories: ProductCategory[],
  image: string,
  id: string,
|};

function BrandCard({ title, image, categories, id }: Props): Node {
  return (
    <div className="category-item category-item-three">
      <div className="content">
        <div className="icon">
          <Link to={`/brands?id=${id}`}>
            <img src={image} alt={title} />
          </Link>
        </div>
        <div className="info">
          <h4 className="title">
            <Link to={`/brands?id=${id}`}>{title}</Link>
          </h4>
          <p className="description">
            {categories.map((cat) => (
              <span key={cat.id}>
                <Link
                  className="text-muted"
                  to={`/brands?id=${id}&category=${cat.id}`}
                >
                  <FormattedMessage id={cat.name} />
                  &nbsp; &nbsp;
                </Link>
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BrandCard;
