// @flow strict
import { FormattedMessage } from 'react-intl';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import type { Node } from 'react';

import Slider from 'react-slick';
import DispensaryCard from '../cells/DispensaryCard';
import SlickArrow from '../molecules/SlickArrow';
import ProductListLoading from '../cells/Shimmers/ProductListLoading';
import QueryResult from './QueryResult';
import EmptyList from './EmptyList';
import RequestingGeoPoint from './RequestingGeoPoint';

import { useDispensaries } from '../hooks/dispensary';
import { coordinates } from '../graphql/reactive-variables';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  centerPadding: '60px',
  prevArrow: <SlickArrow arrow="left" />,
  nextArrow: <SlickArrow arrow="right" />,
  focusOnSelect: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1.2,
        arrows: false,
      },
    },
  ],
};

function FeaturedDispensary(): Node {
  const [getDispensaries, { loading, data, error }] = useDispensaries();

  const point = useReactiveVar(coordinates);

  useEffect(() => {
    if (point.allowed == null) {
      return;
    }

    let sorting = { key: 'name' };

    if (point.lat != null && point.lng != null) {
      sorting = {
        key: 'GeoPoint',
        value: {
          lat: point.lat,
          lng: point.lng,
        },
        direction: 'ASC',
      };
    }

    // $FlowFixMe
    getDispensaries({
      variables: {
        sorting,
        pagination: {
          limit: 10,
          offset: 0,
        },
        filter: {
          isFeatured: true,
        },
      },
    });
  }, [getDispensaries, point]);

  // $FlowFixMe
  const { objects: dispensaries } = data;

  return (
    <section className="listing-grid-area">
      <Container fluid className="content pt-5">
        <Row className="justify-content-center">
          <div className="section-title section-title-two">
            <h1>
              <span className="line">
                <FormattedMessage id="home.dispensary.title2" />{' '}
                <FormattedMessage id="home.dispensary.title" />
              </span>
            </h1>
            <p>
              <Link to="/dispensaries?filterBy=isFeatured">
                <FormattedMessage id="home.show-all" />
              </Link>
            </p>
          </div>
        </Row>

        {point.allowed == null ? (
          <RequestingGeoPoint />
        ) : (
          <QueryResult
            error={error}
            isEmpty={dispensaries.length <= 0}
            loading={loading}
            placeholder={<ProductListLoading />}
            notFound={<EmptyList elements="dispensaries" />}
          >
            <Row className="content-dispensary">
              <Slider {...settings}>
                {dispensaries.map((dispensary) => (
                  <div
                    className="mb-5 px-2"
                    key={`${dispensary.id}-${dispensary.business.id}`}
                  >
                    <DispensaryCard data={dispensary} showDistance />
                  </div>
                ))}
              </Slider>
            </Row>
          </QueryResult>
        )}
      </Container>
    </section>
  );
}

export default FeaturedDispensary;
